.Form__root_mrFG {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 0 -8px -24px;
}

  .Form__root_mrFG .Form__birthCertificateContainer_1K5d {
    margin-bottom: 0;
}

  @media (max-width: 1279px) {.Form__root_mrFG {
    margin: 0;
  }
}

.Form__column_2L5p {
  width: 352px;
  margin-right: 8px;
  margin-bottom: 24px;
  margin-left: 8px;
}

.Form__column_2L5p.Form__small_dg_j {
    width: 168px;
}

.Form__column_2L5p.Form__large_EilN {
    -webkit-order: -1;
            order: -1;
    width: 720px;
}

@media (max-width: 1279px) {
    .Form__column_2L5p,
    .Form__column_2L5p.Form__small_dg_j,
    .Form__column_2L5p.Form__large_EilN {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
  }
}

.Form__docscan_30H6 {
  -webkit-order: -2;
          order: -2;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
}

@media (max-width: 1279px) {

.Form__docscan_30H6 {
    padding-right: 0;
    padding-left: 0;
  }
}

.Form__center_ggXM {
  -webkit-align-self: center;
          align-self: center;
}

@media (max-width: 1279px) {
  .Form__mobile_first_23eb {
    -webkit-order: -1;
            order: -1;
  }
}