.GroupMember__root_J-Fm {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-height: 64px;
}

  @media (min-width: 768px) {.GroupMember__root_J-Fm {
    height: 64px;
  }
}

  .GroupMember__root_J-Fm.GroupMember__disabled_2cSn {
    pointer-events: none;
    cursor: default;
}

  .GroupMember__root_status_active_3wnQ .GroupMember__icon_3ddB {
        background: linear-gradient(86.42deg, #C4D600 0%, #5BB784 100%);
}

  .GroupMember__root_status_active_3wnQ .GroupMember__status_3GHj {
        fill: #97ba1e;
}

  .GroupMember__root_status_active_3wnQ:not(.GroupMember__root_title_9Snn) {
        cursor: pointer;
}

  .GroupMember__root_status_pending_2VLT .GroupMember__icon_3ddB {
        background: #d0d6e1;
}

  .GroupMember__root_status_pending_2VLT .GroupMember__status_3GHj {
        color: #ff991f;
        pointer-events: none;
        cursor: default;
}

  .GroupMember__root_status_deletionPending_2wlr .GroupMember__icon_3ddB {
        background: #d0d6e1;
}

  .GroupMember__root_status_deletionPending_2wlr .GroupMember__status_3GHj {
        color: #f14d56;
        cursor: default;
}

.GroupMember__prevActive_13bc,
.GroupMember__nextActive_3TYC {
  position: absolute;
  left: 50%;
  z-index: 1;
  display: block;
  width: 2px;
  height: 50%;
  background-color: #D4E3AC;
  border-radius: 10px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.GroupMember__prevActive_13bc {
  bottom: 100%;
}

.GroupMember__nextActive_3TYC {
  top: 100%;
}

.GroupMember__icon_placeholder_3xkb,
.GroupMember__icon_3ddB {
  position: absolute;
  left: -2px;
  border-radius: 50%;
}

@media (min-width: 768px) {

.GroupMember__icon_placeholder_3xkb,
.GroupMember__icon_3ddB {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}

.GroupMember__icon_placeholder_3xkb {
  z-index: 2;
  width: 42px;
  height: 42px;
  background-color: #ffffff;
}

.GroupMember__icon_3ddB {
  z-index: 3;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 40px;
  height: 40px;
  -webkit-user-select: none;
          user-select: none;
  border: 2px solid #ffffff;
  opacity: 0.6;
}

@media (max-width: 767px) {

.GroupMember__icon_3ddB {
    -webkit-align-self: flex-start;
            align-self: flex-start;
    margin-top: 15px;
  }
}

.GroupMember__name_zju5 {
  width: 220px;
  margin-left: 56px;
}

.GroupMember__date_3R5T {
  width: 113px;
  margin-left: 25px;
}

@media (min-width: 768px) {

.GroupMember__status_wrap_1WAT {
    margin-left: auto;
  }
}

.GroupMember__status_3GHj {
  min-width: 87px;
  outline: none;
}

.GroupMember__status_active_64ho,
  .GroupMember__status_remove_20j7 {
    cursor: pointer;
}

.GroupMember__status_label_26Ke {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    outline: none;
}

.GroupMember__status_label_isRemove_3Z8n {
      color: #f14d56;
      cursor: pointer;
}

.GroupMember__status_label_isRemoving_3TxI {
      pointer-events: none;
}

.GroupMember__status_icon_3V5k {
    margin-right: 13px;
}

@media (max-width: 767px) {
  .GroupMember__root_J-Fm {
    min-height: 75px;
  }

    .GroupMember__root_J-Fm:not(:last-child) {
      border-bottom: solid 1px #dde3ed;
  }
      .GroupMember__root_expanded_Y_gs .GroupMember__name_zju5 {
        margin-top: 16px;
  }

      .GroupMember__root_expanded_Y_gs .GroupMember__option_icon_1T5b {
        color: #222a37;
  }
      .GroupMember__root_title_9Snn .GroupMember__content_1Meu {
        -webkit-flex-direction: row;
                flex-direction: row;
  }

  .GroupMember__content_1Meu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    width: calc(100% - 12px);
  }

  .GroupMember__name_zju5,
  .GroupMember__date_3R5T,
  .GroupMember__status_3GHj {
    width: auto;
    margin-left: 56px;
  }

  .GroupMember__name_zju5 {
    margin-bottom: 4px;
  }

  .GroupMember__ellipsis__VHN {
    width: calc(100% - 10px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .GroupMember__status_3GHj {
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: left;
  }

  .GroupMember__option_icon_1T5b {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-self: flex-start;
            align-self: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
    width: 20px;
    margin-top: 30px;
    color: #d0d6e1;
    outline: none;
  }
}