
.Travelers__more_ps8N {
  margin-top: 12px;
  margin-right: auto;
  margin-left: auto;
}

  @media (min-width: 1280px) {
.Travelers__more_ps8N {
    max-width: 200px;
  }
}

@media (min-width: 1280px) {

.Travelers__add_aWJ- {
    margin-top: 36px;
  }
}