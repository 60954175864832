.Button__root_2ok7 {
  position: relative;
  box-sizing: border-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-width: 48px;
  height: 32px;
  padding: 0 12px;
  font-size: 14px;
  line-height: 20px;
  color: #222a37;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #d0d6e1;
  border-radius: 4px;
  outline: none;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

  .Button__root_2ok7:hover {
    background-color: #eceff5;
    border-color: #d0d6e1;
}

  .Button__root_2ok7:focus:not(:active) {
    background-color: #d0d6e1;
    border: 1px solid #95a0b3;
    box-shadow: 0 3px 6px -2px #d0d6e1;
}

  .Button__root_2ok7:active {
    background-color: #d0d6e1;
    border-color: #95a0b3;
}

  .Button__root_2ok7:disabled {
    color: #95a0b3;
    cursor: default;
    background: #ffffff;
    border-color: #d0d6e1;
}

  .Button__root_block_18Q3 {
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
}

  .Button__root_block_18Q3 .Button__content_1qdg {
      text-align: center;
}

.Button__content_1qdg {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
}
