.Card__root_2NuI {
  position: relative;
  width: 280px;
  height: 156px;
  padding: 13px 16px 16px;
  overflow: hidden;
  border-radius: 10px;
}

  @media (max-width: 767px) {.Card__root_2NuI {
    width: 240px;
    height: 131px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

.Card__image_1Hcu {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Card__content_Ddxd {
  position: relative;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
}

.Card__title_MEcM {
  margin-bottom: 8px;
}

@media (max-width: 767px) {

.Card__title_MEcM {
    margin-bottom: 4px;
  }
}

.Card__action_22Jm {
  margin-top: auto;
}
