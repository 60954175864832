.Sex__Sex_33q5 {
  position: relative;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  color: inherit;
}

  .Sex__Sex_size_small_2flQ {
    font-size: 14px;
    line-height: 20px;
}

.Sex__icon_3-hO {
  display: inline-block;
  width: 16px;
  height: 20px;
  margin-top: -2px;
  margin-right: 10px;
  color: inherit;
  vertical-align: middle;
}

.Sex__currentColor_3WcS {
  color: currentColor;
}

.Sex__currentColor_3WcS .Sex__icon_3-hO {
    color: currentColor;
}
