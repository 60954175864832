.AddGooglePay__root_1wa6 {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
}

  .AddGooglePay__root_1wa6:hover .AddGooglePay__button__QYO {
    background-color: #374247;
}


.AddGooglePay__button__QYO {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%;
  max-height: 38px;
  color: #fff;
  pointer-events: none;
  background-color: #000;
  border-radius: 8px;
  transition: background .3s ease;
}


.AddGooglePay__text_tY0t {
  display: inline-block;
  margin-right: 4px;
  font-size: 14px;
  line-height: 16px;
}


.AddGooglePay__icon_5-g7 {
  margin-top: 2px;
}


.AddGooglePay__AddWalletWrapper_5gqF {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  height: 38px !important;
}


.AddGooglePay__AddWalletWrapper_5gqF iframe {
    height: 38px !important;
}


.AddGooglePay__AddWalletWrapperHidden_2jYO {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}