.DocumentScan__desktop_1BNu {
  display: block;
}

  .DocumentScan__desktop_1BNu .DocumentScan__inline_1-8y {
    position: relative;
    margin-bottom: 16px;
}

  @media (max-width: 767px) {.DocumentScan__desktop_1BNu {
    display: none;
  }
}

.DocumentScan__mobile_3AOr {
  display: none;
}

.DocumentScan__mobile_3AOr .DocumentScan__button_31YF {
    padding-bottom: 16px;
    margin-top: 16px;
}

@media (max-width: 767px) {

.DocumentScan__mobile_3AOr {
    display: block;
  }
}

.DocumentScan__title_vpWo,
.DocumentScan__subtitle_2XNf {
  display: block;
}

.DocumentScan__title_vpWo {
  margin-bottom: 8px;
}

.DocumentScan__button_31YF {
  display: block;
  margin-top: 16px;
}

.DocumentScan__error_2WmF {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 50;
  width: 280px;
  padding: 12px 16px  16px 16px;
  margin-top: 8px;
  background-color: #64686D;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 8px 16px 0 rgba(63, 63, 68, 0.15);
}

.DocumentScan__desktop_1BNu .DocumentScan__error_2WmF::before {
    position: absolute;
    bottom: 100%;
    left: 16px;
    width: 0;
    height: 0;
    content: '';
    border-right: 4px solid transparent;
    border-bottom: 4px solid #64686D;
    border-left: 4px solid transparent;
}

.DocumentScan__mobile_3AOr .DocumentScan__error_2WmF {
    position: relative;
    top: auto;
    left: auto;
    display: block;
    width: 270px;
    padding: 19px 16px 14px 16px;
    margin-top: 0;
    background-color: rgba(242, 242, 242, 0.8);
    border-radius: 14px;
    box-shadow: none;
}

.DocumentScan__mobile_3AOr .DocumentScan__error_2WmF * {
      text-align: center;
}

.DocumentScan__mobile_3AOr .DocumentScan__error_2WmF .DocumentScan__error-text_g3NO {
      margin-top: 2px;
      margin-bottom: 21px;
}

.DocumentScan__error-text_g3NO {
  display: block;
  margin-top: 4px;
  margin-bottom: 12px;
}

.DocumentScan__error-link_6rZ4 {
  display: block;
  width: calc(100% + 32px);
  padding-top: 15px;
  margin-right: -16px;
  margin-left: -16px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #007AFF;
  text-decoration: none;
  background-color: transparent;
  border: none;
  border-top: 1px solid rgba(60, 60, 67, 0.29);
}

.DocumentScan__overlay_30tY {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.DocumentScan__success_101U {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.DocumentScan__success-icon_1oAj {
    display: block;
    height: 20px;
    margin-right: 12px;
}

.DocumentScan__success-inner_XKIW {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 16px 32px 16px 20px;
    background-color: rgba(100, 104, 109, 0.8);
    border-radius: 12px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 8px 16px 0 rgba(63, 63, 68, 0.15);
}

/* .error */

/*   display: block */

/*   margin-left: -24px */

/*   margin-right: -24px */

/*   margin-top: -29px */

/*   margin-bottom: 15px */

/*   @media (--xs-viewport) */

/*     margin-left: -16px */

/*     margin-right: -16px */

/*     margin-top: -24px */

/*     margin-bottom: 15px */

.DocumentScan__field_3SXn {
  position: absolute;
  left: -10000px;
  opacity: 0;
}

.DocumentScan__inline_1-8y {
  position: relative;
}

.DocumentScan__inline-link_iuq0 {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-right: -4px;
}