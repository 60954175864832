.SkeletonLoaderAuthorization__root_2WM7 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: calc(100vh - 84px);
  padding-left: 215px;
  background-color: #e1f0e5;
}

.SkeletonLoaderAuthorization__desktop_tl0O {
  display: block;
  width: 360px;
  background-color: #fff;
  border: solid 1px #e4e4e4;
  border-radius: 16px;
}

.SkeletonLoaderAuthorization__mobile_1A0O {
  width: 360px;
  background-color: #fff;
}

@media (min-width: 768px) {

.SkeletonLoaderAuthorization__mobile_1A0O {
    display: none;
  }
}

.SkeletonLoaderAuthorization__isModal_3JXl {
  display: none;
}

@media (max-width: 767px) {
  .SkeletonLoaderAuthorization__root_2WM7 {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
    padding-left: 0;
  }

  .SkeletonLoaderAuthorization__desktop_tl0O {
    display: none;
  }

  .SkeletonLoaderAuthorization__isModal_3JXl {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 12px 12px 0 0;
  }
}
