.Indicator__root_3MrN {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center
}

.Indicator__icon-right_3afP {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-left: 4px
}

.Indicator__icon-left_24Sq {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-right: 4px
}