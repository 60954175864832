.PublicMedia__root_3VGM {
  margin-top: 100px;
}

  @media (max-width: 767px) {.PublicMedia__root_3VGM {
    margin-top: 60px;
  }
}

.PublicMedia__title_2hMO {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.PublicMedia__masonry_3DuP {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  width: 1032px;
  margin-left: -24px;
}

@media (max-width: 1024px) {

.PublicMedia__masonry_3DuP {
    width: 688px;
  }
}

@media (max-width: 767px) {

.PublicMedia__masonry_3DuP {
    width: 344px;
  }
}

.PublicMedia__photos_12i1 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 24px;
}

.PublicMedia__photo-wrap_SzWb {
  position: relative;
  width: 320px;
  margin-bottom: 24px;
  margin-left: 24px;
}

.PublicMedia__insta-icon_29vt {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 8px;
}

.PublicMedia__insta-link_1y8t {
  position: absolute;
  bottom: 12px;
  left: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: calc(100% - 12px);
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  opacity: 0.7;
  transition: opacity 0.4s;
}

.PublicMedia__insta-link_1y8t:hover {
    opacity: 1;
}

.PublicMedia__photo_2REc {
  display: block;
  width: 100%;
  max-width: 320px;
  border-radius: 16px;
}
