.ProfileModal__root_1Rd0 {
  box-sizing: border-box;
  padding: 32px;
  background-color: #ffffff;
  border-radius: 16px;
}

.ProfileModal__container_2uex {
  position: relative;
  top: 40px;
  display: block;
  width: 100%;
  max-height: 100%;
  outline: none;
}

.ProfileModal__container-small_fpwy {
    max-width: 320px;
}

.ProfileModal__container-medium_KysS {
    max-width: 600px;
}

.ProfileModal__container-large_N4SM {
    max-width: 800px;
}

.ProfileModal__container-x-large_1b5V {
    max-width: 960px;
}

.ProfileModal__title_2oNl {
  margin: 4px 0 24px;
}

.ProfileModal__close_nlU5 {
  position: absolute;
  top: 24px;
  right: 24px;
  color: #7a869a;
  outline: none;
}

.ProfileModal__overlay_20Gl {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  overflow-y: auto;
  background-color: rgba(148, 148, 148, .6);
}

.ProfileModal__actions_1Z09 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 40px;
}

.ProfileModal__actions-vertical_4d_w {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

@media (max-width: 1280px) {
  .ProfileModal__root_1Rd0 {
    position: fixed;
    top: 56px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 322;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    padding: 16px;
    margin: 0;
    overflow: auto;
    background-color: #ffffff;
  }

  .ProfileModal__title_2oNl {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 322;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    min-height: 56px;
    padding: 0 16px;
    margin: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #dde3ed;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .ProfileModal__close_nlU5 {
    top: 18px;
    right: 16px;
  }

  .ProfileModal__actions_1Z09 {
    margin-bottom: 0;
  }

  .ProfileModal__hidden_1SlU {
    display: none;
  }
}