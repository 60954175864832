.List__root_3RpD {
  margin-top: -24px;
  margin-bottom: -24px;
}

.List__approval_2Pxu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.List__approval_2Pxu:not(:last-child) {
    border-bottom: 1px solid #dde3ed;
}

.List__approval_opened_Hsbp .List__details_3Kuc {
      display: block;
}

.List__approval_opened_Hsbp .List__toggle_2-YT {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
}

.List__logo_1LM1 {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.List__resource_3mNl {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.List__header_2Rb9 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 96px;
  cursor: pointer;
  outline: none;
}

.List__toggle_2-YT {
  margin-right: 4px;
  transition: -webkit-transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease;
  will-change: transform;
}

.List__details_3Kuc {
  display: none;
  padding-bottom: 24px;
  padding-left: 64px;
}

.List__data_types_3WmE {
  margin-bottom: 12px;
}

.List__data_type_1mKU {
  font-weight: bold;
}

.List__warning_38-o {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 40px;
  color: #505f79;
}

.List__warning_icon_2qpY {
  margin-right: 9px;
}

.List__warning_icon_2qpY > svg {
    display: block;
}

.List__button_1Oez {
  width: 150px;
  font-weight: 500;
  text-align: center;
}

@media (max-width: 767px) {
      .List__approval_opened_Hsbp .List__details_3Kuc {
        display: -webkit-flex;
        display: flex;
  }
      .List__approval_tooltip_opened_1QKt .List__details_3Kuc {
        padding-bottom: 212px;
  }

  .List__details_3Kuc {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding: 0;
  }

    .List__details_3Kuc .List__logo_1LM1 {
      width: 60px;
      height: 60px;
      margin-right: 0;
      margin-bottom: 12px;
  }

  .List__toggle_2-YT {
    display: none;
  }

  .List__data_types_3WmE {
    margin-bottom: 24px;
  }

    .List__data_types_3WmE > p {
      text-align: center;
  }

  .List__data_type_1mKU {
    display: block;
  }

  .List__warning_38-o {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 24px;
  }

    .List__warning_38-o > p {
      text-align: center;
  }

  .List__warning_icon_2qpY {
    margin-right: 0;
    margin-bottom: 2px;
  }
}