.IconButton__IconButton_lFcF {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 26px;
  height: 26px;
  color: inherit;
  text-decoration: none;
  pointer-events: inherit;
  background: none;
  border: none;
  border-radius: 50%;
  outline: none;
  fill: currentColor;
}

  @media (max-width: 767px) {.IconButton__IconButton_lFcF {
    width: 24px;
    height: 24px;
  }
}

.IconButton__IconButton_lFcF:hover {
  opacity: 0.8;
}

.IconButton__inner_3L2a {
  position: relative;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  color: inherit;
  cursor: pointer;
  border-radius: 50%;
}

.IconButton__theme_gray_143Z {
  color: #757575;
}

.IconButton__theme_green_uC4F {
  color: #c4d600;
}

.IconButton__theme_white_Cc2t {
  color: #fff;
}

.IconButton__theme_greenCircle_LCRO {
  color: #fff;
  background-color: #c4d600;
}

.IconButton__theme_greenDesktopCircle_Q09- {
  color: #fff;
  background-color: #c4d600;
}

@media (max-width: 767px) {

.IconButton__theme_greenDesktopCircle_Q09- {
    width: 12px;
    height: 20px;
    color: rgba(0, 0, 0, 0.54);
    background-color: transparent;
  }
}

.IconButton__theme_greenRound_1sAj {
  color: #c4d600;
  border: 1px solid #c4d600;
}

.IconButton__size_large_23nG {
  width: 32px;
  height: 32px;
}

@media (max-width: 767px) {

.IconButton__size_large_23nG {
    width: 32px;
    height: 32px;
  }
}

.IconButton__size_big_1DlT {
  width: 44px;
  height: 48px;
}

@media (max-width: 767px) {

.IconButton__size_big_1DlT {
    width: 20px;
    height: 20px;
  }
}

.IconButton__size_small_2iUq {
  width: 18px;
  height: 18px;
}

@media (max-width: 767px) {

.IconButton__size_small_2iUq {
    width: 18px;
    height: 18px;
  }
}

.IconButton__align_middle_3AUC {
    vertical-align: middle;
}
