.Certificate__Certificate_2qgY {
  display: block;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgba(29, 33, 41, 0.13);
}

  .Certificate__Certificate_2qgY .Certificate__information_1Yyi {
    top: 4px;
    width: 20px;
    height: 16px;
}

  .Certificate__Certificate_opened_1ka0 .Certificate__details_16nf {
      display: block;
}

  .Certificate__Certificate_2qgY + .Certificate__Certificate_2qgY {
    margin-top: 16px;
}

  @media (min-width: 768px) {
    .Certificate__Certificate_2qgY .scrollbar-container > div {
      width: calc(100% - 1px);
  }

    .Certificate__Certificate_2qgY + .Certificate__Certificate_2qgY {
      margin-top: 12px;
  }
}

.Certificate__modal_I9mo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  padding: 4px 0;
}

.Certificate__modal_title_mBba {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}

.Certificate__modal_pin_1EYd {
  font-size: 48px;
  font-weight: bold;
  line-height: 48px;
  color: #222a37;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.Certificate__instruction_1JHi {
  width: 100%;
  margin: 20px 0 36px;
  font-size: 16px;
  line-height: 24px;
  color: #222a37;
  text-align: center;
  word-break: normal;
}

.Certificate__buy_2ewh {
  width: 200px;
}

.Certificate__buy_2ewh > a {
    width: 100%;
}

.Certificate__content_2yiy {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  padding: 36px 36px 36px 156px;
}

.Certificate__icon_2gpG {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 120px;
  height: 120px;
  color: #f6f8fc;
}

.Certificate__main_UZJP {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Certificate__left_3ZkA {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.Certificate__title_3jOI {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin-bottom: 12px;
  color: #7a869a;
}

.Certificate__back_3kDI {
  position: absolute;
  top: 16px;
  left: 16px;
  display: none;
}

.Certificate__name_3ZJM {
  font-size: 14px;
  line-height: 20px;
}

.Certificate__price_1r04 {
  margin-bottom: 4px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #222a37;
}

.Certificate__dates_g9J6 {
  display: none;
}

.Certificate__line_2o-d {
  display: -webkit-flex;
  display: flex;
}

.Certificate__right_ytfW {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.Certificate__pin_3QQK {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 8px;
}

.Certificate__deletion_2zvt {
  margin-right: 12px;
}

.Certificate__deletion_mobile_3pbZ {
  position: absolute;
  top: 16px;
  right: 16px;
  display: none;
}

.Certificate__pin_link_2HkG {
  margin-bottom: 18px;
}

.Certificate__dropdown_3IMG {
  position: absolute;
  right: 36px;
  bottom: 36px;
}

.Certificate__dropdown_mobile_2w8U {
  display: none;
  margin-top: 20px;
}

.Certificate__details_16nf {
  display: none;
  margin-top: 36px;
}

.Certificate__purchases_RUt5 {
  padding: 24px 24px 16px;
  margin-bottom: 26px;
  background: linear-gradient(78.32deg, rgba(196, 214, 0, 0.1) 0%, rgba(91, 183, 132, 0.1) 100%);
  border-radius: 12px;
}

.Certificate__purchases_list_35im {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 12px;
  font-size: 14px;
  line-height: 20px;
  list-style: disc;
}

.Certificate__purchases_list_35im li {
    margin-right: 24px;
    margin-bottom: 8px;
    list-style-type: none;
}

.Certificate__rules_2oWq {
  display: inline-block;
}

.Certificate__pin_button_3FNQ {
  position: absolute;
  right: 16px;
  bottom: 16px;
  left: 16px;
  display: none;
}

.Certificate__pin_button_3FNQ > button {
    width: 100%;
}

@media (max-width: 767px) {
  .Certificate__Certificate_2qgY {
    border-radius: 12px;
  }
      .Certificate__Certificate_opened_1ka0 .Certificate__content_2yiy {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 997;
        padding: 52px 0 64px;
        margin-top: 0;
        overflow: hidden;
        background-color: #ffffff;
  }

      .Certificate__Certificate_opened_1ka0 .Certificate__overflow_3VKJ {
        display: block;
        padding: 16px;
        overflow: auto;
  }

      .Certificate__Certificate_opened_1ka0 .Certificate__icon_wrapper_2s_U {
        position: relative;
        display: block;
  }

      .Certificate__Certificate_opened_1ka0 .Certificate__icon_2gpG {
        top: -8px;
        right: -8px;
        z-index: 1001;
        width: 80px;
        height: 80px;
        color: #ffffff;
  }

        .Certificate__Certificate_opened_1ka0 .Certificate__icon_2gpG svg {
          width: 80px;
          height: 80px;
  }

      .Certificate__Certificate_opened_1ka0 .Certificate__main_UZJP {
        padding: 20px;
        background: linear-gradient(83.42deg, rgba(196, 214, 0, 0.1) 0%, rgba(91, 183, 132, 0.1) 100%);
        border-radius: 12px;
  }

      .Certificate__Certificate_opened_1ka0 .Certificate__title_3jOI {
        margin-bottom: 12px;
        color: #222a37;
  }

      .Certificate__Certificate_opened_1ka0 .Certificate__back_3kDI {
        display: block;
  }

      .Certificate__Certificate_opened_1ka0 .Certificate__name_3ZJM {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1002;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        height: 52px;
        font-weight: bold;
        border-bottom: 1px solid #dde3ed;
  }

      .Certificate__Certificate_opened_1ka0 .Certificate__price_1r04 {
        margin-bottom: 8px;
        font-size: 24px;
        line-height: 32px;
  }

      .Certificate__Certificate_opened_1ka0 .Certificate__details_16nf {
        margin-top: 32px;
  }

      .Certificate__Certificate_opened_1ka0 .Certificate__dates_g9J6,
      .Certificate__Certificate_opened_1ka0 .Certificate__details_16nf,
      .Certificate__Certificate_opened_1ka0 .Certificate__pin_button_3FNQ {
        display: block;
  }

      .Certificate__Certificate_opened_1ka0 .Certificate__date_range_W7n_,
      .Certificate__Certificate_opened_1ka0 .Certificate__dropdown_3IMG,
      .Certificate__Certificate_opened_1ka0 .Certificate__dropdown_mobile_2w8U {
        display: none;
  }

  .Certificate__modal_I9mo {
    width: auto;
    padding: 16px 8px;
  }

  .Certificate__instruction_1JHi {
    margin-top: 12px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: #505f79;
  }

  .Certificate__buy_2ewh {
    display: none;
  }

  .Certificate__content_2yiy {
    padding: 20px;
  }

  .Certificate__icon_2gpG {
    top: 8px;
    right: 8px;
    left: auto;
    width: 60px;
    height: 60px;
  }

    .Certificate__icon_2gpG svg {
      width: 60px;
      height: 60px;
  }

  .Certificate__title_3jOI {
    margin-right: 40px;
    margin-bottom: 20px;
  }

  .Certificate__price_1r04 {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
  }

  .Certificate__right_ytfW {
    display: none;
  }

  .Certificate__deletion_mobile_3pbZ {
    display: block;
  }

  .Certificate__dropdown_3IMG {
    display: none;
  }

  .Certificate__dropdown_mobile_2w8U {
    display: block;
    margin-top: 20px;
  }

  .Certificate__purchases_RUt5 {
    padding: 0;
    margin-bottom: 20px;
    background: transparent;
  }

  .Certificate__purchases_list_35im {
    height: auto;
  }
}

