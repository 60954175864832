.PublicPreferencesApproved__inner_2DXu {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  max-width: 440px;
}

  @media (max-width: 767px) {

  .PublicPreferencesApproved__inner_2DXu.PublicPreferencesApproved__small_3AQV {
      max-width: 100%;
  }
}

  .PublicPreferencesApproved__inner_2DXu:not(.PublicPreferencesApproved__small_3AQV) {
    box-sizing: border-box;
    padding: 24px;
    border: 1px solid #dde3ed;
    border-radius: 12px;
}

  @media (max-width: 767px) {
      .PublicPreferencesApproved__inner_2DXu:not(.PublicPreferencesApproved__small_3AQV) .PublicPreferencesApproved__right_36MZ {
        width: 60px;
        height: 60px;
        padding: 7px;
        margin-bottom: 0;
        border: 1px solid #dde3ed;
        border-radius: 6px;
  }
}

.PublicPreferencesApproved__left_1ZA1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: calc(100% - 104px);
}

@media (max-width: 767px) {

.PublicPreferencesApproved__left_1ZA1 {
    max-width: calc(100% - 84px);
  }
}

.PublicPreferencesApproved__right_36MZ {
  box-sizing: border-box;
  width: 80px;
  height: 80px;
  padding: 9px;
  margin-left: auto;
  border: 1px solid #dde3ed;
  border-radius: 6px;
  outline: none;
}

@media (max-width: 767px) {

.PublicPreferencesApproved__right_36MZ {
    width: auto;
    height: auto;
    padding: 0;
    margin-bottom: auto;
    border: none;
    border-radius: 0;
  }
}

.PublicPreferencesApproved__name_3Szu {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.PublicPreferencesApproved__ok-icon_1JLr {
  margin-left: 12px;
}

.PublicPreferencesApproved__link_3QRE {
  margin-top: 4px;
}

.PublicPreferencesApproved__link_3QRE .PublicPreferencesApproved__link_root_Zsr1 {
    max-width: 100%;
    overflow: hidden;
}

.PublicPreferencesApproved__link_3QRE .PublicPreferencesApproved__link_text_1jLh {
    overflow: hidden;
    text-overflow: ellipsis;
}

.PublicPreferencesApproved__code_xfpv {
  box-sizing: border-box;
  display: inline-block;
  padding: 25px;
  margin-bottom: 24px;
  border: 1px solid #dde3ed;
  border-radius: 12px;
}

@media (max-width: 767px) {

.PublicPreferencesApproved__code_xfpv {
    width: 100%;
    margin-top: 24px;
  }

    .PublicPreferencesApproved__code_xfpv svg {
      width: 100%;
      height: auto;
  }
}

.PublicPreferencesApproved__modal_XuCf {
  padding: 5px;
  text-align: center;
}

@media (max-width: 767px) {

.PublicPreferencesApproved__modal_XuCf {
    width: calc(100% - 10px);
    margin: 0 auto;
  }
}

.PublicPreferencesApproved__modal_XuCf .PublicPreferencesApproved__link_3QRE,
  .PublicPreferencesApproved__modal_XuCf .PublicPreferencesApproved__copy_17wr {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.PublicPreferencesApproved__copy_17wr {
  margin-top: 6px;
}

.PublicPreferencesApproved__link_button_1F08 {
  word-break: break-all;
}

.PublicPreferencesApproved__notification_3Wv9 {
  position: fixed;
  bottom: 32px;
  left: 32px;
  z-index: 9999999;
  width: 288px;
}

@media (max-width: 767px) {

.PublicPreferencesApproved__notification_3Wv9 {
    top: 32px;
    bottom: none;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}