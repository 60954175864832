.Pet__root_3I65 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 12px 32px;
  margin-right: -32px;
  margin-left: -32px;
  transition: background-color 0.25s ease-in-out;
}

  .Pet__root_3I65:hover {
    background-color: #f6f8fc;
}

  @media (max-width: 767px) {.Pet__root_3I65 {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0 16px;
    margin-right: -16px;
    margin-left: -16px;
  }
}

.Pet__header_actions_FDgi {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 18px 24px 12px;
}

.Pet__image_container_vPyu {
  margin-right: 20px;
}

@media (max-width: 767px) {

.Pet__image_container_vPyu {
    margin-right: 0;
    margin-bottom: 12px;
  }
}

.Pet__image_2TQg {
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 767px) {

.Pet__image_2TQg {
    width: 132px;
    height: 132px;
  }
}

.Pet__content_O5TA {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

@media (max-width: 767px) {

.Pet__content_O5TA {
    -webkit-align-items: center;
            align-items: center;
  }
}

.Pet__nickname_3UFp {
  width: 100%;
  max-width: 220px;
  margin-right: 25px;
}

@media (max-width: 767px) {

.Pet__nickname_3UFp {
    margin-right: 0;
  }
}

.Pet__nickname_title_2iLo {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Pet__info_3HR5 {
  min-width: 113px;
  margin-right: 59px;
}

@media (max-width: 767px) {

.Pet__info_3HR5 {
    margin-right: 0;
  }
}

.Pet__actions_146j {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  margin-right: 40px;
  margin-left: auto;
  opacity: 0;
  transition: 0.15s ease-in-out;
}

@media (max-width: 767px) {

.Pet__actions_146j {
    display: none;
  }
}

.Pet__root_3I65:hover .Pet__actions_146j {
    opacity: 1;
}