.MenuModals__title-button_3WpZ {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.MenuModals__title-icon_20Wu {
  display: inline-block;
  margin-right: 12px;
}

.MenuModals__title-icon_20Wu svg {
    display: block;
}

.MenuModals__change_3PFg {
  width: 536px;
}

@media (max-width: 767px) {

.MenuModals__change_3PFg {
    width: 100%;
    margin-top: 16px;
  }
}

.MenuModals__info-fields_12Mh {
  display: -webkit-flex;
  display: flex;
}

@media (max-width: 767px) {

.MenuModals__info-fields_12Mh {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.MenuModals__info-field_2xtL {
  width: 50%;
}

.MenuModals__info-field_2xtL + .MenuModals__info-field_2xtL {
    margin-left: 16px;
}

@media (max-width: 767px) {

.MenuModals__info-field_2xtL {
    width: 100%;
  }

    .MenuModals__info-field_2xtL + .MenuModals__info-field_2xtL {
      margin-top: 16px;
      margin-left: 0;
  }
}

.MenuModals__trip-name_24sZ {
  margin-top: 24px;
}

@media (max-width: 767px) {

.MenuModals__trip-name_24sZ {
    margin-top: 40px;
  }
}

.MenuModals__trip-name-label_2HRn {
  margin-bottom: 16px;
}

.MenuModals__delete_3T5Y {
  word-break: break-word;
}