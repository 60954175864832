.Status__inner_3VTF {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center
}

.Status__status_created_2BVt .Status__icon_2K-s {
      color: #ff991f
}

.Status__status_cancelled_3gBL .Status__icon_2K-s {
      color: #95a0b3
}

.Status__icon_2K-s {
  margin-right: 8px
}