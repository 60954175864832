@media (min-width: 768px) {.PublicProfileLayout__breadcrumbs_23WB {
    margin: 0 24px;
  }
}

.PublicProfileLayout__background_15_u {
  position: relative;
  display: block;
  overflow: hidden;
}

@media (min-width: 768px) {

.PublicProfileLayout__background_15_u {
    z-index: 200;
  }
}

.PublicProfileLayout__background_green_A_Az {
    background-color: #e1f0e5;
}

.PublicProfileLayout__background_G50_25H- {
    background-color: #f4f9e6;
}

.PublicProfileLayout__content_14zz {
  position: relative;
}

@media (min-width: 1280px) {

.PublicProfileLayout__content_14zz {
    min-height: calc(100vh - 474px);
  }
}

@media (min-width: 768px) {

.PublicProfileLayout__content_14zz {
    z-index: 201;
  }
}

.PublicProfileLayout__priority-image_1Y07 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 199;
  display: block;
  width: 600px;
  height: 400px;
  background-size: cover;
}

@media (max-width: 1023px) {

.PublicProfileLayout__priority-image_1Y07 {
    display: none;
  }
}

@media (max-width: 1439px) {

.PublicProfileLayout__priority-image-big-breakpoint_2Ol3 {
      display: none;
  }
}
