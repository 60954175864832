.UserData__UserData_2Uom {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

  .UserData__UserData_help_2N_y {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

  .UserData__UserData_noIndent_2YRo {
    padding: 0;
    margin: 0;
}

  @media (max-width: 767px) {.UserData__UserData_2Uom {
    padding: 0;
    margin: 0;
  }
      .UserData__UserData_revertheadingMobile_36dt .UserData__heading_1NMH {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
  }
}

  @media (max-width: 767px) {
      .UserData__UserData_noMobileAction_fHAC .UserData__action_1aoM {
        display: none;
  }

      .UserData__UserData_noMobileAction_fHAC .UserData__info_ctvO {
        width: 100%;
  }

      .UserData__UserData_noMobileAction_fHAC .UserData__item_1zxd {
        padding-right: 4px;
  }
}

  .UserData__UserData_2Uom:hover .UserData__action_1aoM {
    opacity: 1;
}

  @media (min-width: 768px) {
      .UserData__UserData_leftHeading_-phN .UserData__info_ctvO {
        position: relative;
  }

      .UserData__UserData_leftHeading_-phN .UserData__heading_1NMH {
        position: absolute;
        top: 0;
        left: 0;
        width: 52.5%;
  }

      .UserData__UserData_leftHeading_-phN .UserData__item_1zxd:first-child {
        width: 47.5%;
        margin-left: 52.5%;
  }

        .UserData__UserData_leftHeading_-phN .UserData__item_1zxd:first-child + .UserData__item_1zxd {
          width: 47.5%;
          margin-top: 8px;
          margin-left: 52.5%;
  }

      .UserData__UserData_leftHeading_-phN .UserData__type_3zoP {
        display: block;
        margin-left: 0;
  }
}

  .UserData__UserData_flexHeading_24Fa .UserData__heading_1NMH {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 8px;
}

  @media (max-width: 767px) {

  .UserData__UserData_flexHeading_24Fa .UserData__heading_1NMH {
        -webkit-align-items: stretch;
                align-items: stretch;
        margin-bottom: 12px;
  }
}

  .UserData__UserData_flexHeading_24Fa .UserData__type_3zoP {
      margin-top: 0;
      margin-left: 6px;
}

.UserData__info_ctvO {
  width: calc(100% - 40px);
}

.UserData__content_32VN {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%;
}

.UserData__heading_1NMH {
  width: 100%;
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.38);
}

@media (max-width: 767px) {

.UserData__heading_1NMH {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-align-items: stretch;
            align-items: stretch;
    margin-bottom: 16px;
  }

    .UserData__heading_1NMH > p:first-child {
      font-weight: 600;
  }
}

.UserData__type_3zoP {
  display: inline-block;
  margin-top: 4px;
}

@media (max-width: 767px) {

.UserData__type_3zoP {
    display: none;
  }
}

.UserData__type_mobile_2Ghd {
    display: none;
    margin-top: 4px;
}

.UserData__type_mobile_2Ghd p {
      text-transform: uppercase;
}

@media (max-width: 767px) {

.UserData__type_mobile_2Ghd {
      display: block;
  }
}

.UserData__item_column_3rk8 + .UserData__item_column_3rk8 + .UserData__item_column_3rk8 {
    margin-top: 8px;
}

@media (max-width: 767px) {

.UserData__item_column_3rk8 + .UserData__item_column_3rk8 + .UserData__item_column_3rk8 {
      margin-top: 0;
  }
}

.UserData__item_column_3rk8:nth-child(odd) {
    width: 52.5%;
}

@media (max-width: 767px) {

.UserData__item_column_3rk8:nth-child(odd) {
      width: 100%;
  }
}

.UserData__item_column_3rk8:nth-child(even) {
    width: 47.5%;
}

@media (max-width: 767px) {

.UserData__item_column_3rk8:nth-child(even) {
      width: 100%;
  }
}

.UserData__item_column_3rk8:last-child:first-child {
    width: 100%;
}

@media (max-width: 767px) {
    .UserData__item_column_3rk8:not(:last-child) {
      margin-bottom: 8px;
  }
}

.UserData__item_row_26Ma {
  width: 100%;
}

.UserData__item_row_26Ma:not(:first-child) {
    margin-top: 16px;
}

@media (max-width: 767px) {

.UserData__item_row_26Ma:not(:first-child) {
      margin-top: 8px;
  }
}

.UserData__action_1aoM {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

@media (max-width: 767px) {

.UserData__action_1aoM {
    -webkit-align-self: flex-start;
            align-self: flex-start;
    padding-right: 6px;
    opacity: 1;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
}

.UserData__help_1vVg {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 24px;
}

@media (max-width: 767px) {

.UserData__help_1vVg {
    padding-top: 4px;
    margin-bottom: 16px;
  }
}

.UserData__notification_1qzl {
  width: 100%;
  margin-bottom: 24px;
}
