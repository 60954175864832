.Card__card_2dup {
  position: relative;
  display: block;
}

  .Card__card_edit_1_sL {
    width: 100%;
    margin-bottom: 24px;
}

  .Card__card_2dup:not(:last-child) {
    margin-bottom: 6px;
}

  @media (max-width: 1280px) {

  .Card__card_2dup:not(:last-child) {
      margin-bottom: 0;
  }
}

.Card__box__gbm {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

@media (max-width: 1280px) {

.Card__box__gbm {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}

.Card__number_1Vfs {
  position: relative;
  display: block;
  width: 264px;
}

@media (max-width: 1280px) {

.Card__number_1Vfs {
    width: 100%;
    margin-top: 11px;
    margin-bottom: 24px;
  }
}

.Card__name_3sj2 {
  display: block;
  width: 264px;
}

@media (max-width: 1280px) {

.Card__name_3sj2 {
    width: 100%;
    margin-bottom: 24px;
  }
}

.Card__date_19ky {
  display: block;
  width: 112px;
}

@media (max-width: 1280px) {

.Card__date_19ky {
    width: calc(50% - 4px);
    margin-bottom: 24px;
  }
}

.Card__type_3VJf {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
  pointer-events: none;
}

.Card__cvc_1m1g {
  display: none;
}

@media (max-width: 1280px) {

.Card__cvc_1m1g {
    display: block;
    width: calc(50% - 4px);
    margin-bottom: 24px;
  }
}
