.PetPage__loader_Jh1F {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 300px;
}

.PetPage__empty_2WMM {
  min-height: 300px;
  padding-top: 40px;
}

.PetPage__root_32K2 {
  padding-top: 53px;
  padding-bottom: 63px;
}

@media (max-width: 767px) {

.PetPage__root_32K2 {
    padding-top: 24px;
  }
}

.PetPage__block_zXZV:not(:last-child) {
    margin-bottom: 24px;
}

@media (max-width: 959px) {

.PetPage__block_zXZV {
    margin-right: 8px;
    margin-left: 8px;
  }
}
