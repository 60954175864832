.Links__root_2Jif {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Links__button_1L88 {
  display: block;
  min-width: 196px;
}

@media (max-width: 767px) {

.Links__button_1L88 {
    min-width: 50%;
  }
}

@media (max-width: 374px) {

.Links__button_1L88 {
    min-width: 100%;
  }
}