.SkeletonProcessingConfirmationCode__root_3ai3 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: calc(100vh - 84px);
  background-color: #e1f0e5;
}

  @media (min-width: 768px) {.SkeletonProcessingConfirmationCode__root_3ai3 {
    min-height: calc(100vh - 84px);
    padding-top: 20px;
  }
}

  @media (max-width: 767px) {.SkeletonProcessingConfirmationCode__root_3ai3 {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
    height: 590px;
  }
}

.SkeletonProcessingConfirmationCode__desktop_3HKI {
  display: block;
  width: 360px;
  margin-left: 215px;
  background-color: #fff;
  border: solid 1px #e4e4e4;
  border-radius: 16px;
}

@media (max-width: 767px) {

.SkeletonProcessingConfirmationCode__desktop_3HKI {
    display: none;
  }
}

.SkeletonProcessingConfirmationCode__mobile_aD9S {
  display: block;
  width: 360px;
  height: 100%;
  background-color: #fff;
}

@media (min-width: 768px) {

.SkeletonProcessingConfirmationCode__mobile_aD9S {
    display: none;
  }
}
