.SkeletonLoaderRegistration__SkeletonLoaderRegistration_37RA {
  display: block;
  height: 100vh;
}

  @media (max-width: 767px) {.SkeletonLoaderRegistration__SkeletonLoaderRegistration_37RA {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    height: 644px;
    background-color: #fff;
  }
}

.SkeletonLoaderRegistration__desktop_3gqP {
  display: block;
  padding-top: 68px;
  padding-bottom: 172px;
  padding-left: 216px;
  background-color: #e1f0e5;
}

@media (max-width: 767px) {

.SkeletonLoaderRegistration__desktop_3gqP {
    display: none;
  }
}

.SkeletonLoaderRegistration__form_2CV8 {
  height: 684px;
  background-color: #fff;
  border-radius: 16px;
}

.SkeletonLoaderRegistration__form_2CV8,
.SkeletonLoaderRegistration__button_28Tf {
  display: block;
  width: 360px;
}

.SkeletonLoaderRegistration__mobile_2vmW {
  display: block;
  width: 360px;
  height: 100%;
  background-color: #fff;
}

@media (min-width: 768px) {

.SkeletonLoaderRegistration__mobile_2vmW {
    display: none;
  }
}
