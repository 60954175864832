.GroupAccount__root_1eWk {
  display: block;
  width: 100%;
  margin-top: 12px;
}

  .GroupAccount__root_1eWk .GroupAccount__icon-link_1Oyj {
    top: 4px;
    width: 20px;
}

  .GroupAccount__root_opened_2ZF5 .GroupAccount__chevron-icon_1-xP {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
}

  .GroupAccount__root_opened_2ZF5 .GroupAccount__collapse-button_1Yxe {
      margin-top: 38px;
}

  .GroupAccount__root_isSub_186I .GroupAccount__description_31k_ {
      margin-top: 10px;
}

  .GroupAccount__root_notification_1waA {
    margin-top: 0;
}

  .GroupAccount__root_isGroup_1Uc4 {
    margin-top: 0;
}

  .GroupAccount__root_isGroup_1Uc4 .GroupAccount__notification_3PXB {
      margin-bottom: 24px;
}

.GroupAccount__notification_3PXB {
  margin-bottom: 36px;
}

.GroupAccount__notification_3PXB b {
    font-weight: 600;
}

.GroupAccount__notification_3PXB p:not(:last-child) {
    margin-bottom: 8px;
}

.GroupAccount__title_1twj {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.GroupAccount__title_account_2xiL {
    height: 64px;
}

.GroupAccount__title_icon_3FWw {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    background: linear-gradient(86.42deg, #C4D600 0%, #5BB784 100%);
    border-radius: 50%;
    opacity: 0.6;
}

.GroupAccount__title_text_account_dg2B {
      display: -webkit-inline-flex;
      display: inline-flex;
      margin-left: 56px;
}

.GroupAccount__title_text_info_mK2z {
      width: 16px;
      height: 16px;
      margin-left: 6px;
}

.GroupAccount__title_text_info_mK2z svg {
        cursor: pointer;
}

.GroupAccount__title_action_oMHa {
    margin-left: auto;
}

.GroupAccount__description_31k_ {
  display: block;
  max-width: 664px;
  margin: 15px 0 0 56px;
}

.GroupAccount__rules_3zAX {
  display: -webkit-flex;
  display: flex;
  margin: 24px 0 0 56px;
}

.GroupAccount__rules_row_dcob {
    -webkit-flex-direction: column;
            flex-direction: column;
}

.GroupAccount__rule_w-14 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 194px;
}

.GroupAccount__rule_w-14:not(:last-child) {
    margin-right: 24px;
}

.GroupAccount__rule_icon_Sd0Z {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 40px;
    height: 36px;
}

.GroupAccount__rule_w-14 b {
  font-weight: 600;
}

.GroupAccount__actions_G41O {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 40px 0 0 56px;
}

.GroupAccount__actions_button_1cfr {
    width: 160px;
}

.GroupAccount__actions_link_YzS2 {
    margin-left: 26px;
}

.GroupAccount__actions_member_34LV {
    display: -webkit-flex;
    display: flex;
    margin: 20px 0 0 56px;
}

.GroupAccount__actions_member_button_2J65:not(:last-child) {
      margin-right: 37px;
}

.GroupAccount__collapse-button_1Yxe {
  margin: 20px 0 0 56px;
}

.GroupAccount__chevron-icon_1-xP {
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.GroupAccount__plus-icon_3TFO {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 5px;
}

.GroupAccount__divider_2zDl {
  width: 100%;
  height: 1px;
  color: #dde3ed;
  border: none;
}

.GroupAccount__travelers_title_mN4Q {
  display: -webkit-flex;
  display: flex;
  margin: 16px 0;
}

.GroupAccount__action_button_1km3 {
  width: 120px;
}

.GroupAccount__action_button_1km3:last-child {
    margin-left: 8px;
}

.GroupAccount__modal_suYY {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin-bottom: 8px;
}

.GroupAccount__modal_notification_3njF {
    margin-bottom: 17px;
}

.GroupAccount__modal_description_E5Lz {
    margin-bottom: 24px;
}

.GroupAccount__modal_find_1lLs {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 24px;
}

.GroupAccount__modal_username_2OzR {
    margin: 7px 0 0;
}

.GroupAccount__success-icon_Ru19 {
  color: #97ba1e;
}

.GroupAccount__modal_action_button_3maL {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .GroupAccount__rule_w-14 {
    width: 268px;
  }

    .GroupAccount__rule_w-14:not(:last-child) {
      margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .GroupAccount__root_1eWk {
    margin-top: 0;
  }
      .GroupAccount__root_opened_2ZF5 .GroupAccount__collapse-button_1Yxe {
        margin-top: 23px;
  }
      .GroupAccount__root_role_member_3Fqc .GroupAccount__description_31k_ {
        margin-top: 0;
  }

  .GroupAccount__notification_3PXB {
    margin: -8px 0 24px;
  }
    .GroupAccount__title_icon_3FWw {
      margin-right: 11px;
  }

    .GroupAccount__title_action_oMHa {
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: center;
              align-items: center;
      margin-right: -12px;
      color: #97ba1e;
      outline: none;
  }

  .GroupAccount__description_31k_ {
    margin: 3px 0 0 52px;
  }

  .GroupAccount__rules_3zAX {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 20px 0 0;
  }

  .GroupAccount__rule_w-14 {
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    margin-right: 0;
  }

    .GroupAccount__rule_text_2Jxm {
      margin-left: 12px;
  }

    .GroupAccount__rule_icon_Sd0Z {
      margin-bottom: 0;
  }

    .GroupAccount__rule_w-14:not(:last-child) {
      margin-bottom: 20px;
  }

  .GroupAccount__actions_G41O {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    margin: 18px 0 0 52px;
  }

    .GroupAccount__actions_link_YzS2 {
      margin: 0 0 17px 0;
  }

    .GroupAccount__actions_member_34LV {
      -webkit-flex-direction: column-reverse;
              flex-direction: column-reverse;
  }

      .GroupAccount__actions_member_button_2J65:last-child {
        margin-bottom: 16px;
        margin-left: 0;
  }

  .GroupAccount__collapse-button_1Yxe {
    margin: 14px 0 0 52px;
  }

  .GroupAccount__travelers_title_mN4Q {
    -webkit-align-items: center;
            align-items: center;
    padding: 16px 18px;
    margin: -24px -16px 16px;
    box-shadow: 0 4px 10px rgba(34, 42, 55, 0.13);
  }

    .GroupAccount__travelers_title_collapsed_uwPB {
      border-top: 1px solid #dde3ed;
  }

  .GroupAccount__modal_suYY {
    margin-bottom: 0;
  }

    .GroupAccount__modal_notification_3njF {
      margin-bottom: 17px;
  }

    .GroupAccount__modal_description_E5Lz {
      margin-bottom: 16px;
  }

    .GroupAccount__modal_find_1lLs {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      margin-top: 20px;
  }

    .GroupAccount__modal_username_2OzR {
      margin: 9px 0 0;
  }

  .GroupAccount__modal_action_button_3maL {
    min-width: 116px;
  }
}

.GroupAccount__unavailable_18AJ {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #7a869a;
}

@media (max-width: 787px) {

.GroupAccount__unavailable_18AJ {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    padding-left: 4px;
  }
}

.GroupAccount__unavailable_icon_2yn_ {
    margin-right: 8px;
}

@media (max-width: 787px) {

.GroupAccount__unavailable_icon_2yn_ {
      padding-top: 2px;
  }
}

.GroupAccount__unavailable_icon_2yn_ svg {
      display: block;
}

.GroupAccount__root_1eWk .GroupAccount__OnBoardingTooltip_ypha {
  z-index: auto !important;
  z-index: initial !important;
}

.GroupAccount__modal_actions_2PIi {
  padding-right: 0;
  padding-left: 0;
}
