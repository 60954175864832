.Flights__flight_2BOD:not(:first-child) {
    padding-top: 21px;
    margin-top: 20px;
    border-top: 1px solid #eceff5;
}
  @media (max-width: 767px) {
    .Flights__flight_2BOD:not(:first-child) {
      padding-top: 16px;
      margin-top: 15px;
      border-top: 1px solid #eceff5;
  }
}

.Flights__content_2pPF {
  display: -webkit-flex;
  display: flex;
  margin-top: 8px;
}

@media (max-width: 767px) {

.Flights__content_2pPF {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

@media (min-width: 768px) {

.Flights__short_1oXn {
    margin-top: 4px;
  }
}

.Flights__short-block_1-RQ {
  margin-left: 8px;
}

.Flights__slash_BR7A {
  color: #d0d6e1;
}

.Flights__info_3CHR {
  width: 100%;
}

.Flights__info-short_C6aR {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.Flights__info-short_C6aR .Flights__dates_2Fm5 {
    width: 100%;
    margin-left: 8px;
}

.Flights__dates_2Fm5, .Flights__bottom_KXhw {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Flights__dots_2rvU {
  width: 100%;
  height: 2px;
  background: radial-gradient(ellipse at center, #dde3ed 20%, #ffffff 20%);
  background-position: 0px center;
  background-size: 8px 8px;
}

@media (min-width: 768px) {

.Flights__dots_2rvU {
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
  }
}

.Flights__start-date_1Pkd, .Flights__end-date_3Yos {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.Flights__start-date_1Pkd {
  margin-right: 10px;
}

.Flights__end-date_3Yos {
  margin-left: 10px;
}

.Flights__bottom_KXhw {
  margin-top: 4px;
}

.Flights__flight-number_3lih {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-align-items: center;
          align-items: center;
  white-space: nowrap;
}

.Flights__flight-number_3lih svg {
    display: block;
}

.Flights__s7-icon_2w3n {
  margin-right: 8px;
}

.Flights__status_Bkc7 {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  width: 224px;
  margin-left: 32px;
}

@media (max-width: 767px) {

.Flights__status_Bkc7 {
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;
  }
}

.Flights__closed_2VRZ {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 48px;
  padding: 8px 20px;
  background-color: #f6f8fc;
  border-radius: 8px;
}

.Flights__banner_1rRk {
  margin-top: 28px;
}

@media (max-width: 767px) {

.Flights__banner_1rRk {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {

.Flights__banner-link_3VfN {
    margin-top: 17px;
  }
}

@media (min-width: 768px) {

.Flights__miles_2g_A {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    width: 100%;
  }
}

@media (max-width: 767px) {

.Flights__miles_2g_A {
    margin-top: -12px;
  }
}