

.SkeletonLoaderRegistrationFull__SkeletonLoaderRegistrationFull_3TFf {
  display: block;
}

  @media (max-width: 767px) {

.SkeletonLoaderRegistrationFull__SkeletonLoaderRegistrationFull_3TFf {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    height: 1620px;
    background-color: #fff;
  }
}

.SkeletonLoaderRegistrationFull__desktop_2NT1 {
  display: block;
  width: 644px;
  padding: 40px 32px;
  margin-top: 76px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgba(34, 42, 55, 0.13);
}

@media (max-width: 767px) {

.SkeletonLoaderRegistrationFull__desktop_2NT1 {
    display: none;
  }
}

.SkeletonLoaderRegistrationFull__mobile_3gMd {
  display: block;
  width: 360px;
  height: 100%;
  background-color: #fff;
}

@media (min-width: 768px) {

.SkeletonLoaderRegistrationFull__mobile_3gMd {
    display: none;
  }
}