.Offers__root_qYhx {
  padding-top: 40px;
  margin: 0 auto;
}

  @media (max-width: 767px) {.Offers__root_qYhx {
    padding: 24px 16px 0;
  }
}


.Offers__tiles_3zyE {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 32px;
}


@media (max-width: 767px) {


.Offers__tiles_3zyE {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
}


.Offers__bottom_1IQT {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 22px;
  border-top: 1px solid #dde3ed;
}


.Offers__item_Vqrs {
  width: 50%;
  margin-right: 24px;
}


@media (max-width: 767px) {


.Offers__item_Vqrs {
    width: 100%;
    margin-right: 0;
  }

    .Offers__item_Vqrs:not(:last-child) {
      margin-bottom: 32px;
  }
}


.Offers__item_Vqrs:last-child {
    margin-right: 0;
}


.Offers__small_2YXA {
  width: 25%;
}


@media (max-width: 767px) {


.Offers__small_2YXA {
    width: calc(50% - 8px);
  }

    .Offers__small_2YXA:nth-child(odd) {
      margin-right: 16px;
  }
}