.Modal__root_3-lH {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 991;
  width: 100%;
  height: 100%;
  padding-top: 56px;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #f1f3f8;
}

  .Modal__root_3-lH.Modal__white_386y {
    background-color: #ffffff;
}

  .Modal__root_3-lH.Modal__white_386y .Modal__content_30pt {
      padding: 16px;
}

.Modal__back_3-7m {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 992;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 56px;
  padding: 16px;
  color: inherit;
  background-color: #ffffff;
  border: none;
  border-bottom: 1px solid #dde3ed;
  outline: none;
}

.Modal__back_icon_meC4 {
  margin-right: 12px;
}

.Modal__close_3p7j {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 993;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 52px;
  height: 52px;
  color: inherit;
  outline: none;
}

.Modal__wrapper_1ntq {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100%;
}

.Modal__bottom_10Xh {
  padding: 16px;
  margin-top: auto;
}

.Modal__action_yVP9 + .Modal__action_yVP9 {
    margin-top: 8px;
}



