.Offer__root_2z3A {
  position: relative;
}

.Offer__image_1Cl0 {
  width: 100%;
  padding-top: 66.6%;
  background-color: #ffffff;
  background-position: center center;
  background-size: cover;
  border-radius: 8px;
}

.Offer__small_3LtL .Offer__image_1Cl0 {
    border-radius: 4px;
}

.Offer__image_1Cl0,
.Offer__text_3_UX {
  margin-bottom: 8px;
}

.Offer__text_3_UX {
  display: block;
  padding-right: 5px;
}