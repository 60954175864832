.Wrapper__root_3C3f {
  display: block;
  min-height: calc(100vh - 638px);
  padding-top: 60px;
  padding-bottom: 120px;
  background-color: #f1f3f8;
}

  @media (max-width: 767px) {.Wrapper__root_3C3f {
    padding-top: 0;
    padding-bottom: 55px;
  }
}

.Wrapper__content_zb97 {
  max-width: 720px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 767px) {

.Wrapper__content_zb97 {
    max-width: 100%;
    padding: 16px;
  }
}
