.Package__root_cN3R {
  position: relative;
  z-index: 1;
  display: block;
  padding: 28px 42px;
  overflow: hidden;
  border-radius: 16px;
}

  @media (max-width: 767px) {.Package__root_cN3R {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 413px;
    padding: 0;
    border-radius: 16px;
  }
}

.Package__background_3Vdf {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  background-position: top center;
  background-size: cover;
}

.Package__prolongation_1oR8 {
  position: absolute;
  top: 0;
  left: 0;
}

.Package__prolongation_1oR8 + .Package__top_HupI {
    -webkit-align-items: center;
            align-items: center;
}

@media (max-width: 767px) {

.Package__prolongation_1oR8 + .Package__top_HupI {
      -webkit-align-items: flex-start;
              align-items: flex-start;
  }
}

.Package__top_HupI {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

@media (max-width: 767px) {

.Package__top_HupI {
    padding: 42px 24px 0;
  }
}

.Package__buttons_1-kw {
  display: -webkit-flex;
  display: flex;
  margin-top: 66px;
}

@media (max-width: 767px) {

.Package__buttons_1-kw {
    display: block;
    padding: 24px;
    margin-top: auto;
    border-radius: 16px;
  }
}

.Package__blur_2tmV {
  -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px);
  color: #ffffff;
  background-color: transparent;
}

.Package__button_1q27 {
  min-width: 220px;
  margin-right: 8px;
}

@media (max-width: 767px) {

.Package__button_1q27 {
    width: 100%;
    margin-right: 0;
  }

    .Package__button_1q27:not(:first-child) {
      margin-top: 8px;
  }
}

.Package__title_3LSN {
  margin-bottom: 4px;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: #ffffff;
}

@media (min-width: 768px) {
    .Package__active_3oVL .Package__title_3LSN br {
      display: none;
  }
}

@media (max-width: 767px) {

.Package__title_3LSN {
    margin-right: 24px;
    margin-bottom: 0;
  }

    .Package__active_3oVL .Package__title_3LSN {
      font-size: 24px;
      line-height: 32px;
  }
}

.Package__code_1rqb {
  text-transform: capitalize;
}

@media (max-width: 767px) {
  .Package__text_ask- {
    display: none;
  }

  .Package__active_3oVL {
    min-height: 288px;
  }

    .Package__active_3oVL .Package__text_ask- {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      padding: 10px 16px;
      border-radius: 8px;
  }
}

.Package__theme_light_GFDM {
    background-image: radial-gradient(174.57% 2061.9% at 96.94%, at 26.54%, #83DB87 0%, #C3DA35 100%);
    background-image: radial-gradient(174.57% 2061.9% at 96.94% 26.54%, #83DB87 0%, #C3DA35 100%);
}

@media (max-width: 767px) {
      .Package__theme_light_GFDM.Package__active_3oVL .Package__text_ask-,
      .Package__theme_light_GFDM .Package__buttons_1-kw {
        background-color: #A1C756;
  }
}

.Package__theme_light_plus_1xWd {
    background-image: radial-gradient(174.57% 2061.9% at 96.94%, at 26.54%, #83DB87 0%, #C3DA35 100%);
    background-image: radial-gradient(174.57% 2061.9% at 96.94% 26.54%, #83DB87 0%, #C3DA35 100%);
}

@media (max-width: 767px) {
      .Package__theme_light_plus_1xWd.Package__active_3oVL .Package__text_ask-,
      .Package__theme_light_plus_1xWd .Package__buttons_1-kw {
        -webkit-backdrop-filter: blur(60px);
                backdrop-filter: blur(60px);
  }
}

.Package__theme_turbo_dUB4 {
    background: linear-gradient(88.04deg, #62DAEE 0.11%, #4380CF 99.85%), radial-gradient(69.8% 175.24% at 95.1%, at 0%, rgba(39, 41, 143, 0.4) 0%, rgba(39, 41, 143, 0) 100%), radial-gradient(36.84% 33.72% at 17.35%, at 104.18%, rgba(39, 41, 143, 0.3) 0%, rgba(39, 41, 143, 0) 100%), linear-gradient(0deg, #27344B, #27344B), #FFFFFF;
    background: linear-gradient(88.04deg, #62DAEE 0.11%, #4380CF 99.85%), radial-gradient(69.8% 175.24% at 95.1% 0%, rgba(39, 41, 143, 0.4) 0%, rgba(39, 41, 143, 0) 100%), radial-gradient(36.84% 33.72% at 17.35% 104.18%, rgba(39, 41, 143, 0.3) 0%, rgba(39, 41, 143, 0) 100%), linear-gradient(0deg, #27344B, #27344B), #FFFFFF;
}

@media (max-width: 767px) {
      .Package__theme_turbo_dUB4.Package__active_3oVL .Package__text_ask-,
      .Package__theme_turbo_dUB4 .Package__buttons_1-kw {
        background-color: #50A2DB;
  }
}

