.Preferences__item_2JjN {
  margin: 16px 0;
}

.Preferences__airport_description_1OUB {
    padding: 24px 0;
}

.Preferences__subscriptions_text_bs4P {
    margin-bottom: 18px;
}

.Preferences__subscriptions_types_4Ury {
    margin-top: 24px;
    margin-bottom: 42px;
}

.Preferences__subscriptions_checkbox_3DWe:not(:last-child) {
    margin-bottom: 20px;
}

.Preferences__subscriptions_methods_SSDz .Preferences__switchers_1ZY9 {
      margin-top: 18px;
}

.Preferences__switchers_1ZY9 {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: -20px;
}

.Preferences__switchers_1ZY9 .Preferences__switcher_3zMw {
    margin-right: 8px;
}

.Preferences__switchers_1ZY9 > div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 20px;
}

.Preferences__switchers_1ZY9 > div:not(:last-child) {
      margin-right: 42px;
}

.Preferences__consent-to-processing_22He {
  margin-top: 18px;
}

.Preferences__consent-to-processing_22He a {
    color: rgba(0, 0, 0, 0.54);
    text-decoration: underline;
}

.Preferences__greeting_description_xppr {
  margin-top: 18px;
}

.Preferences__doctor_25XK {
  width: 172px;
  height: 64px;
}

.Preferences__doctor_description_2GGh {
  margin: 24px 0;
}

.Preferences__selection_1p7s {
  max-width: 460px;
}

.Preferences__mobile_view_3zyB {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: -8px;
  margin-right: -16px;
  margin-bottom: -8px;
}

.Preferences__mobile_view_group_1gUY {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    margin-right: 56px;
    margin-bottom: 16px;
}

@media (max-width: 767px) {
  .Preferences__mobile_view_3zyB {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
