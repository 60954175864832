.BoardingPass__root_14M0 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 325;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: rgba(0, 0, 0, .87);
  opacity: 0;
  transition: opacity 0.1s cubic-bezier(0.465, 0.183, 0.153, 0.946);
  will-change: opacity
}

.BoardingPass__content_3nHG {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 325;
  display: block;
  width: 200px;
  height: 296px;
  overflow: hidden;
  pointer-events: inherit;
  background-size: cover;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%)
}

.BoardingPass__close_3RsH {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 325;
  display: none;
  padding: 0 16px 16px
}

.BoardingPass__close-visible_2h2t {
  display: block
}

.BoardingPass__visible_3_4t {
  overflow: hidden;
  pointer-events: auto;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946)
}
