.MilesPayment__action_2UBE {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 24px 0 0;
}

  @media (max-width: 767px) {.MilesPayment__action_2UBE {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 399;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    padding: 20px 16px;
    background-color: #fff;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: 0 0 1px 0 rgba(.25,.25,.27,0.05), 0 -1px 3px 0 rgba(.25,.25,.27,0.15);
  }
}

  .MilesPayment__action-button_36za {
    display: block;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    width: 168px;
    height: 48px;
    margin-right: 25px;
}

  @media (max-width: 767px) {

  .MilesPayment__action-button_36za {
      display: -webkit-flex;
      display: flex;
      width: 128px;
      height: 48px;
      cursor: pointer;
  }
}

  .MilesPayment__action-info_26eM {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

  @media (max-width: 767px) {

  .MilesPayment__action-info_26eM {
      min-width: 74px;
      margin-right: auto;
  }
}

  .MilesPayment__action-info_26eM div:not(:last-child) {
      margin-bottom: 5px;
}
