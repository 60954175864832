.Questions__root_36OX {
  max-width: 328px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto
}

.Questions__title_3PGD {
  margin-bottom: 12px
}

.Questions__icon_15Mm {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 12px;
  color: #505f79
}