.TripsLoaders__root_2cHS {
  padding-top: 60px;
  padding-bottom: 100px;
  background-color: #f1f3f8;
}

  @media (max-width: 767px) {.TripsLoaders__root_2cHS {
    padding-top: 8px;
    padding-right: 8px;
    padding-bottom: 55px;
    padding-left: 8px;
  }
}

@media (max-width: 767px) {

.TripsLoaders__trip-page_25Us {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 210;
    overflow-y: auto;
  }
}

.TripsLoaders__skeleton_RfgL {
  max-width: 720px;
  margin: 0 auto;
}

.TripsLoaders__card_2TZV + .TripsLoaders__card_2TZV {
  margin-top: 16px;
}

.TripsLoaders__switcher_2Umw {
  width: 320px;
  height: 40px;
  margin: 0 auto 32px;
  background-color: #ffffff;
  border-radius: 8px;
}

@media (max-width: 767px) {

.TripsLoaders__switcher_2Umw {
    width: 100%;
    margin-bottom: 24px;
  }
}

.TripsLoaders__trip-back_sfge {
  width: 194px;
  height: 24px;
  margin-bottom: 40px;
  background-color: #ffffff;
  border-radius: 8px;
}

@media (max-width: 767px) {

.TripsLoaders__trip-back_sfge {
    width: calc(100% + 16px);
    height: auto;
    margin: -8px -8px 0;
    border-radius: 0;
  }
}

.TripsLoaders__mobile_17Pm {
  display: none;
}

@media (max-width: 767px) {

.TripsLoaders__mobile_17Pm {
    display: block;
  }
}

.TripsLoaders__desktop_3K7w {
  display: block;
}

@media (max-width: 767px) {

.TripsLoaders__desktop_3K7w {
    display: none;
  }
}