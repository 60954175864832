.Trips__status_23dU {
  width: 100%;
  max-width: 320px;
  margin: 0 auto 32px;
}

  @media (max-width: 767px) {.Trips__status_23dU {
    max-width: 100%;
    padding: 8px;
    margin: 0;
    background-color: #ffffff;
  }
}

