.Cards__card_1YKB {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 172px;
  margin-bottom: 16px;
  overflow: hidden;
  border-radius: 16px;
}

  .Cards__card_1YKB.Cards__single_1fCC {
    margin-bottom: 0;
}

  .Cards__card_1YKB.Cards__single_1fCC:not(.Cards__isEmpty_26Lr) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
}

  .Cards__card_1YKB.Cards__single_1fCC .Cards__content_38Mn {
      cursor: default;
}

  @media (max-width: 767px) {

  .Cards__card_1YKB.Cards__single_1fCC .Cards__content_38Mn {
        padding: 16px;
  }
}

  .Cards__card_1YKB:after {
    z-index: 1;
    content: '';
    background: linear-gradient(180deg, rgba(34, 42, 55, 0.3) 0%, rgba(34, 42, 55, 0.7) 100%);
}

  @media (max-width: 767px) {.Cards__card_1YKB {
    min-height: 224px;
  }

    .Cards__card_1YKB.Cards__single_1fCC {
      z-index: 0;
      min-height: 200px;
      border-radius: 0;
  }

      .Cards__card_1YKB.Cards__single_1fCC .Cards__gradient_1hGn {
        height: 200px;
  }
}

.Cards__card-loader_3q5F + .Cards__card-loader_3q5F {
  margin-top: 16px;
}

@media (max-width: 767px) {

.Cards__card-loader_3q5F + .Cards__card-loader_3q5F {
    margin-top: 16px;
  }
}

.Cards__mobile_2m5y {
  display: none;
}

@media (max-width: 767px) {

.Cards__mobile_2m5y {
    display: block;
  }
}

.Cards__desktop_2VzZ {
  display: block;
}

@media (max-width: 767px) {

.Cards__desktop_2VzZ {
    display: none;
  }
}

.Cards__content_38Mn {
  position: relative;
  z-index: 3;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  padding: 24px;
  cursor: pointer;
  outline: none;
}

@media (max-width: 767px) {

.Cards__content_38Mn {
    padding: 22px 20px 20px;
  }
}

.Cards__card_1YKB:after, .Cards__processing_34gr {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Cards__processing_34gr {
  z-index: 5;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Cards__processing-fade_2EOC {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #222a37;
  opacity: 0.9;
}

.Cards__actions_xWwW {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 4;
}

.Cards__actions-button_29g_ {
  padding: 8px;
  color: #ffffff;
  outline: none;
  -webkit-transform: translate(8px, -8px);
          transform: translate(8px, -8px);
}

.Cards__menu_3ufp {
  position: absolute;
  top: calc(100% - 8px);
  right: 0;
  min-width: 160px;
  padding: 8px 0;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 8px;
}

.Cards__top_M95M {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #ffffff;
  opacity: 0.6;
}

.Cards__top-icon_85Ic:not(:first-child) {
    margin-left: 12px;
}

.Cards__bottom_10cz {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: auto;
}

@media (max-width: 767px) {

.Cards__bottom_10cz {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    height: 100%;
    margin-top: 26px;
  }
}

.Cards__title_1JDy {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}

.Cards__card_1YKB:hover .Cards__title_1JDy {
    display: -webkit-box;
    text-overflow: clip;
    white-space: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.Cards__description_3QZD {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: calc(100% - 288px - 16px);
}

@media (max-width: 767px) {

.Cards__description_3QZD {
    max-width: 100%;
    margin-top: 4px;
  }
}

.Cards__subtitle_1ugX {
  margin-top: 8px;
}

@media (max-width: 767px) {

.Cards__subtitle_1ugX {
    margin-top: 4px;
  }
}

.Cards__message_3JtN {
  max-width: 288px;
  padding: 12px;
  background: rgba(34, 42, 55, 0.4);
  border-radius: 8px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.Cards__message-miles_1_-4 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 6px 12px 6px 6px;
    border-radius: 18px;
}

.Cards__message-icon_2vY1 {
    display: inline-block;
    margin-right: 6px;
}

.Cards__message-icon-background_1vcb {
      position: absolute;
      z-index: -1;
      width: 20px;
      height: 20px;
      background: rgba(255, 255, 255);
      border-radius: 50%;
}

.Cards__message-icon_2vY1 svg {
      display: block;
}

.Cards__message-progress_3hvd {
    color: #97ba1e;
}

.Cards__message-unknown_2YJd {
    color: #95a0b3;
}

.Cards__message-warning_1ZJo {
    color: #ff991f;
}

@media (max-width: 767px) {

.Cards__message_3JtN {
    margin-top: 20px;
  }

    .Cards__message-miles_1_-4 {
      margin-right: 16px;
  }
}

.Cards__more_2ZBK {
  margin-top: 16px;
}

.Cards__gradient_1hGn {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  height: 100px;
}

@media (max-width: 767px) {

.Cards__gradient_1hGn {
    height: 120px;
  }
}

.Cards__green_Gy6W {
  background: linear-gradient(180deg, rgba(33, 167, 149, 0) 0%, rgba(33, 167, 149, 0.49) 51.04%, rgba(129, 161, 26, 0.8) 100%);
}

.Cards__peach_2vWt {
  background: linear-gradient(180deg, rgba(255, 153, 31, 0) 0%, rgba(255, 153, 31, 0.5) 51.04%, rgba(248, 91, 128, 0.8) 100%);
}

.Cards__violet_3VxW {
  background: linear-gradient(180deg, rgba(126, 87, 194, 0) 0%, rgba(126, 87, 194, 0.5) 51.04%, rgba(57, 92, 177, 0.8) 100%);
}

.Cards__turquoise_1Wxp {
  background: linear-gradient(180deg, rgba(87, 126, 220, 0) 0%, rgba(87, 126, 220, 0.5) 51.04%, rgba(0, 164, 186, 0.8) 100%);
}
