.PurchaseCard__root_3fif {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin-bottom: 26px;
  border: 1px solid #dde3ed;
  border-radius: 16px;
}

.PurchaseCard__header_3xJE {
  display: -webkit-flex;
  display: flex;
  min-height: 70px;
  padding: 24px 30px;
  background-color: #f6f8fc;
  border-bottom: 1px solid #dde3ed;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.PurchaseCard__link_3drJ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.PurchaseCard__left_2vMh,
.PurchaseCard__right_2OYm {
  display: -webkit-flex;
  display: flex;
}

.PurchaseCard__right_2OYm {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.PurchaseCard__date_G4If,
.PurchaseCard__id_1-Xh {
  margin-right: 80px;
}

.PurchaseCard__price_3Wom {
  margin-right: auto;
}

.PurchaseCard__content_2S4r {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  padding: 24px 33px;
  margin-right: -8px;
  margin-left: -8px;
  overflow: hidden;
}

@media (max-width: 768px) {

.PurchaseCard__content_2S4r {
    padding: 24px;
  }
}

@media (max-width: 411px) {

.PurchaseCard__content_2S4r {
    padding: 24px 16px;
  }
}

.PurchaseCard__item_2cd9 {
  width: 100%;
  min-width: 93px;
  max-width: 153px;
  margin-right: 8px;
  margin-left: 8px;
}

@media (max-width: 768px) {
    .PurchaseCard__item_2cd9:not(:nth-child(-n+5)) {
      display: none;
  }
}

@media (max-width: 629px) {
    .PurchaseCard__item_2cd9:not(:nth-child(-n+4)) {
      display: none;
  }
}

@media (max-width: 520px) {
    .PurchaseCard__item_2cd9:not(:nth-child(-n+3)) {
      display: none;
  }
}

@media (max-width: 411px) {
    .PurchaseCard__item_2cd9:not(:nth-child(-n+2)) {
      display: none;
  }
}

.PurchaseCard__image_2Vk6 {
  position: relative;
  width: 100%;
  padding-top: 66.92%;
  overflow: hidden;
  background-color: #ffffff;
  border: 1px transparent;
  border-radius: 8px;
}

.PurchaseCard__image_2Vk6 img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
}

.PurchaseCard__mobile_vLNV .PurchaseCard__left_2vMh,
  .PurchaseCard__mobile_vLNV .PurchaseCard__right_2OYm {
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    width: auto;
    width: initial;
}

.PurchaseCard__mobile_vLNV .PurchaseCard__date_G4If,
  .PurchaseCard__mobile_vLNV .PurchaseCard__id_1-Xh {
    margin-right: 0;
}

.PurchaseCard__mobile_vLNV .PurchaseCard__left_2vMh {
    margin-right: 10px;
}

.PurchaseCard__mobile_vLNV .PurchaseCard__right_2OYm {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

.PurchaseCard__mobile_vLNV .PurchaseCard__price_3Wom {
    margin-right: 0;
}

.PurchaseCard__mobile_vLNV .PurchaseCard__status_3HxW {
    text-align: right;
}
