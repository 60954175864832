.MilesActionModal__root_2Tf6 {
  position: relative;
  max-width: 320px;
  padding: 32px;
  background-color: #fff;
  border-radius: 16px;
}

.MilesActionModal__close_2tCh {
  position: absolute;
  top: 16px;
  right: 16px;
}

@media (max-width: 767px) {

.MilesActionModal__close_2tCh {
    display: none;
  }
}

.MilesActionModal__title_2hf_ {
  display: -webkit-flex;
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid #DFE4EE;
}

.MilesActionModal__title-icon_6EdJ {
    display: inline-block;
    margin-right: 12px;
    font-family: 'Segoe UI Emoji';
}

.MilesActionModal__description_3WmU {
  margin-top: 20px;
}

.MilesActionModal__action-button_1omb {
  margin-top: 24px;
}
