@media (max-width: 767px) {.MilesPhysicalCard__root_2phI {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 998;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background-color: #ffffff;
  }
}

.MilesPhysicalCard__header_3vF3 {
  display: none;
}

@media (max-width: 767px) {

.MilesPhysicalCard__header_3vF3 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 18px 16px;
    border-bottom: 1px solid #dde3ed;
  }
}

@media (max-width: 767px) {

.MilesPhysicalCard__close_2Xst {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0;
    margin-left: auto;
    color: inherit !important;
    background-color: transparent;
    outline: none;
  }
}

.MilesPhysicalCard__content_1X0F {
  padding-top: 24px;
}

@media (max-width: 767px) {

.MilesPhysicalCard__content_1X0F {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding: 8px;
    overflow: auto;
  }
}

.MilesPhysicalCard__new-order_13FK {
  padding-bottom: 104px;
}

@media (min-width: 768px) {

.MilesPhysicalCard__new-order_13FK {
    padding-bottom: 20px;
  }
}

.MilesPhysicalCard__order_3B4H:not(:last-child) {
    margin-bottom: 12px;
}

.MilesPhysicalCard__footer_yLR1 {
  padding-top: 24px;
}

@media (min-width: 768px) {

.MilesPhysicalCard__footer_yLR1 {
    padding-top: 40px;
  }
}
