.QRCodes__add_1hzJ {
  margin-top: 25px
}

.QRCodes__input_2PxK {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0
}