.UserInfo__root_1RFX {
  display: -webkit-flex;
  display: flex;
}

.UserInfo__avatar_210o {
  margin-right: 32px;
}

.UserInfo__info_19W- {
  width: 100%;
  overflow: hidden;
}

.UserInfo__name_3UkG {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 8px;
}

.UserInfo__row_I4Km {
  display: -webkit-flex;
  display: flex;
  margin-top: 20px;
}

.UserInfo__col_1CVC:not(:first-child) {
    margin-left: 23px;
}

.UserInfo__col_1CVC:not(:last-child) {
    padding-right: 23px;
    border-right: 1px solid #dde3ed;
}

.UserInfo__loyalty_1q1t {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  max-width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #97ba1e;
}

.UserInfo__loyalty_1q1t:hover {
    color: #869d22;
}

.UserInfo__loyalty_1q1t svg {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.UserInfo__link_2utH {
  display: block;
  width: 100%;
  margin-right: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.UserInfo__link_2utH.UserInfo__collecting_3y9e {
    margin-right: 0;
    margin-left: 6px;
}

@media (max-width: 767px) {
  .UserInfo__avatar_210o {
    margin-right: 16px;
  }

  .UserInfo__row_I4Km {
    margin-top: 12px;
  }

  .UserInfo__info-loader_1LiE {
    width: 335px;
  }

  .UserInfo__name_3UkG {
    margin-bottom: 2px;
  }

  .UserInfo__col_1CVC {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }

  .UserInfo__count_2Czq {
    margin-right: 4px;
  }
}

@media (max-width: 599px) {
  .UserInfo__info-loader_1LiE {
    width: 160px;
  }

  .UserInfo__miles_25gG {
    margin-right: 0;
  }
}

.UserInfo__color--green_2x_y {
  color: #97ba1e;
}

.UserInfo__isPublic_2WrB {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.UserInfo__isPublic_2WrB .UserInfo__avatar_210o {
    margin: 0;
}

.UserInfo__isPublic_2WrB .UserInfo__info_19W- {
    width: auto;
    padding: 0;
    margin-top: 20px;
}

.UserInfo__isPublic_2WrB .UserInfo__name_3UkG {
    margin: 0;
}
