.Contacts__notification_2-mf {
  margin-bottom: 24px;
}

  .Contacts__notification-success_1sQZ {
    position: fixed;
    bottom: 32px;
    left: 32px;
    z-index: 9999999;
    width: 288px;
}

.Contacts__contacts_3wZU {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-basis: 50%;
          flex-basis: 50%;
}

@media (max-width: 1280px) {
  .Contacts__notification-success_1sQZ {
    right: 16px;
    bottom: 16px;
  }
}

@media (max-width: 767px) {
  .Contacts__contacts_3wZU {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .Contacts__notification-success_1sQZ {
    right: 8px;
    bottom: 8px;
    left: 8px;
    width: auto;
  }
}
