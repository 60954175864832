.ProfileOffers__profileOffers_gj_B {
  display: block;
  min-height: calc(100vh - 638px);
  padding-bottom: 120px;
  background-color: #f1f3f8;
}

  @media (max-width: 767px) {.ProfileOffers__profileOffers_gj_B {
    padding-bottom: 26px;
    background-color: #f5f5f5;
  }
}
