.SkeletonLoaderHeader__SkeletonLoaderHeader_eoc8 {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 80px;
  overflow: hidden;
  box-shadow: 0px 1px 6px 1px rgba(29, 33, 41, 0.13);
}

  @media (max-width: 1023px) {.SkeletonLoaderHeader__SkeletonLoaderHeader_eoc8 {
    height: 52px;
  }
}
