.SkeletonLoader__root_3TY8 {
  padding-top: 40px;
  padding-bottom: 120px;
}

  .SkeletonLoader__root_3TY8:not(.SkeletonLoader__theme_simple_22ou):not(.SkeletonLoader__theme_once_11Oy) + .SkeletonLoader__root_3TY8 {
    padding-top: 32px;
    margin-top: -120px;
}

  .SkeletonLoader__root_3TY8:not(.SkeletonLoader__theme_simple_22ou).SkeletonLoader__theme_once_11Oy + .SkeletonLoader__root_3TY8.SkeletonLoader__theme_once_11Oy {
    padding-top: 16px;
}


.SkeletonLoader__SkeletonLoader_1FkD {
  position: relative;
  display: block;
  padding: 16px 24px 0;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0 4px 10px -2px rgba(29, 33, 41, 0.13);
}


@media (max-width: 767px) {


.SkeletonLoader__SkeletonLoader_1FkD {
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }
}


.SkeletonLoader__title_1DaB {
  position: relative;
  z-index: 2;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #dde3ed;
  transition: box-shadow 0.3s;
}


@media (max-width: 767px) {


.SkeletonLoader__title_1DaB {
    padding: 18px 24px;
    border-bottom-width: 0;
    box-shadow: 0 4px 10px -2px rgba(29, 33, 41, 0.13);
  }
}


.SkeletonLoader__content_1_fG {
  padding: 24px 0;
}


@media (max-width: 767px) {


.SkeletonLoader__content_1_fG {
    padding: 24px;
  }
}


.SkeletonLoader__mobile_1qVY {
  display: none;
}


@media (max-width: 767px) {


.SkeletonLoader__mobile_1qVY {
    display: block;
  }
}


.SkeletonLoader__desktop_3fUW {
  display: block;
}


@media (max-width: 767px) {


.SkeletonLoader__desktop_3fUW {
    display: none;
  }
}


.SkeletonLoader__item_27_9 {
  display: block;
  margin-bottom: 24px;
  overflow: hidden;
}


.SkeletonLoader__flex_cm5X {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  max-width: 100%;
}


.SkeletonLoader__flex_cm5X:not(:last-child) {
    margin-bottom: 24px;
}


.SkeletonLoader__flex_cm5X > .SkeletonLoader__item_27_9,
  .SkeletonLoader__flex_cm5X > .SkeletonLoader__items_12j- {
    margin-bottom: 0;
}


.SkeletonLoader__flex_cm5X > .SkeletonLoader__item_27_9:not(:last-child) {
    margin-right: 16px;
}


.SkeletonLoader__items_12j- {
  display: block;
}


.SkeletonLoader__items_12j- > .SkeletonLoader__item_27_9:last-child {
    margin-bottom: 0;
}


.SkeletonLoader__circle_BL2I {
  position: absolute;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  overflow: hidden;
  border-radius: 50%;
}


.SkeletonLoader__theme_once_11Oy {
  padding-top: 0;
  padding-bottom: 0;
}


.SkeletonLoader__theme_once_11Oy .SkeletonLoader__SkeletonLoader_1FkD {
    padding-top: 11px;
}


.SkeletonLoader__theme_once_11Oy .SkeletonLoader__content_1_fG {
    padding-bottom: 19px;
}


.SkeletonLoader__theme_once_11Oy .SkeletonLoader__item_27_9 {
    margin-top: -16px;
    margin-bottom: 0 !important;
}


.SkeletonLoader__theme_center_1E7j,
.SkeletonLoader__theme_simple_22ou {
  display: block;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
}


.SkeletonLoader__theme_center_1E7j .SkeletonLoader__SkeletonLoader_1FkD,
  .SkeletonLoader__theme_center_1E7j .SkeletonLoader__content_1_fG,
  .SkeletonLoader__theme_simple_22ou .SkeletonLoader__SkeletonLoader_1FkD,
  .SkeletonLoader__theme_simple_22ou .SkeletonLoader__content_1_fG {
    padding: 0;
    border-radius: 0;
    box-shadow: none;
}


.SkeletonLoader__theme_center_1E7j .SkeletonLoader__SkeletonLoader_1FkD,
  .SkeletonLoader__theme_center_1E7j .SkeletonLoader__content_1_fG {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}