.Marketplace__wrap_2bIg {
  margin-top: 40px;
}

  @media (max-width: 767px) {.Marketplace__wrap_2bIg {
    margin-top: 16px;
  }
}

.Marketplace__root_2Sut {
  position: relative;
  padding: 24px;
  background-color: #44B0D9;
}

@media (max-width: 767px) {

.Marketplace__root_2Sut {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.Marketplace__description_3P1h {
  position: relative;
  z-index: 1;
  margin-top: 4px;
}

@media (max-width: 767px) {

.Marketplace__description_3P1h {
    max-width: 225px;
  }
}

.Marketplace__button_PHDE {
  position: relative;
  z-index: 1;
  margin-top: 16px;
}

@media (max-width: 767px) {

.Marketplace__button_PHDE {
    margin-top: 26px;
  }
}

.Marketplace__img_1uEx {
  position: absolute;
  top: -12px;
  right: 11px;
}

@media (max-width: 767px) {

.Marketplace__img_1uEx {
    top: 64px;
    right: 0;
  }
}