
.PageContainer__root_UEB5 {
  display: block;
  height: auto
}

.PageContainer__bg-color-light-green_LCoN {
  background-color: #e1f0e5
}

@media (min-width: 768px) {

.PageContainer__breadcrumb_2Dpv {
    margin: 0 24px
  }
}