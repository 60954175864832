.ContactEdit__root_3-8c {
  position: relative;
  width: 360px;
  padding: 24px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 16px 32px rgba(34, 42, 55, 0.13);
}

.ContactEdit__title_2-6F {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 16px;
}

.ContactEdit__title_icon_3Jz0 {
    color: #7a869a;
    outline: none;
}

.ContactEdit__notification_30dx {
  margin-bottom: 16px;
}

.ContactEdit__info_1d51 {
  margin-bottom: 16px;
}

.ContactEdit__form_3O3H {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.ContactEdit__actions_stmB {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 32px;
}

.ContactEdit__actions_stmB button:not(:last-child) {
    margin-bottom: 8px;
}

.ContactEdit__actions-sent-code_18pW button:not(:last-child) {
      margin-bottom: 0;
}

.ContactEdit__actions-sent-code_18pW button:first-child {
      margin-bottom: 16px;
}

.ContactEdit__loader_1agc {
  position: absolute;
  bottom: -20px;
  left: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.ContactEdit__overlay_2-xK {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 991;
  display: -webkit-flex;
  display: flex;
  background-color: #222a37;
  opacity: 0.6;
}

@media (max-width: 1280px) {
  .ContactEdit__root_3-8c {
    position: fixed;
    top: 96px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 992;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    padding: 16px;
    margin: 0;
    overflow: auto;
    background-color: #ffffff;
    border-radius: 0;
  }

  .ContactEdit__title_2-6F {
    position: fixed;
    top: 40px;
    right: 0;
    left: 0;
    z-index: 992;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    min-height: 56px;
    padding: 0 16px;
    background-color: #ffffff;
    border-bottom: 1px solid #dde3ed;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

    .ContactEdit__title_icon_3Jz0 {
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: center;
              align-items: center;
      outline: none;
  }

  .ContactEdit__loader_1agc {
    position: absolute;
    top: 50%;
    bottom: unset;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }

  .ContactEdit__actions_stmB {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-justify-content: center;
            justify-content: center;
  }

    .ContactEdit__actions_stmB button:not(:last-child) {
      margin-left: 8px;
  }

    .ContactEdit__actions-sent-code_18pW {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
  }

      .ContactEdit__actions-sent-code_18pW button:not(:last-child) {
        margin-left: 0;
  }

      .ContactEdit__actions-sent-code_18pW button:first-child {
        margin-bottom: 24px;
  }
}

@media (max-width: 1280px) and (min-width: 768px) {
    .ContactEdit__actions_stmB button {
      width: calc(50% - 4px);
  }

    .ContactEdit__actions_stmB .ContactEdit__resend_21r8 {
      width: auto;
  }
}

@media (max-width: 767px) {
  .ContactEdit__actions_stmB {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

    .ContactEdit__actions_stmB button:not(:last-child) {
      margin-bottom: 8px;
      margin-left: 0;
  }
      .ContactEdit__actions-sent-code_18pW button:not(:last-child) {
        margin-bottom: 0;
  }

      .ContactEdit__actions-sent-code_18pW button:first-child {
        margin-bottom: 16px;
  }
}