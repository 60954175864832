.Archive__filters-wrap_34ig {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 32px;
}

  @media (max-width: 767px) {.Archive__filters-wrap_34ig {
    display: block;
    margin-bottom: 16px;
  }
}