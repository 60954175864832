.RegistrationFull__root_1gZz {
  padding-top: 76px;
  padding-bottom: 172px;
}

.RegistrationFull__form-wrap_cLmU {
  display: block;
  width: 664px;
  padding: 40px 32px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 4px 10px rgba(34, 42, 55, 0.13);
}

@media (max-width: 767px) {

.RegistrationFull__form-wrap_cLmU {
    width: 100%;
    padding: 28px 16px 16px;
    margin: 0;
    border-radius: 0;
  }
}

.RegistrationFull__title_3LCR {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 24px;
}

@media (max-width: 767px) {

.RegistrationFull__title_3LCR {
    margin-bottom: 16px;
  }
}

.RegistrationFull__b2b_qyef {
  max-width: 70%;
}

@media (max-width: 767px) {

.RegistrationFull__b2b_qyef {
    max-width: 100%;
  }
}

.RegistrationFull__loyalty-provider_2nmk {
  margin-left: 10px;
}

@media (max-width: 767px) {

.RegistrationFull__loyalty-provider_2nmk {
    margin-left: 0;
  }
}

.RegistrationFull__form-section_3fKt {
  display: block;
  margin-bottom: 60px;
}

.RegistrationFull__section-title_3Myu {
  display: block;
  margin-bottom: 24px;
}

@media (max-width: 767px) {

.RegistrationFull__section-title_3Myu {
    margin-bottom: 20px;
  }
}

.RegistrationFull__section-title_personal_14oa {
    margin-top: -40px;
    margin-bottom: 40px;
}

.RegistrationFull__personal_316R {
  margin-top: 16px;
}

.RegistrationFull__personal-fields_3r7j {
  position: relative;
  display: block;
  width: 288px;
}

@media (max-width: 767px) {

.RegistrationFull__personal-fields_3r7j {
    width: 100%;
  }
}

.RegistrationFull__card_2EsE {
  position: absolute;
  top: 0;
  left: calc(100% + 24px);
  display: block;
  width: 360px;
  height: 240px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.RegistrationFull__card-info_2LZp {
  position: absolute;
  top: 24px;
  right: 24px;
  display: block;
  color: #222a37;
  text-align: right;
}

.RegistrationFull__card-owner_2mHP {
  position: absolute;
  top: 80px;
  right: 23px;
  left: 23px;
}

.RegistrationFull__card-field_3Etv {
  position: absolute;
  top: 80px;
  right: 24px;
  left: 24px;
}

.RegistrationFull__card-field_3Etv + .RegistrationFull__card-field_3Etv {
    top: 160px;
}

.RegistrationFull__has-card_Tsik {
  display: block;
  margin-top: 18px;
  margin-bottom: -2px;
}

@media (max-width: 767px) {

.RegistrationFull__has-card_Tsik {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.RegistrationFull__buttons_2HSs {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

@media (max-width: 767px) {

.RegistrationFull__buttons_2HSs {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.RegistrationFull__skip-button_1H85 {
  margin-left: 32px;
}

@media (max-width: 767px) {

.RegistrationFull__skip-button_1H85 {
    margin-top: 16px;
    margin-left: 0;
  }
}

.RegistrationFull__submit-btn_2VD9 {
  display: block;
  width: 100%;
}

@media (min-width: 768px) {

.RegistrationFull__submit-btn_2VD9 {
    width: 224px;
  }
}

@media (max-width: 767px) {

.RegistrationFull__submit-btn_2VD9 {
    margin-top: 24px;
  }
}

.RegistrationFull__hr_h69T {
  display: block;
  width: 100%;
  margin-top: 36px;
}

@media (max-width: 767px) {

.RegistrationFull__hr_h69T {
    margin-top: 20px;
  }
}

.RegistrationFull__contacts_sTWz {
  margin-bottom: 24px;
}

@media (max-width: 767px) {

.RegistrationFull__contacts_sTWz {
    margin-bottom: 0;
  }
}

.RegistrationFull__parent-card_title_35Cx {
  font-weight: bold;
}

@media (max-width: 767px) {

.RegistrationFull__parent-card_title_35Cx {
    margin-left: 40px;
    font-size: 14px;
    font-weight: normal;
  }
}

.RegistrationFull__latin-field_2mIf {
  z-index: 0;
  width: 100%;
  margin-bottom: 16px;
}

.RegistrationFull__mobile_2gOg {
  display: block;
}

@media (min-width: 768px) {

.RegistrationFull__mobile_2gOg {
    display: none;
  }
}

.RegistrationFull__desktop_mE44 {
  display: block;
}

@media (max-width: 767px) {

.RegistrationFull__desktop_mE44 {
    display: none;
  }
}

.RegistrationFull__agreement_wHr3 {
  color: #7a869a;
  -webkit-user-select: none;
          user-select: none;
}

.RegistrationFull__agreement_wHr3 a {
    font-weight: 600;
    color: #505f79;
    transition: color 0.3s ease;
}

.RegistrationFull__agreement_wHr3 a:hover {
      color: #222a37;
      text-decoration: underline;
}

.RegistrationFull__service-error_39_f {
  display: block;
  margin: 24px 0;
}
