.RippleElement__root_H73r {
  position: relative;
  overflow: hidden
}

.RippleElement__ripple_2Yp3 {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #000;
  border-radius: 100%;
  opacity: 0.25;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-animation-name: RippleElement__ripple-effect_Ka8b;
          animation-name: RippleElement__ripple-effect_Ka8b;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease
}

@-webkit-keyframes RippleElement__ripple-effect_Ka8b {
  to {
    opacity: 0;
    -webkit-transform: scale(8);
            transform: scale(8)
  }
}

@keyframes RippleElement__ripple-effect_Ka8b {
  to {
    opacity: 0;
    -webkit-transform: scale(8);
            transform: scale(8)
  }
}