.PasswordStrengthTip__list_2emN ul {
    list-style: outside
}
  .PasswordStrengthTip__list_2emN.PasswordStrengthTip__variantDefault_1t0p {
    margin-left: 24px
}
  .PasswordStrengthTip__list_2emN.PasswordStrengthTip__variantDimmed_1xp3 {
    margin-left: 20px
}

.PasswordStrengthTip__title_3s0T.PasswordStrengthTip__variantDefault_1t0p {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

    margin-bottom: 8px
}

.PasswordStrengthTip__title_3s0T.PasswordStrengthTip__variantDimmed_1xp3 {
    color: #7a869a;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    margin-bottom: 6px
}

.PasswordStrengthTip__title_3s0T.PasswordStrengthTip__error_2RJ5 {
    color: #f14d56
}

.PasswordStrengthTip__requirement_2XAL.PasswordStrengthTip__variantDefault_1t0p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px
}

.PasswordStrengthTip__requirement_2XAL.PasswordStrengthTip__variantDimmed_1xp3 {
    color: #7a869a;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px
}

.PasswordStrengthTip__requirement_2XAL.PasswordStrengthTip__error_2RJ5 {
    color: #f14d56
}