.Avatar__root_104k {
  position: relative;
}

@media (min-width: 768px) {

.Avatar__modal-buttons_2DpP {
    padding-right: 32px;
    padding-left: 32px;
  }
}

.Avatar__modal-buttons_2DpP > * {
    -webkit-flex-basis: auto !important;
            flex-basis: auto !important;
}

@media (min-width: 768px) {

.Avatar__modal-buttons_2DpP > * {
      width: 100% !important;
  }
}

.Avatar__avatar-default-size_1PZ_ {
  width: 120px;
  height: 120px;
}

.Avatar__avatar-small-size_6sVP {
  width: 90px;
  height: 90px;
}

.Avatar__avatar_1tcG {
  position: relative;
  display: block;

  overflow: hidden;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}

@media (max-width: 767px) {

.Avatar__avatar_1tcG {
    width: 80px;
    height: 80px;
  }
}

.Avatar__avatar_disabled_ZHOK .Avatar__fullsize_1g30,
    .Avatar__avatar_disabled_ZHOK .Avatar__button_x0wc {
      pointer-events: none;
}

.Avatar__avatar_public_2Urw {
    margin: 0 auto;
}

.Avatar__avatar_public_2Urw .Avatar__image_3Y0S {
      display: -webkit-flex;
      display: flex;
}

.Avatar__avatar_1tcG:hover .Avatar__blurred_JVWd,
    .Avatar__avatar_1tcG:hover .Avatar__overlay_1u8N {
      pointer-events: all;
      opacity: 1;
}

.Avatar__avatar_1tcG[focus-within] .Avatar__blurred_JVWd,
    .Avatar__avatar_1tcG[focus-within] .Avatar__overlay_1u8N {
      pointer-events: all;
      opacity: 1;
}

.Avatar__avatar_1tcG:focus-within .Avatar__blurred_JVWd,
    .Avatar__avatar_1tcG:focus-within .Avatar__overlay_1u8N {
      pointer-events: all;
      opacity: 1;
}

.Avatar__avatar_1tcG:focus {
    outline: 5px auto rgb(77, 144, 254);
}

.Avatar__avatar_1tcG:focus .Avatar__blurred_JVWd,
    .Avatar__avatar_1tcG:focus .Avatar__overlay_1u8N {
      opacity: 1;
}

.Avatar__image_3Y0S {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-position: center center;
  background-size: cover;
}

.Avatar__image_empty_m98j::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: linear-gradient(86.42deg, #C4D600 0%, #5BB784 100%);
    opacity: 0.5;
}

.Avatar__image_3Y0S svg {
    z-index: 1;
}

.Avatar__name_FMbe {
  position: relative;
  margin-left: 6px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 6px;
}

@media (max-width: 767px) {

.Avatar__name_FMbe {
    margin-left: 4px;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 4px;
  }
}

.Avatar__fullsize_1g30,
.Avatar__button_x0wc {
  padding: 0;
  color: currentColor;
  cursor: pointer;
  background-color: transparent;
  border-style: none;
  outline-style: none;
  transition: color 0.2s;
}

.Avatar__fullsize_1g30:focus, .Avatar__button_x0wc:focus {
    outline: 5px auto rgb(77, 144, 254);
}

.Avatar__fullsize_1g30 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 100%;
}

.Avatar__blurred_JVWd {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-filter: blur(2px);
          filter: blur(2px);
  background-position: center center;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.2s;
}

@media (max-width: 767px) {

.Avatar__blurred_JVWd {
    display: none;
  }
}

.Avatar__overlay_1u8N {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  padding: 0 10px;
  color: #fff;
  background-color: rgba(34, 42, 55, 0.2);
  opacity: 0;
  transition: opacity 0.2s;
}

.Avatar__overlay--without-image_34pD {
    padding: 0;
}

@media (max-width: 767px) {

.Avatar__overlay_1u8N {
    display: none;
  }
}

.Avatar__openOverlay_1D-P {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {

.Avatar__openOverlay_1D-P {
    display: block;
  }
}

.Avatar__mobileOverlay_1fBZ {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.4);
}

.Avatar__buttons_c1Gf {
  margin-top: 10px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 13px;
}

@media (min-width: 768px) {

.Avatar__avatar-delete__confirm_3tc5 {
    padding-top: 24px;
  }
}

@media (max-width: 767px) {

.Avatar__avatar-delete__confirm_3tc5 {
    padding: 16px 0;
  }
}

@media (max-width: 767px) {
  .Avatar__avatar-editor_2MLy {
    padding-top: 32px;
  }

  .Avatar__button_x0wc {
    display: block;
    width: 100%;
    height: 57px;
    font-size: 20px;
    color: #007aff;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
  }

    .Avatar__button_red_3aId {
      color: #ff3b30;
  }

    .Avatar__button_bold_26Zt {
      font-weight: 700;
      background-color: #fff;
  }

    .Avatar__button_x0wc:not(:first-child) {
      margin-top: 1px;
  }
}

.Avatar__input_3e68 {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
}

.Avatar__editor_rotate-buttons_3aFp {
    position: absolute;
    right: 0;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
}

.Avatar__editor_rotate-left_1HbI {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}

.Avatar__avatar-editor_2MLy {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  max-height: 400px;
}

.Avatar__avatar-editor_2MLy .cropper-view-box {
      border-radius: 50%;
}

.Avatar__avatar-editor_2MLy .cropper-wrap-box {
      background-color: #ffffff;
}

.Avatar__loader_2Gsa {
  position: absolute;
  top: 50%;
  left: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.Avatar__loader_2Gsa::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: linear-gradient(86.42deg, #C4D600 0%, #5BB784 100%);
    opacity: 0.5;
}

.Avatar__doctor-icon_1KuJ {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 32px;
  height: 32px;
}

@media (max-width: 767px) {

.Avatar__doctor-icon_1KuJ {
    right: -4px;
    bottom: -4px;
    width: 28px;
    height: 28px;
  }
}

.Avatar__doctor-outline_2U2z {
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  z-index: -1;
  display: block;
  background: linear-gradient(180deg, #01E4AF 0%, #01B48A 100%);
  border-radius: 50%;
}

.Avatar__doctor-outline_2U2z:before {
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    display: block;
    content: '';
    background: #fff;
    border-radius: 50%;
}