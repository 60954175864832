.TransactionsDiagram__root_386f {
  position: relative;
  padding-top: 16px;
  padding-bottom: 24px;
}

.TransactionsDiagram__main_1Vq2 {
  position: relative;
  display: -webkit-flex;
  display: flex;
}

@media (max-width: 767px) {

.TransactionsDiagram__main_1Vq2 {
    z-index: 2;
    width: 100%;
    padding-top: 40px;
    margin-top: -40px;
    overflow-x: scroll;
    overflow-y: visible;
  }
}

.TransactionsDiagram__month_2xjO {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: visible;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.TransactionsDiagram__month_2xjO.TransactionsDiagram__isDisabled_1tRx {
    pointer-events: none;
}

@media (max-width: 767px) {

.TransactionsDiagram__month_2xjO {
    box-sizing: content-box;
    width: 52px !important;
  }

    .TransactionsDiagram__month_2xjO:first-child {
      padding-left: 16px;
  }
    .TransactionsDiagram__month_2xjO:last-child {
      padding-right: 16px;
  }
      .TransactionsDiagram__month_2xjO.TransactionsDiagram__isActive_3TkK .TransactionsDiagram__inner_2J4H,
      .TransactionsDiagram__month_2xjO.TransactionsDiagram__isActive_3TkK .TransactionsDiagram__bar_2Fmu::before {
        background-color: #f6f8fc;
  }

      .TransactionsDiagram__month_2xjO.TransactionsDiagram__isActive_3TkK .TransactionsDiagram__inner_2J4H {
        z-index: 1;
  }

        .TransactionsDiagram__month_2xjO.TransactionsDiagram__isActive_3TkK .TransactionsDiagram__inner_2J4H .TransactionsDiagram__tooltip_1S1M {
          display: block;
  }
}

@media (min-width: 768px) {
      .TransactionsDiagram__month_2xjO:hover .TransactionsDiagram__inner_2J4H,
      .TransactionsDiagram__month_2xjO:hover .TransactionsDiagram__bar_2Fmu::before {
        background-color: #f6f8fc;
  }

      .TransactionsDiagram__month_2xjO:hover .TransactionsDiagram__inner_2J4H {
        z-index: 1;
  }

      .TransactionsDiagram__month_2xjO:hover .TransactionsDiagram__tooltip_1S1M {
        display: block;
  }
}

.TransactionsDiagram__inner_2J4H {
  display: block;
  height: 100%;
  padding: 8px;
  border-radius: 4px;
}

.TransactionsDiagram__inner_2J4H::after {
    position: absolute;
    right: 0;
    bottom: 32px;
    left: 0;
    content: '';
    border-top: 1px solid #dde3ed;
}

.TransactionsDiagram__bottom_2_KR {
  position: relative;
  width: 100%;
  padding-top: 8px;
  font-size: 10px;
  line-height: 16px;
  color: #95a0b3;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  border-top: 1px solid transparent;
}

.TransactionsDiagram__top_-fMV {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: center;
          justify-content: center;
  height: 119px;
}

.TransactionsDiagram__top_-fMV::before {
    position: absolute;
    top: -8px;
    right: -8px;
    bottom: 0;
    left: -8px;
    z-index: -2;
    content: '';
    background-color: #ffffff;
}

.TransactionsDiagram__bar_2Fmu {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 19px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.TransactionsDiagram__bar_2Fmu::before {
    position: absolute;
    top: -8px;
    right: -8px;
    bottom: 0;
    left: -8px;
    z-index: -1;
    content: '';
    background-color: transparent;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.TransactionsDiagram__bar_2Fmu:not(:last-child) {
    margin-right: 2px;
}

.TransactionsDiagram__legends_2MmH {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 24px;
  margin-bottom: -4px;
}

@media (max-width: 767px) {

.TransactionsDiagram__legends_2MmH {
    padding: 0 16px;
  }
}

.TransactionsDiagram__legend_1ykH {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 16px;
  color: #7a869a;
  letter-spacing: 0.02em;
}

.TransactionsDiagram__legend_1ykH:not(:last-child) {
    margin-right: 16px;
}

.TransactionsDiagram__dot_1ndN {
  display: block;
  width: 8px;
  height: 8px;
  margin-right: 7px;
  border-radius: 50%;
}

.TransactionsDiagram__color_accrual_1SNs {
    background-color: #97ba1e;
}

.TransactionsDiagram__color_redemption_2HcA {
    background-color: #95a0b3;
}

.TransactionsDiagram__color_cr7_3o3e {
    background-color: #c4d600;
}

.TransactionsDiagram__color_otr_3D74,
  .TransactionsDiagram__color_other_34lg {
    background-color: #81C9D5;
}

.TransactionsDiagram__color_crp_3Aeh {
    background-color: #5789D5;
}

.TransactionsDiagram__color_rca_2nfK {
    background-color: #817BB9;
}

.TransactionsDiagram__color_bnk_36ww {
    background-color: #F2A900;
}

.TransactionsDiagram__color_htl_2eCf {
    background-color: #EF7D7E;
}

.TransactionsDiagram__color_rtl_10qO {
    background-color: #C18BBD;
}

.TransactionsDiagram__color_ins_5kh5 {
    background-color: #5BB784;
}

.TransactionsDiagram__part_1bCB {
  width: 100%;
}

.TransactionsDiagram__tooltip_1S1M {
  position: absolute;
  z-index: 20;
  display: none;
  padding: 6px 8px;
  white-space: nowrap;
  pointer-events: none;
  background-color: #222a37;
  border-radius: 4px;
}

.TransactionsDiagram__tooltip_1S1M .TransactionsDiagram__legends_2MmH {
    display: block;
    margin: 0;
}

.TransactionsDiagram__tooltip_1S1M .TransactionsDiagram__legend_1ykH {
    margin: 0;
}

.TransactionsDiagram__tooltip_1S1M .TransactionsDiagram__legend_1ykH span {
      display: inline-block;
      width: 8px;
      margin-right: 4px;
      text-align: center;
}

@media (min-width: 768px) {

.TransactionsDiagram__tooltip_1S1M .TransactionsDiagram__legend_1ykH {
      color: #ffffff;
  }
}

.TransactionsDiagram__tooltip_1S1M::before {
    position: absolute;
    width: 0;
    height: 0;
    color: #222a37;
    content: '';
}

.TransactionsDiagram__tooltip_center_22FL {
    left: 50%;
    margin-bottom: 10px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.TransactionsDiagram__tooltip_center_22FL::before {
      top: 100%;
      left: calc(50% - 5px);
      border-top: 4px solid currentColor;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
}

.TransactionsDiagram__tooltip_right_UiZg {
    right: 100%;
    margin-right: 10px;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
}

.TransactionsDiagram__tooltip_right_UiZg::before {
      top: calc(50% - 5px);
      left: 100%;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 4px solid currentColor;
}

.TransactionsDiagram__tooltip_left_aaro {
    left: 100%;
    margin-left: 10px;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
}

.TransactionsDiagram__tooltip_left_aaro::before {
      top: calc(50% - 5px);
      right: 100%;
      border-top: 5px solid transparent;
      border-right: 4px solid currentColor;
      border-bottom: 5px solid transparent;
}

@media (max-width: 767px) {

.TransactionsDiagram__tooltip_1S1M {
    background-color: #ffffff;
    box-shadow: 0px 4px 10px rgba(34, 42, 55, 0.13);
  }

    .TransactionsDiagram__tooltip_1S1M::before {
      color: #ffffff;
  }
}

.TransactionsDiagram__hidden_1p83 {
  opacity: 0;
}

.TransactionsDiagram__loader_2xzv {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.TransactionsDiagram__loader_2xzv > * {
    margin-bottom: -15px;
}