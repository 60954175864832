.Cards__cards_JgFv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: -24px;
}

  @media (max-width: 1280px) {.Cards__cards_JgFv {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    width: 100%;
    margin-bottom: -16px;
    overflow: auto;
  }
}

.Cards__card_ZEcy {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 24px;
}

.Cards__card_ZEcy:not(:last-child) {
    margin-right: 20px;
}

@media (max-width: 1280px) {

.Cards__card_ZEcy {
    margin-right: 0;
    margin-bottom: 16px;
  }

    .Cards__card_ZEcy:not(:last-child) {
      margin-right: 8px;
  }
}

.Cards__card_edit_3cVX {
    width: 100%;
    margin-right: 0 !important;
    border-color: #dde3ed;
    border-style: solid;
}

.Cards__card_edit_3cVX:first-child {
      border-bottom-width: 1px;
}

.Cards__card_edit_3cVX:not(:first-child) {
      padding-top: 24px;
      border-top-width: 1px;
}

.Cards__card_edit_3cVX + .Cards__card_edit_3cVX {
      padding-top: 0;
      border-top-width: 0;
}

@media (min-width: 1280px) {

.Cards__card_edit_3cVX {
      z-index: 2;
      background-color: #ffffff;
  }
}

