.Feature__root_d-Lx {
  position: relative;
  display: -webkit-flex;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
}

  .Feature__root_d-Lx:not(:last-child) {
    border-bottom: 1px solid #dde3ed;
}

  @media (max-width: 767px) {.Feature__root_d-Lx {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .Feature__info_2EUi {
    max-width: calc(100% - 82px);
  }
}

.Feature__left_jUOh {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

@media (max-width: 767px) {

.Feature__left_jUOh {
    -webkit-order: 3;
            order: 3;
    margin-left: 22px;
  }
}

.Feature__icon_MmI7 {
  display: block;
}

.Feature__round_2NUZ {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.Feature__round_2NUZ.Feature__has_background_YB2X {
    background-color: #eceff5;
}

.Feature__title_2dqT {
  display: block;
  margin-bottom: 4px;
}

.Feature__labels_3ZqB {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 16px;
  margin-left: auto;
}

@media (max-width: 767px) {

.Feature__labels_3ZqB {
    -webkit-order: -1;
            order: -1;
    width: 100%;
    padding-left: 0;
    margin-bottom: 16px;
  }
}