

.Tiles__root_18zM {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  width: 100%;
}

  @media (max-width: 767px) {

.Tiles__root_18zM {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

  .Tiles__root_18zM > :not(:first-child) {
    margin-left: 24px;
}

  @media (max-width: 767px) {

  .Tiles__root_18zM > :not(:first-child) {
      margin-top: 8px;
      margin-left: 0;
  }
}
