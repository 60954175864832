@media (max-width: 767px) {
  .Features__root_2nfN {
    border-radius: 10px;
  }
}

.Features__title_3VRo {
  display: block;
  padding: 16px 24px 12px;
}

@media (max-width: 767px) {

.Features__title_3VRo {
    padding: 18px;
    box-shadow: 0px 4px 10px rgba(34, 42, 55, 0.13);
  }
}

.Features__content_2bvC {
  display: block;
  padding: 0 24px 16px;
}

@media (max-width: 767px) {

.Features__content_2bvC {
    padding: 0 12px;
  }
}