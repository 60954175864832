.Modal__overlay_2tcd {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  overflow-y: auto;
  background-color: rgba(148, 148, 148, .6);

  /* @media (--xs-viewport) */
  /*   align-items: flex-start */
}

.Modal__container_37lk {
  position: relative;
  display: block;
  width: 100%;
  max-width: 320px;
  outline: none;
}

.Modal__container_for-ds_3ffb {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

@media (max-width: 767px);

.Modal__centered_3pnF {
  -webkit-align-items: center;
          align-items: center;
}

.Modal__widthAuto_1U8q {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: auto;
  max-width: 100%;
}

.Modal__title_3N3k {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

.Modal__title-button_216s {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.Modal__title-icon_1PfW {
  display: inline-block;
  margin-right: 8px;
}

.Modal__title-icon_1PfW svg {
    display: block;
}

.Modal__title-left-side_ydih {
  display: -webkit-flex;
  display: flex;
}
