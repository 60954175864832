.TicketDiscounts__root_3nee {
  /* Из за проблем с порядком стилей DS и MP, пришлось использовать important */
  padding: 24px !important;
}

.TicketDiscounts__title_tVE0 {
  margin-bottom: 12px;
}

.TicketDiscounts__content_2fz4 {
  display: -webkit-flex;
  display: flex;
  margin-right: -4px;
  margin-left: -4px;
  overflow: -moz-scrollbars-none;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.TicketDiscounts__content_2fz4::-webkit-scrollbar {
    display: none;
    width: 0px;
}

@media (max-width: 767px) {

.TicketDiscounts__content_2fz4 {
    padding-right: 24px;
    margin-right: -24px;
  }
}

.TicketDiscounts__item_3nQ2 {
  -webkit-flex-basis: 100px;
          flex-basis: 100px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin-right: 4px;
  margin-left: 4px;
}