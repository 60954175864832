.NotificationBus__notifications_lWK- {
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 9999999;
  margin-bottom: 24px;
}

  @media (max-width: 767px) {.NotificationBus__notifications_lWK- {
    top: auto;
    right: 16px;
    bottom: 24px;
    left: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
}

.NotificationBus__notification_3A9y {
  max-width: 440px;
}

.NotificationBus__notification_3A9y:not(:first-child) {
    margin-top: 16px;
}
