.WelcomeMiles__WelcomeMiles_1WkN {
  display: block;
  padding: 17px 0;
}

  @media (max-width: 767px) {.WelcomeMiles__WelcomeMiles_1WkN {
    padding-right: 7px;
    padding-left: 16px;
  }
}

.WelcomeMiles__present_1NZP {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  max-width: 429px;
  padding: 20px;
  padding-bottom: 12px;
  margin-bottom: 25px;
  background-color: #C4D600;
  border-radius: 13px;
}

@media (max-width: 767px) {

.WelcomeMiles__present_1NZP {
    padding: 20px 12px;
    margin-bottom: 20px;
  }
}

.WelcomeMiles__info_3PDC {
  display: block;
  margin-left: 16px;
}

@media (max-width: 767px) {

.WelcomeMiles__info_3PDC {
    margin-left: 10px;
  }
}

.WelcomeMiles__text_2vLJ {
  font-size: 16px;
  color: #fff;
  letter-spacing: -0.4px;
}

@media (max-width: 767px) {

.WelcomeMiles__text_2vLJ {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: normal;
  }
}

.WelcomeMiles__wrap_2IKM {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

@media (max-width: 767px) {

.WelcomeMiles__wrap_2IKM {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
}

.WelcomeMiles__number_H5KN {
  margin-right: 10px;
  font-size: 40px;
  font-weight: 900;
  color: #fff;
}

@media (max-width: 767px) {

.WelcomeMiles__number_H5KN {
    margin-right: 0;
    line-height: 50px;
  }
}

.WelcomeMiles__description_3OJS {
  display: block;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.2px;
}

@media (max-width: 767px) {

.WelcomeMiles__description_3OJS {
    font-size: 14px;
    line-height: 20px;
  }
}

.WelcomeMiles__list_1aq- {
  display: block;
  margin-bottom: 20px;
  list-style: none;
}

@media (max-width: 767px) {

.WelcomeMiles__list_1aq- {
    margin-bottom: 15px;
  }
}

.WelcomeMiles__item_3K6K:not(:last-child) {
  margin-bottom: 8px;
}

@media (max-width: 767px) {

.WelcomeMiles__item_3K6K:not(:last-child) {
    margin-bottom: 5px;
  }
}

.WelcomeMiles__itemLink_2dXc {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  text-decoration: none;
  cursor: pointer;
}

.WelcomeMiles__itemText_2Bsm {
  margin-left: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.54);
  cursor: inherit;
}

@media (max-width: 767px) {

.WelcomeMiles__itemText_2Bsm {
    font-size: 14px;
  }
}

.WelcomeMiles__subDescription_3lR4 {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}
