.Services__service_2HPw {
  display: -webkit-flex;
  display: flex;
}

  .Services__service_2HPw + .Services__service_2HPw {
    padding-top: 21px;
    margin-top: 20px;
    border-top: 1px solid #eceff5;
}

.Services__icon_3VnJ svg {
    display: block;
}

.Services__mobile-wrap_RWeD + .Services__mobile-wrap_RWeD {
  margin-top: 16px;
}

.Services__mobile-inner_2NHQ {
  padding: 16px;
}

.Services__mobile-inner_2NHQ .Services__icon_3VnJ {
    float: right;
}

.Services__content_29D8 {
  margin-left: 20px;
}

@media (max-width: 767px) {

.Services__content_29D8 {
    margin-left: 0;
  }
}

.Services__hasOnlyTitle_10YP {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

@media (max-width: 767px) {

.Services__hasOnlyTitle_10YP {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
}

.Services__stars_2DuS {
  display: inline-block;
  margin-left: 12px;
  color: #ff991f;
}

.Services__stars_2DuS svg + svg {
    margin-left: 1px;
}

@media (max-width: 767px) {

.Services__stars_2DuS {
    display: block;
    margin-bottom: 8px;
    margin-left: 0;
  }
}

.Services__info-blocks_g14z {
  display: -webkit-flex;
  display: flex;
  margin-top: 12px;
}

@media (max-width: 767px) {

.Services__info-blocks_g14z {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.Services__info-block_2ZYi {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  max-width: 286px;
}

.Services__info-block_2ZYi + .Services__info-block_2ZYi {
    margin-left: 20px;
}

@media (max-width: 767px) {
    .Services__info-block_2ZYi + .Services__info-block_2ZYi {
      margin-left: 0;
  }

    .Services__info-block_2ZYi:not(:last-child) .Services__info-second-line_13Io {
      margin-bottom: 16px;
  }
}

.Services__info-first-line_17pO {
  display: -webkit-flex;
  display: flex;
}

.Services__info-label_3GdX {
  position: relative;
  margin-right: 19px;
}

.Services__info-label_3GdX:after {
    position: absolute;
    top: 50%;
    right: -11px;
    display: block;
    width: 3px;
    height: 3px;
    content: '';
    background-color: #222a37;
    border-radius: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.Services__info-second-line_13Io {
  margin-top: 4px;
}

.Services__miles-desktop_3goe {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  margin-left: auto;
}

@media (max-width: 767px) {

.Services__miles-desktop_3goe {
    display: none;
  }
}

.Services__miles-mobile_2zpz {
  margin-top: 8px;
}

@media (min-width: 768px) {

.Services__miles-mobile_2zpz {
    display: none;
  }
}