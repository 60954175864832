.LabelWithText__LabelWithText_3Aq6 {
  display: -webkit-flex;
  display: flex;
}

  @media (max-width: 767px) {
    .LabelWithText__LabelWithText_3Aq6 .LabelWithText__info_Hcux {
      -webkit-flex-grow: 1;
              flex-grow: 1;
      -webkit-justify-content: space-between;
              justify-content: space-between;
  }
}

  .LabelWithText__LabelWithText_3Aq6:not(.LabelWithText__LabelWithText_rows_3RfS) {
    -webkit-align-items: center;
            align-items: center;
}

  .LabelWithText__LabelWithText_rows_3RfS {
    -webkit-flex-direction: column;
            flex-direction: column;
}

  .LabelWithText__LabelWithText_rows_3RfS .LabelWithText__info_Hcux {
      margin-top: 4px;
      margin-left: 0;
}

  @media (max-width: 767px) {

  .LabelWithText__LabelWithText_rows_3RfS .LabelWithText__info_Hcux {
        height: auto;
        overflow: hidden;
  }
}

  .LabelWithText__LabelWithText_rows_3RfS.LabelWithText__LabelWithText_align_right_1RIc {
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

  .LabelWithText__LabelWithText_rows_3RfS.LabelWithText__LabelWithText_align_center_o2Uk {
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

  .LabelWithText__LabelWithText_align_right_1RIc {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

  .LabelWithText__LabelWithText_align_center_o2Uk {
    -webkit-justify-content: center;
            justify-content: center;
}

.LabelWithText__info_Hcux {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 5px;
  overflow: hidden;
  color: #222a37;
}

.LabelWithText__info_Hcux > :not(:first-child) {
    margin-left: 4px;
}

.LabelWithText__info_Hcux > :not(:first-child):last-child {
    margin-right: 4px;
}

@media (max-width: 767px) {

.LabelWithText__info_Hcux {
    min-height: 20px;
    overflow: hidden;
  }
}

.LabelWithText__info_rowsInfo_3u-T {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

@media (max-width: 767px) {

.LabelWithText__info_rowsInfo_3u-T {
      height: auto;
  }
}

.LabelWithText__info_rowsInfo_3u-T :not(:first-child) {
      margin-left: 0;
}

.LabelWithText__label_1TP1 {
  margin-right: 4px;
}
