.Pets__content_26W- {
  margin-bottom: 36px
}

.Pets__load_more_3O9v {
  max-width: 200px;
  padding-top: 12px;
  margin-right: auto;
  margin-left: auto
}
