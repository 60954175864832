.PublicPreferencesForm__caption_2Vfl {
  margin-top: 32px;
  margin-bottom: 16px;
}

  @media (max-width: 767px) {.PublicPreferencesForm__caption_2Vfl {
    margin-bottom: 12px;
  }
}

.PublicPreferencesForm__accesses_2ZDu {
  display: -webkit-flex;
  display: flex;
}

@media (max-width: 767px) {

.PublicPreferencesForm__accesses_2ZDu {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.PublicPreferencesForm__access_1nIo:not(:first-child) {
    margin-left: 32px;
}

@media (max-width: 767px) {

.PublicPreferencesForm__access_1nIo {
    padding: 16px 0;
    border-bottom: 1px solid #dde3ed;
  }

    .PublicPreferencesForm__access_1nIo:not(:first-child) {
      margin-left: 0;
  }
}

.PublicPreferencesForm__add-insta_xE6M {
  margin-top: 16px;
}

.PublicPreferencesForm__add-insta_xE6M button {
    display: -webkit-inline-flex;
    display: inline-flex;
    vertical-align: bottom;
}

.PublicPreferencesForm__actions_3Miw {
  display: -webkit-flex;
  display: flex;
  margin-top: 32px;
}

@media (max-width: 767px) {

.PublicPreferencesForm__actions_3Miw {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    margin-top: 40px;
  }
}

.PublicPreferencesForm__action_3JTq {
  width: 200px;
}

.PublicPreferencesForm__action_3JTq:not(:first-child) {
    margin-left: 8px;
}

@media (max-width: 767px) {

.PublicPreferencesForm__action_3JTq {
    width: auto;
  }

    .PublicPreferencesForm__action_3JTq:not(:first-child) {
      margin-bottom: 12px;
      margin-left: 0;
  }
}

.PublicPreferencesForm__addname_1Z7o {
  display: inline-block;
  margin-top: -2px;
  margin-bottom: -2px;
}

.PublicPreferencesForm__nowrap_KAWQ {
  white-space: nowrap;
}

.PublicPreferencesForm__notificate_3ahT {
  margin-top: 32px;
}