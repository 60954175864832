.AddAppleWallet__AddWalletWrapper_2g3_ {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 48px;
  cursor: pointer;
  background: #000;
  border-radius: 8px;
}

  .AddAppleWallet__AddWalletWrapper_2g3_.AddAppleWallet__small_1Ncz {
    width: 153px;
    height: 46px;
    padding: 0 8px;
}

  .AddAppleWallet__AddWalletWrapper_2g3_.AddAppleWallet__small_1Ncz svg {
      min-width: 32px;
}

  .AddAppleWallet__AddWalletWrapper_2g3_.AddAppleWallet__small_1Ncz a {
      font-size: 14px;
}

.AddAppleWallet__link_2oFj {
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.AddAppleWallet__image_25Za {
  margin-right: 12px;
}
