.ProfileHeader__root_1Icx {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 26px;
  background-color: #fff;
  box-shadow: 0 3px 3px -2px rgba(0,0,0,0.1);
}

  @media (max-width: 767px) {.ProfileHeader__root_1Icx {
    padding-top: 20px;
    padding-right: 16px;
    padding-left: 16px;
  }
}

.ProfileHeader__mediaRoot_2BAE {
  background-color: #A1BFA5;
}

div .ProfileHeader__mediaRoot_2BAE {
    z-index: 1;
}

@media (max-width: 767px) {

div .ProfileHeader__mediaRoot_2BAE {
      padding-top: 0;
  }
}