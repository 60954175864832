.ConfirmContact__root_tx-A ul {
    margin: 10px 0 10px 20px;
    list-style-type: disc
}
  .ConfirmContact__root_tx-A a {
    color: rgba(0, 0, 0, 0.54);
    text-decoration: underline
}

.ConfirmContact__loader_1GE1 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: calc(100vh - 200px);
  padding: 100px
}