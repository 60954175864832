.MilesTransactions__list_2A4_ {
  position: relative;
  display: block;
  min-height: 50px;
}

.MilesTransactions__empty_8L0l {
  padding-top: 24px;
}

.MilesTransactions__date_1yLI {
  padding-top: 32px;
  padding-bottom: 12px;
}

.MilesTransactions__date_1yLI:first-child {
    padding-top: 24px;
}

@media (max-width: 767px) {

.MilesTransactions__date_1yLI {
    padding-top: 20px;
    padding-bottom: 12px;
  }

    .MilesTransactions__date_1yLI:first-child {
      padding-top: 20px;
  }
}

.MilesTransactions__transaction_1goI {
  display: block;
  padding: 20px 0 16px;
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 767px) {

.MilesTransactions__transaction_1goI {
    padding: 12px 0 9px;
  }
}

.MilesTransactions__transaction_1goI:not(:last-child),
  .MilesTransactions__transaction_lastBorder_2xKy:last-child {
    border-bottom: 1px solid #dde3ed;
}

.MilesTransactions__buttonWrap_2bhh {
  display: block;
  width: 100%;
  padding: 20px 0 8px;
}

@media (max-width: 767px) {

.MilesTransactions__buttonWrap_2bhh {
    padding: 16px 0 0;
  }
}

.MilesTransactions__header_3ZXl {
  margin-bottom: 24px;
}

.MilesTransactions__header_container_apva {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.MilesTransactions__welcome-block_340B {
  display: block;
}

.MilesTransactions__welcome-block_withFilters_21tc {
    margin-bottom: 16px;
}

.MilesTransactions__filter_newUser_1P0a {
    margin-top: 16px;
}

.MilesTransactions__filter_icon_1Yau {
  display: none;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 26px;
  height: 26px;
}

@media (max-width: 767px) {

.MilesTransactions__filter_icon_1Yau {
    display: -webkit-flex;
    display: flex;
  }
}