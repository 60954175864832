.SocialLinks__root_1Ebk {
  display: block;

  --color-cancel-background: #eceff5;
}

.SocialLinks__cancel_12pD {
  margin: 0 0 24px;
  color: var(--color-cancel);
}

.SocialLinks__cancel_12pD .SocialLinks__icon_3QJI {
    background-color: var(--color-cancel-background);
}

.SocialLinks__cancel_12pD .SocialLinks__label_Xahg {
    margin-left: 10px;
}

.SocialLinks__icon_3QJI {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.SocialLinks__button_2Czk {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  outline: none;
}

.SocialLinks__button_2Czk:hover {
    opacity: 0.8;
}
