.Label__root_OvoA {
  position: relative;
  padding: 2px 12px;
  background-image: linear-gradient(84deg, #c4d600 0%, #5bb784 100%);
  border-radius: 16px
}

.Label__type_turbo_LudE {
    background-image: linear-gradient(84deg, #81c9d5 0%, #3d72b8 100%)
}

.Label__type_light_vqEI {
    background-image: linear-gradient(84deg, #c4d600 0%, #5bb784 100%)
}
