.ChangePassword__title_3gme {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

  .ChangePassword__title_icon_2LHI {
    margin-right: 12px;
}

  .ChangePassword__title_icon_2LHI > svg {
      display: block;
}

.ChangePassword__description_1oDE {
  position: relative;
  display: block;
  padding-bottom: 24px;
  margin-top: 16px;
}

.ChangePassword__submit_11at {
  display: block;
  margin-top: 16px;
}

.ChangePassword__success-popup_1gSP {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 313;
  display: block;
  min-width: 288px;
  padding: 16px 32px 16px 20px;
  background-color: #757575;
  border-radius: 12px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.ChangePassword__about-new-password_1uZO {
  display: block;
  margin-bottom: 24px;
}

.ChangePassword__error-message_2rdE {
  display: block;
  margin-bottom: 24px;
}

.ChangePassword__fields_2gjw {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.ChangePassword__fields_2gjw .ChangePassword__field_3m7w {
    margin-bottom: 24px;
}

@media (min-width: 768px) {
  .ChangePassword__fields_2gjw .ChangePassword__field_3m7w {
    width: 299px;
  }

    .ChangePassword__fields_2gjw .ChangePassword__field_3m7w:not(:last-child) {
      margin-right: 23px;
  }

  .ChangePassword__submit_11at {
    width: 160px;
  }
}

@media (max-width: 767px) {
  .ChangePassword__fields_2gjw {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.ChangePassword__hiddenLogin_3706 {
  position: fixed;
  top: -1000vh;
  left: -1000vw;
  opacity: 0;
}
