.Hidden__on_desktop_3jkA {
  display: none !important;
}

  @media (max-width: 767px) {.Hidden__on_desktop_3jkA {
    display: block !important;
  }
}

.Hidden__on_tablet_3iiS {
  display: block;
}

@media (max-width: 1280px) {

.Hidden__on_tablet_3iiS {
    display: none !important;
  }
}

.Hidden__on_mobile_1wiv {
  display: block;
}

@media (max-width: 767px) {

.Hidden__on_mobile_1wiv {
    display: none !important;
  }
}
