

.SkeletonLoaderBreadcrumbs__SkeletonLoaderBreadcrumbs_2s7I {
  position: relative;
  display: block;
  width: 100%;
  height: 32px;
  overflow: hidden
}

.SkeletonLoaderBreadcrumbs__main_2hmP {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%
}
