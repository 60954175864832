.Details__Details_3895 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

  .Details__Details_opened_2kCW .Details__icon_Ljta {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
}
