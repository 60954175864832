
.MilesTransfer__root_2vLq {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0 0 25px;
  background-color: #fff;
}

  @media (max-width: 767px) {
.MilesTransfer__root_2vLq {
    position: fixed;
    top: 56px;
    left: 0;
    z-index: 310;
    width: 100%;
    height: 100%;
    padding: 16px 16px 106px;
    margin: 0;
    overflow: auto;
  }
}

.MilesTransfer__title_37vQ {
  display: none;
}

@media (max-width: 767px) {

.MilesTransfer__title_37vQ {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 311;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    min-height: 56px;
    padding: 0 16px;
    overflow-y: hidden;
    background-color: #fff;
    border-bottom: solid 1px #e8e8e8;
  }
}

.MilesTransfer__title-icon_RnEL {
    margin-left: auto;
    cursor: pointer;
}

.MilesTransfer__notification_276q {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin: 7px 0 25px;
}

@media (max-width: 767px) {

.MilesTransfer__notification_276q {
    -webkit-order: 2;
            order: 2;
    margin: 0 0 20px;
  }
}

.MilesTransfer__condition_1DZc {
  display: block;
  margin: 0 0 35px;
}

@media (max-width: 767px) {

.MilesTransfer__condition_1DZc {
    -webkit-order: 1;
            order: 1;
    margin: 4px 0 37px;
  }
}

@media (max-width: 767px) {

.MilesTransfer__card-content_2yYT {
    -webkit-order: 3;
            order: 3;
  }
}

.MilesTransfer__content_3aVV {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  width: 100%;
  margin: -11px 0 40px;
}

@media (max-width: 767px) {

.MilesTransfer__content_3aVV {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 3px;
  }
}

.MilesTransfer__fields_1Cpv {
  display: block;
  min-width: 416px;
}

@media (max-width: 767px) {

.MilesTransfer__fields_1Cpv {
    min-width: 100%;
    margin-top: 20px;
  }
}

.MilesTransfer__field_1__d {
  display: block;
  margin-bottom: 24px;
}

.MilesTransfer__card-field_1k12 {
  display: block;
}

@media (max-width: 767px) {

.MilesTransfer__card-field_1k12 {
    width: auto;
  }
}

.MilesTransfer__card_2ugE {
  position: relative;
  display: block;
  min-width: 264px;
  height: 168px;
  margin-left: 39px;
  background-size: cover;
  border-radius: 12px;
  box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 8px 16px 0 rgba(63,63,68,0.15);
}

@media (max-width: 767px) {

.MilesTransfer__card_2ugE {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    width: 100%;
    height: auto;
    padding: 8px;
    margin: 0;
    background-color: #E3EECD;
    border-radius: 8px;
  }
}

@media (max-width: 767px) {

.MilesTransfer__card-icon_2vuC {
      display: block;
      width: 111px;
      height: 36px;
      margin: 8px 0 12px;
  }
}

.MilesTransfer__card-number_3nFM {
    position: absolute;
    top: 15px;
    right: 17px;
}

.MilesTransfer__card-text_3oBL {
    position: absolute;
    bottom: 24px;
    left: 17px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

@media (max-width: 767px) {

.MilesTransfer__card-text_3oBL {
      position: static;
      margin: 13px 0 7px 16px;
  }
}

.MilesTransfer__card-payment_2XXd {
  display: block;
  margin: 0 0 22px;
}

@media (max-width: 767px) {

.MilesTransfer__card-payment_2XXd {
    margin-top: 3px;
  }
}

.MilesTransfer__incorrect-code-notification_2eB1 {
  display: block;
  margin: 11px 0 22px;
}

@media (max-width: 767px) {

.MilesTransfer__incorrect-code-notification_2eB1 {
    margin: -8px 0 41px;
  }
}

.MilesTransfer__confirmation-dialog_1iO8 {
  display: block;
  margin: 11px 0 35px;
}

@media (max-width: 767px) {

.MilesTransfer__confirmation-dialog_1iO8 {
    margin: -8px 0 40px;
  }
}

.MilesTransfer__footer-info_15NY {
  display: block;
  margin: 4px 0 14px;
}

.MilesTransfer__footer-info_15NY p:not(:last-child) {
    margin: 0 0 12px;
}

@media (max-width: 767px) {

.MilesTransfer__footer-info_15NY {
    -webkit-order: 4;
            order: 4;
    /* Fix render iOS 14 Safari */
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
}