.Wrapper__root_14jb {
  display: block;
  max-width: 720px;
  padding: 42px 8px 120px;
  margin-right: auto;
  margin-left: auto;
}

  @media (max-width: 767px) {.Wrapper__root_14jb {
    padding-top: 24px;
    padding-bottom: 55px;
  }
}

.Wrapper__back_1I87 {
  display: block;
  margin-bottom: 24px;
}