.ProfileView__profileView_11S2 {
  display: block;
  min-height: calc(100vh - 638px);
  padding-bottom: 120px;
  background-color: #f1f3f8;
}

  @media (max-width: 767px) {.ProfileView__profileView_11S2 {
    padding-right: 8px;
    padding-bottom: 55px;
    padding-left: 8px;
  }
}

.ProfileView__group_3bEr {
  display: block;
  padding-top: 32px;
}

@media (max-width: 767px) {

.ProfileView__group_3bEr {
    padding-top: 16px;
  }
}

.ProfileView__group_more_1mSP {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

