.Edit__root_33kE {
  position: relative;
  padding-right: 128px;
  padding-bottom: 40px;
  padding-left: 112px;
}

.Edit__section_3lT9:not(:first-child),
.Edit__actions_14Iy {
  margin-top: 60px;
}

.Edit__description_3vQr {
  margin-top: 8px;
}

.Edit__item_2yk6 {
  position: relative;
  margin-top: 24px;
}

.Edit__description_3vQr,
.Edit__item_2yk6 {
  max-width: 720px;
}

.Edit__actions_14Iy {
  display: -webkit-flex;
  display: flex;
}

.Edit__action_htIr {
  min-width: 158px;
  margin-right: 8px;
}

.Edit__document_1Mrz {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Edit__document_1Mrz:not(:last-child) {
    margin-bottom: 60px;
}

.Edit__remove_3jFn {
  margin-top: 36px;
}

.Edit__remove_3jFn.Edit__hasDocScan_Va_- {
    margin-top: 76px;
}

.Edit__notification_3NVG {
  margin-bottom: 24px;
}