.EditableRow__root_3OGl {
  display: -webkit-flex;
  display: flex;
}

.EditableRow__edit_3XGC {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

@media (min-width: 768px) {

.EditableRow__edit_3XGC {
    max-width: 312px;
  }
}

.EditableRow__edit-button_1YLc {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-left: 6px;
  color: #97ba1e;
}

.EditableRow__actions_2Dq2 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 20px;
}

.EditableRow__action_e_-5 {
  width: calc(50% - 4px);
}

.EditableRow__action_e_-5:not(:first-child) {
    margin-left: 8px;
}