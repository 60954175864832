.MilesCertificate__milesCertificate_1OZi {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 24px 0 27px;
  background-color: #fff;
}

  @media (max-width: 767px) {.MilesCertificate__milesCertificate_1OZi {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 310;
    width: 100%;
    height: 100%;
    padding: 76px 16px 144px;
    margin: 0;
    overflow: auto;
  }
}


.MilesCertificate__head_5zfx,
.MilesCertificate__notification_2a4N {
  display: block;
  margin-bottom: 32px;
}


.MilesCertificate__title_3xb1 {
  display: block;
  margin-bottom: 8px;
}


.MilesCertificate__description_Qu1e {
  display: block;
  margin-bottom: 12px;
}


/* ------ mobile ------ */


.MilesCertificate__mobile_2bQS {
  display: none;
}


@media (max-width: 767px) {


.MilesCertificate__mobile_2bQS {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 311;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    min-height: 56px;
    padding: 0 16px;
    overflow-y: hidden;
    background-color: #fff;
    border-bottom: solid 1px #e8e8e8;
  }
}


.MilesCertificate__mobile-icon_P6Va {
    margin-left: auto;
    cursor: pointer;
}


.MilesCertificate__mobile-back_Am5b {
    margin-right: 10px;
}


.MilesCertificate__overlay_2e-A {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 300;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  overflow-y: auto;
  background-color: rgba(148, 148, 148, .6);
}


@media (max-width: 767px) {


.MilesCertificate__overlay_2e-A {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
}


.MilesCertificate__container_2h3I {
  position: relative;
  display: block;
  width: 510px;
  max-width: 510px !important;
  outline: none;
}


.MilesCertificate__modal_Jvwc {
  z-index: 312;
}


.MilesCertificate__modal-close_3zMX {
    position: absolute;
    top: 14px;
    right: 10px;
    z-index: 999;
}