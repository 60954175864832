.Subscription__root_1Ffl {
  display: block
}

.Subscription__block_THPv {
  margin-bottom: 24px
}

.Subscription__modal_2mav .Subscription__buttons_3RJD {
  display: block
}