
.Transaction__transaction_3Mvz {
  display: table;
  width: 100%;
}

.Transaction__disable_2KB- {
  opacity: 0.5;
}

.Transaction__cell_3U5j {
  display: table-cell;
  min-height: 56px;
  padding-right: 8px;
  padding-left: 8px;
  vertical-align: middle;
}

@media (max-width: 767px) {

.Transaction__cell_3U5j {
    vertical-align: top;
  }
}

.Transaction__cell_name_30Yw {
    width: 100%;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
}

.Transaction__cell_name_bottom_CyTz {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      padding-top: 14px;
}

.Transaction__cell_name_bottom_item_11Rp {
        margin-right: 14px;
}

.Transaction__cell_miles_3n4i {
    width: 100%;
    padding-right: 0;
    white-space: nowrap;
    vertical-align: top;
}

@media (min-width: 768px) {
      .Transaction__cell_miles_3n4i * {
        white-space: nowrap;
  }
}

@media (max-width: 767px) {

.Transaction__cell_miles_3n4i {
      width: auto;
      text-align: right;
  }

      .Transaction__cell_miles_3n4i > p {
        text-align: right;
  }
}

.Transaction__name-text_zHwd {
  display: none;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;
}

@media (max-width: 767px) {

.Transaction__name-text_zHwd {
    display: block;
    margin-top: 4px;
    white-space: normal;
  }
}

.Transaction__description-text_3jwN {
  display: inline-block;
  margin-top: 4px;
}

@media (max-width: 767px) {
    .Transaction__description-text_3jwN * {
      word-break: break-word;
  }
}

.Transaction__text_3ffs {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  text-align: right;
}

.Transaction__subname_TM5_ {
  white-space: nowrap;
}

@media (max-width: 767px) {

.Transaction__subname_TM5_ {
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
    color: #7a869a;
  }
}

.Transaction__button_3uL0 {
  display: inline-block;
  padding: 16px 24px;
}

@media (max-width: 767px) {

.Transaction__button_3uL0 {
    padding: 12px;
  }
}

.Transaction__miles_5cB3 {
  font-size: inherit;
  color: inherit;
  white-space: nowrap;
}

@media (max-width: 767px) {

.Transaction__miles_5cB3 {
    display: block;
  }
}

.Transaction__button-text_DIqp,
.Transaction__link-text_3V3C {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}

.Transaction__button-text_DIqp {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.38);
  transition: border-bottom 0.3s;
}

.Transaction__button-text_DIqp:hover {
    border-bottom: 1px solid transparent;
}

.Transaction__button-text_DIqp:focus {
    outline: 5px auto rgb(77, 144, 254);
}

.Transaction__link_3lZA {
  display: inline-block;
  text-decoration: none;
}

@media (max-width: 767px) {

.Transaction__link_3lZA {
    margin-left: 12px;
  }
}

.Transaction__link_3lZA:hover .Transaction__link-text_3V3C {
    border-bottom: 1px solid transparent;
}

.Transaction__link_3lZA:focus .Transaction__link-text_3V3C {
    outline: 5px auto rgb(77, 144, 254);
}

.Transaction__link-text_3V3C {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.38);
  transition: border-bottom 0.3s;
}

.Transaction__color_cr7_3j-E {
    background-color: rgba(196, 214, 0, 0.2);
}

.Transaction__color_otr_2kb8,
  .Transaction__color_other_lYjz {
    background-color: rgba(129, 201, 213, 0.2);
}

.Transaction__color_crp_2r1h {
    background-color: rgba(87, 137, 213, 0.2);
}

.Transaction__color_rca_2gY0 {
    background-color: rgba(129, 123, 185, 0.2);
}

.Transaction__color_bnk_2l6A {
    background-color: rgba(242, 169, 0, 0.2);
}

.Transaction__color_htl_3SsX {
    background-color: rgba(239, 125, 126, 0.2);
}

.Transaction__color_rtl_1li_ {
    background-color: rgba(193, 139, 189, 0.2);
}

.Transaction__color_ins_lDlr {
    background-color: rgba(91, 183, 132, 0.2);
}
