.StatusS7Priority__button_1W8L {
  position: relative;
  display: block;
  margin-left: 4px;
  cursor: pointer;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

  .StatusS7Priority__button_rotate_16gO {
    -webkit-animation: StatusS7Priority__rotate_1RV0 0.75s linear infinite;
            animation: StatusS7Priority__rotate_1RV0 0.75s linear infinite;
}

@-webkit-keyframes StatusS7Priority__rotate_1RV0 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes StatusS7Priority__rotate_1RV0 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.StatusS7Priority__columns_2r60 {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.StatusS7Priority__column_2_43 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

@media (max-width: 767px) {
  .StatusS7Priority__columns_2r60 {
    display: block;
  }

  .StatusS7Priority__column_2_43:not(:first-child) {
    margin-top: 8px;
  }
}

.StatusS7Priority__tooltip_PBgY {
  display: block;
}

.StatusS7Priority__title_3eTP a {
  color: #222a37;
  text-decoration: underline;
}

.StatusS7Priority__title_3eTP a:hover {
    text-decoration: none;
}

.StatusS7Priority__content_QNih {
  padding-bottom: 0;
}

.StatusS7Priority__item_6rL3 {
  display: block;
  padding-top: 24px;
  padding-bottom: 24px;
  color: rgba(0, 0, 0, 0.87);
}

.StatusS7Priority__item_6rL3:not(:last-child) {
    border-bottom: 1px solid #dde3ed;
}