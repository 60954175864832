.StatusPriority__StatusPriority_3Gag {
  display: block;
  width: 100%;
  padding-bottom: 5px;
}

  @media (max-width: 767px) {.StatusPriority__StatusPriority_3Gag {
    padding-bottom: 10px;
  }
}

.StatusPriority__tooltip_1QIQ {
  display: block;
  margin: 0;
}

.StatusPriority__status_3jHX {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 24px;
}

.StatusPriority__bar_wuc- {
  display: block;
  width: 100%;
  padding-right: 8px;
  margin-top: 15px;
}

@media (max-width: 767px) {

.StatusPriority__bar_wuc- {
    padding-right: 4px;
    margin-top: 21px;
  }
}

.StatusPriority__info_6hRV {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  margin-top: 18px;
}

@media (max-width: 767px) {

.StatusPriority__info_6hRV {
    display: block;
    margin-top: 23px;
  }
}

.StatusPriority__infoRight_Khyw {
  position: relative;
}

@media (max-width: 767px) {

.StatusPriority__infoRight_Khyw {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }
}

.StatusPriority__infoItem_dDKM {
  display: block;
}

.StatusPriority__infoItem_dDKM:not(:first-child) {
    margin-top: 9px;
}

@media (max-width: 767px) {

.StatusPriority__infoItem_dDKM:not(:first-child) {
      margin-top: 8px;
  }
}

.StatusPriority__infoPrivilege_bE0s {
  display: block;
  margin-top: 24px;
}

.StatusPriority__infoList_24E1 {
  display: block;
  margin-top: 12px;
}

@media (max-width: 767px) {

.StatusPriority__infoList_24E1 {
    margin-top: 8px;
    margin-right: -2px;
  }
}

.StatusPriority__expiration_1lP- {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 8px;
}

@media (max-width: 767px) {

.StatusPriority__expiration_1lP- {
    width: 100%;
    margin-top: 8px;
    margin-right: auto;
  }
}

.StatusPriority__date_3Rak {
  margin-right: 10px;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.54);
}

@media (max-width: 767px) {

.StatusPriority__date_3Rak {
    font-size: 12px;
    line-height: 16px;
    text-align: right;
  }
}

.StatusPriority__date_3Rak br {
    display: none;
}

@media (max-width: 767px) {

.StatusPriority__date_3Rak br {
      display: block;
  }
}

.StatusPriority__bold_1LhG {
  font-size: inherit;
  font-weight: 500;
  color: inherit;
}

a.StatusPriority__bold_1LhG {
  font-size: inherit;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: underline;
  cursor: pointer;
}

a.StatusPriority__bold_1LhG:hover {
    text-decoration: none;
}

.StatusPriority__more_2rS2 {
  margin-top: 20px;
}