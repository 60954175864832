.SkeletonConfirmRecovery__root_SleN {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: calc(100vh - 84px);
  background-color: #e1f0e5;
}

  @media (max-width: 767px) {.SkeletonConfirmRecovery__root_SleN {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
    height: 590px;
  }
}

.SkeletonConfirmRecovery__desktop_2Djr {
  display: block;
  width: 360px;
  margin-left: 215px;
  background-color: #fff;
  border: solid 1px #e4e4e4;
  border-radius: 16px;
}

@media (max-width: 767px) {

.SkeletonConfirmRecovery__desktop_2Djr {
    display: none;
  }
}

.SkeletonConfirmRecovery__mobile_2Elh {
  display: block;
  width: 360px;
  height: 100%;
  background-color: #fff;
}

@media (min-width: 768px) {

.SkeletonConfirmRecovery__mobile_2Elh {
    display: none;
  }
}

.SkeletonConfirmRecovery__center_2OnJ {
  -webkit-justify-content: center;
          justify-content: center;
}
