.StatusBar__root_KLdY {
  overflow: hidden;
}

  @media (min-width: 768px) {.StatusBar__root_KLdY {
    padding-bottom: 28px;
  }
}

.StatusBar__wrapper_3hxu

.StatusBar__steps_S53j {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

.StatusBar__step_3u3n {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 20px;
}

.StatusBar__marker_36XR {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.StatusBar__step_3u3n:not(:first-child) .StatusBar__marker_36XR::before {
      position: absolute;
      top: 50%;
      right: 0;
      width: 100vw;
      height: 2px;
      content: '';
      background-color: inherit;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
}

.StatusBar__marker_success_4zIG {
    background-color: #97ba1e;
}

.StatusBar__marker_active_2zgd {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 20px;
    height: 20px;
    color: #ffffff;
    background-color: #97ba1e;
}

.StatusBar__marker_suspend_1pgo {
    background-color: #d0d6e1;
}

.StatusBar__marker_36XR svg {
    z-index: 1;
}

.StatusBar__text-wrapper_2ud0 {
  position: absolute;
  top: 26px;
  display: none;
}

@media (min-width: 768px) {

.StatusBar__text-wrapper_2ud0 {
    display: block;
  }
}

.StatusBar__text-wrapper_2ud0:not(.StatusBar__text-wrapper_last_1YJR):not(.StatusBar__text-wrapper_first_3R2I) {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.StatusBar__text-wrapper_first_3R2I {
    left: 0;
}

.StatusBar__text-wrapper_last_1YJR {
    right: 0;
}

.StatusBar__text_zFWZ {
  white-space: nowrap;
}


