
.Breadcrumbs__root_2JhN {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  width: 100%;
  max-width: 1440px;
  padding: 24px 32px;
  margin: 0 auto;
  overflow: hidden;
  color: #333;
  list-style-type: none;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

  .Breadcrumbs__root_green_3ekQ {
    background-color: #e1f0e5;
}

  .Breadcrumbs__root_G50_ppi_ {
    background-color: #f4f9e6;
}

  .Breadcrumbs__root_N30_3uei {
    background-color: #f1f3f8;
}

  @media (min-width: 1440px) {
.Breadcrumbs__root_2JhN {
    padding: 24px 32px;
  }
}

.Breadcrumbs__item_3SYG {
  height: 16px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 16px;
  color: #7a869a;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Breadcrumbs__item_3SYG:not(:last-child) {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    text-decoration: underline;
}

.Breadcrumbs__item_3SYG:not(:last-child)::after {
      display: inline-block;
      padding: 0 2px;
      content: '>';
}

.Breadcrumbs__item_3SYG:last-child {
    pointer-events: none;
}

.Breadcrumbs__item_3SYG .Breadcrumbs__link_JHAp {
    color: inherit;
    text-decoration: inherit;
    cursor: pointer;
}

.Breadcrumbs__item_3SYG .Breadcrumbs__link_JHAp:hover {
      text-decoration: none;
}