.Item__root_2QN5 {
  width: 120px;
}

  @media (max-width: 767px) {
    .Item__root_2QN5.Item__aligment-right_1-Mp {
      height: 48px;
      margin: 20px 0;
  }
}

  @media (max-width: 1279px) {.Item__root_2QN5 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 0;
            flex: 1 0;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: auto;
    height: 100px;
  }

    .Item__root_2QN5.Item__aligment-left_20gl {
      position: relative;
      background-color: #ffffff;
      border-radius: 12px;
      box-shadow: 0px 0px 4px rgba(29, 33, 41, 0.04), 0px 4px 12px rgba(29, 33, 41, 0.04);
  }

      .Item__root_2QN5.Item__aligment-left_20gl:not(:last-child) {
        margin-right: 8px;
  }
}

.Item__value_smAP,
.Item__label_3krT {
  text-align: center;
  white-space: nowrap;
}

.Item__value_smAP {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
}

.Item__label_3krT {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
}
