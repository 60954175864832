.NearestFlight__root_1dmw {
  position: relative;
}

.NearestFlight__isModal_11RF {
  position: absolute;
  bottom: 52px;
  left: 12px;
  min-width: 316px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media (max-width: 767px) {

.NearestFlight__isModal_11RF {
    min-width: 304px;
  }
}

.NearestFlight__isModal_11RF .NearestFlight__inner_3I6b::before {
    position: absolute;
    bottom: -12px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -12px;
    content: '';
    border-top: 12px solid #ffffff;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
}

.NearestFlight__isModal_11RF .NearestFlight__title_12gj {
    padding-right: 20px;
}

.NearestFlight__inner_3I6b {
  padding: 24px;
}

@media (max-width: 767px) {

.NearestFlight__inner_3I6b {
    padding: 24px 16px 16px;
  }
}

.NearestFlight__close_NxKF {
  position: absolute;
  top: 16px;
  right: 16px;
  color: #7a869a;
  background: none;
  outline: none;
}

@media (min-width: 768px) {
  .NearestFlight__title_12gj * {
    white-space: nowrap;
  }
}

.NearestFlight__date_1qnD,
.NearestFlight__flight_CPEK {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.NearestFlight__date_1qnD {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 4px;
}

.NearestFlight__dot_2jrz {
  display: block;
  width: 3px;
  height: 3px;
  margin: 0 8px;
  background-color: #222a37;
  border-radius: 50%;
}

.NearestFlight__flight_CPEK {
  margin-top: 16px;
}

.NearestFlight__icon_3HHv {
  height: 20px;
  margin-right: 8px;
}

.NearestFlight__avatar_2bCD {
  position: absolute;
  right: 24px;
  bottom: 24px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 40px;
  height: 40px;
  color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.NearestFlight__empty_3XxS {
  background: linear-gradient(86.42deg, #C4D600 0%, #5BB784 100%);
  opacity: 0.5;
}