.View__root_21hT {
  position: relative;
}

  .View__root_21hT:not(:first-child):after {
    position: absolute;
    top: 0;
    right: 32px;
    left: 32px;
    height: 1px;
    content: '';
    background-color: #eceff5;
}

  .View__root_21hT.View__allow_hover_30K9:hover:after,
  .View__root_21hT.View__allow_hover_30K9:hover + .View__root_21hT:after {
    display: none;
}

  .View__root_21hT.View__allow_hover_30K9:hover .View__heading_2LNR {
      background-color: #f6f8fc;
}

  .View__root_21hT.View__allow_hover_30K9:hover .View__avatar_1pSH {
      box-shadow: inset 0 0 0 1px #eceff5;
}

  .View__root_21hT.View__allow_hover_30K9:hover .View__avatar_1pSH:before {
      opacity: 0;
}

  .View__root_21hT.View__allow_hover_30K9:hover .View__actions_1jQj {
      opacity: 1;
}

.View__heading_2LNR {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding: 24px 32px;
  background-color: #ffffff;
  transition: background-color .2s ease;
}

.View__column_2R7J {
  margin-right: 20px;
}

.View__initials_1rq8 {
  position: relative;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 60px;
  height: 60px;
  overflow: hidden;
  background: #ffffff;
  border-radius: 50%;
}

.View__initials_1rq8:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: '';
    background: linear-gradient(84deg, #c4d600 0%, #5bb784 100%);
    opacity: 0.2;
}

.View__person_2NDO {
  width: 200px;
}

.View__name_4Ru8 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 4px;
  overflow: hidden;
}

.View__part_2Exg {
  display: inline-block;
  max-width: 100%;
}

.View__part_2Exg:first-child {
    margin-right: 4px;
}

.View__documents_bb9u {
  width: 176px;
}

.View__loyalty_3T2I {
  width: 144px;
}

.View__contacts_1iz6 {
  width: 160px;
}

.View__actions_1jQj {
  display: -webkit-flex;
  display: flex;
  min-width: 72px;
  margin-right: 0;
  margin-left: auto;
  opacity: 0;
  transition: opacity .2s ease;
}

.View__actions_1jQj.View__visible_2FIy,
  .View__actions_1jQj.View__wide_2b4I {
    opacity: 1;
}

.View__action_3Cqv:not(:last-child) {
  margin-right: 8px;
}

.View__wide_2b4I .View__action_3Cqv:not(:last-child) {
    margin-right: 40px;
}

.View__open_area_A71X {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.View__info_30Hz {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.View__icon_2ugc {
  margin-right: 6px;
  color: #d0d6e1;
}

.View__count_2Osb {
  margin-left: 8px;
  color: #95a0b3;
}

@media (max-width: 1279px) {
  .View__root_21hT:not(:first-child):after {
    right: 16px;
    left: 16px;
  }

  .View__heading_2LNR {
    padding: 16px;
  }


  .View__initials_1rq8 {
    width: 40px;
    height: 40px;
  }

  .View__person_2NDO {
    width: 100%;
  }

  .View__open_area_A71X {
    display: block;
  }
}