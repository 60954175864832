.LinkDotted__LinkDotted_qEDz {
  display: inline-block;
  padding-top: 1px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.38);
  transition: border-color 0.3s ease-out;
}

  .LinkDotted__LinkDotted_theme_solid_2ECU {
    text-decoration: underline;
    border-bottom: none;
}

  .LinkDotted__LinkDotted_size_demi_3F68 {
    font-size: 12px;
    line-height: 16px;
}

  @media (max-width: 767px) {
    .LinkDotted__LinkDotted_inline_kmV8 {
      display: inline;
  }
}

.LinkDotted__LinkDotted_qEDz:hover {
  border-color: transparent;
}

.LinkDotted__LinkDotted_qEDz:focus {
  outline: 5px auto rgb(77, 144, 254);
}
