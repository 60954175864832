

.Notifications__notifications_2eb4 {
  position: relative;
}

.Notifications__notification_2xGn {
  display: block;
  padding-top: 32px;
}

@media (max-width: 767px) {

.Notifications__notification_2xGn {
    padding-top: 16px;
  }
}
