.MilesRecoveryFlightSkeleton__root_1rYs {
  box-sizing: border-box;
  width: 621px;
  height: 212px;
  margin-top: 24px
}

.MilesRecoveryFlightSkeleton__main_1Cy7 {
  width: 100%;
  height: 100%;
  background: #f6f8fc;
  border-radius: 8px
}

@media (max-width: 1280px) {
  .MilesRecoveryFlightSkeleton__root_1rYs {
    width: 100%
  }
}

@media (max-width: 767px) {
  .MilesRecoveryFlightSkeleton__root_1rYs {
    width: 100%;
    height: 195px;
    margin-top: 40px
  }

  .MilesRecoveryFlightSkeleton__main_1Cy7 {
    border-radius: 0
  }
}