.Social__root_z7Lp {
  display: block;
}

  .Social__root_z7Lp.Social__removable_251G .Social__remove_1Y2b {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    margin-left: auto;
    opacity: 1;
}

  @media (min-width: 768px) and (max-width: 1279px) {

  .Social__root_z7Lp.Social__removable_251G .Social__remove_1Y2b {
      margin-left: 16px;
  }
}

  .Social__root_z7Lp.Social__removable_251G.Social__mobile_3mKU .Social__item_3_kM {
        cursor: pointer;
}

  .Social__root_z7Lp.Social__removable_251G.Social__mobile_3mKU .Social__remove_1Y2b {
        display: none;
}

  .Social__root_z7Lp.Social__removable_251G.Social__mobile_3mKU .Social__remove_mobile_3U_L {
        display: block;
}

  .Social__root_z7Lp:not(.Social__removable_251G) .Social__item_3_kM, .Social__root_z7Lp:not(.Social__mobile_3mKU) .Social__item_3_kM {
      outline: none;
}

  .Social__root_z7Lp.Social__hoverable_3WFb .Social__remove_1Y2b {
      opacity: 0;
}

  .Social__root_z7Lp.Social__hoverable_3WFb .Social__item_3_kM:hover .Social__remove_1Y2b {
      opacity: 1;
}

@media (max-width: 767px) {

.Social__name_jnaG {
    max-width: calc(100% - 24px);
  }
}

.Social__item_3_kM {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  cursor: default;
}

.Social__remove_1Y2b {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #f14d56;
  outline: none;
  transition: opacity 0.2s ease-out;
}

.Social__remove_1Y2b .Social__icon_VqjD {
    background-color: #feedee;
}

.Social__remove_1Y2b .Social__label_3hq9 {
    margin-right: 9px;
}

.Social__remove_mobile_3U_L {
  display: none;
}

.Social__icon_VqjD {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.Social__modal_ceUP {
  max-width: 600px;
}

.Social__modal-content_3CcF {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Social__modal-content_3CcF .Social__option_1ea- {
    height: auto;
    margin-top: 10px;
    overflow: hidden;
}

@media (max-width: 1279px) {
  .Social__root_z7Lp {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 80px;
  }
}
