.Discount__root_WaB_ {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  height: 148px;
  padding: 24px;
  background: #f6f8fc;
  border-radius: 16px;
}

  @media (max-width: 767px) {.Discount__root_WaB_ {
    min-width: 122px;
    height: auto;
    min-height: 98px;
    padding: 16px;
  }
}

  .Discount__root_WaB_.Discount__type_basic_2vL- {
    background-color: #f6f8fc;
}

  .Discount__root_WaB_.Discount__type_standart_3WT9 {
    background-color: #f6f9ea;
}

  .Discount__root_WaB_.Discount__type_plus__VcX {
    background-color: #efe9fb;
}

.Discount__name_LfyE {
  text-transform: capitalize;
}

.Discount__badge_ilEr {
  position: absolute;
  top: 24px;
  right: 24px;
  padding: 2px 16px;
  border-radius: 12px;
}

@media (max-width: 767px) {

.Discount__badge_ilEr {
    top: 8px;
    right: 8px;
  }
}

.Discount__type_basic_2vL- .Discount__badge_ilEr {
    background-color: #95a0b3;
}

.Discount__type_standart_3WT9 .Discount__badge_ilEr {
    background-color: #97ba1e;
}

.Discount__type_plus__VcX .Discount__badge_ilEr {
    background-color: #7e57c2;
}
