.ActionMiles__info-wrap_3adV {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-height: 144px;
  background-color: #fff;
  border-radius: 16px 16px 0 0;
}

  @media (max-width: 767px) {.ActionMiles__info-wrap_3adV {
    min-height: 100px;
    border-radius: 0;
  }
}

.ActionMiles__info-icon_1hQ7 {
  display: block;
  min-width: 120px;
  height: 120px;
  margin: 12px 0 12px 12px;
  background-size: cover;
}

@media (max-width: 767px) {

.ActionMiles__info-icon_1hQ7 {
    display: none;
  }
}

.ActionMiles__info_24BP {
  margin: 28px 24px 30px;
}

@media (max-width: 767px) {

.ActionMiles__info_24BP {
    margin: 18px 16px 15px;
  }
}

.ActionMiles__info-text_W-WT {
  display: block;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 767px) {

.ActionMiles__info-text_W-WT {
    font-size: 14px;
  }
}

.ActionMiles__list-item-new_2K70 {
  margin-right: 20px;
}

.ActionMiles__list-item-actions_33W9 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-left: auto;
}

.ActionMiles__info-button_7jQa {
  display: block;
  width: 134px;
  height: 30px;
  margin-top: 18px;
  font-size: 14px;
  line-height: 30px;
  color: inherit;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: #fff;
  border: solid 1px #c4d600;
  border-radius: 8px;
}

.ActionMiles__info-button_7jQa:hover {
    background-color: #f6f6f7;
    border-color: #e4ec8b;
}

.ActionMiles__info-button_7jQa:active {
    background-color: #edeeee;
    border-color: #a1b000;
}

@media (max-width: 767px) {

.ActionMiles__info-button_7jQa {
    margin-top: 14px;
  }
}

.ActionMiles__list-item_2Mv0 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  list-style-type: none;
  cursor: pointer;
  background-color: #fff;
}

.ActionMiles__list-item-icon_2Nns svg path {
    fill: #222a37;
}

.ActionMiles__list-item_2Mv0 * {
    -webkit-user-select: none;
            user-select: none;
}

.ActionMiles__list-item-text_2_el {
    margin-left: 15px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    cursor: inherit;
}

@media (max-width: 767px) {

.ActionMiles__list-item-text_2_el {
      font-size: 14px;
  }
}

.ActionMiles__list-wrap_2E8L .ActionMiles__list-item_2Mv0 {
    height: 60px;
    border-top: solid 1px #e0e0e0;
}

@media (max-width: 767px) {

.ActionMiles__list-wrap_2E8L .ActionMiles__list-item_2Mv0 {
      height: 55px;
  }
}

.ActionMiles__list-item-detail_3zLX {
  margin-top: 25px;
  list-style-type: none;
}

.ActionMiles__list-wrap_2E8L .ActionMiles__list-item-detail_3zLX {
    margin-top: 0;
    margin-bottom: 25px;
}

.ActionMiles__chevron_2ml3 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 24px;
  height: 24px;
  color: inherit !important;
  cursor: inherit;
  background-color: #eceff5;
  border-radius: 50%;
  outline: none;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

@media (max-width: 767px) {

.ActionMiles__chevron_2ml3 {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
}

.ActionMiles__chevron_expanded_2aXq {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
}

.ActionMiles__theme_b2c_T2Jf .ActionMiles__chevron_2ml3 {
    color: #97ba1e !important;
    background-color: #f4f9e6;
}

