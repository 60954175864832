.SkeletonLoaderProfileOffers__root_1L75 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: calc(100vh - 758px);
  padding: 0 120px;
  background-color: #f1f3f8;
}

  @media (max-width: 767px) {.SkeletonLoaderProfileOffers__root_1L75 {
    padding: 0 55px;
    background-color: #f5f5f5;
  }
}
