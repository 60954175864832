.OldBrowserBanner__root_3HyM {
  position: relative;
  width: 100%;
  max-width: 800px;
  padding: 48px;
  background-color: #ffffff;
  border-radius: 16px;
}

.OldBrowserBanner__close_1uOD {
  position: absolute;
  top: 24px;
  right: 24px;
  color: #7a869a;
}

.OldBrowserBanner__description_bwp8 {
  margin-top: 24px;
}

.OldBrowserBanner__browsers_1kj5 {
  margin-top: 48px;
}

.OldBrowserBanner__browser_2Sc7 {
  display: inline-block;
  width: 165px;
  padding: 20px;
  cursor: pointer;
  background-color: #f6f8fc;
  border-radius: 8px;
}

.OldBrowserBanner__browser_2Sc7:hover {
    background-color: #f6f8fc;
}

.OldBrowserBanner__browser_2Sc7 + .OldBrowserBanner__browser_2Sc7 {
    margin-left: 12px;
}

.OldBrowserBanner__browser-icon_KHmM {
    display: inline-block;
    margin-top: 8px;
    vertical-align: top;
}

.OldBrowserBanner__browser-name_14jl {
    display: inline-block;
    margin-left: 11px;
}