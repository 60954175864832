.Conditions__root_2VbX {
  display: block;
  margin-bottom: 24px;
}

  .Conditions__root_2VbX .Conditions__title_1KdI {
    margin-bottom: 16px;
}

  .Conditions__root_2VbX .Conditions__condition_W3CH {
    position: relative;
    display: block;
    padding-left: 20px;
    margin-bottom: 8px;
}

  .Conditions__root_2VbX .Conditions__condition_W3CH::after {
      position: absolute;
      top: 8px;
      left: 2px;
      width: 5px;
      height: 5px;
      content: '';
      background-color: #97ba1e;
      border-radius: 50%;
}
