

.Tile__root_3m2t {
  position: relative;
  z-index: 1;
  display: block;
  width: 224px;
  height: 240px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 12px;
}

  @media (max-width: 767px) {

.Tile__root_3m2t {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 76px;
  }
}

@media (min-width: 768px) {

.Tile__title_OGyY {
    max-width: calc(100% - 16px);
    margin: 0 auto;
  }
}

.Tile__icon_3n9c {
  display: block;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  width: 120px;
  height: 120px;
  margin: 28px auto 17px;
  cursor: inherit;
  background-size: cover;
}

@media (max-width: 767px) {

.Tile__icon_3n9c {
    width: 60px;
    height: 60px;
    margin: 8px;
  }
}
