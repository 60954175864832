.SectionItem__root_mx19 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
  color: #95a0b3;
}

  .SectionItem__root_mx19:not(:last-child) {
    border-bottom: 1px solid #dde3ed;
}

  @media (max-width: 767px) {.SectionItem__root_mx19 {
    padding: 10px 16px;
  }
}

  .SectionItem__root_mx19.SectionItem__isPaper_1jwV {
    padding: 0;
}

.SectionItem__wide_1bcx {
  padding-top: 16px;
  padding-bottom: 16px;
}

@media (max-width: 767px) {

.SectionItem__wide_1bcx {
    padding: 16px 0;
  }
}

@media (min-width: 768px) {
    .SectionItem__isEdit_c4K2 .SectionItem__item_8fz0,
    .SectionItem__isEdit_c4K2 .SectionItem__action_2XqW {
      display: none;
  }
}

.SectionItem__item_8fz0 {
  width: 100%;
}

.SectionItem__action_2XqW {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-align-self: center;
          align-self: center;
  transition: opacity 0.3s ease-in-out;
}

@media (max-width: 767px) {

.SectionItem__action_2XqW {
    -webkit-align-self: flex-end;
            align-self: flex-end;
    margin-right: 6px;
    opacity: 1;
  }
}

.SectionItem__edit_1gVF {
  position: relative;
  width: 100%;
}

@media (max-width: 767px) {

.SectionItem__edit_1gVF {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 990;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(34, 42, 55, 0.4);
  }
}