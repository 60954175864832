.Active__root_11en .Active__depth_1yAQ {
    box-sizing: border-box;
    padding: 24px;
}

    @media (max-width: 767px) {.Active__root_11en .Active__depth_1yAQ {
      padding: 16px;
  }
}

.Active__modal-buttons_3lPw > * {
  -webkit-flex-basis: auto !important;
          flex-basis: auto !important;
}

.Active__footer_3pD5 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 24px;
}

@media (max-width: 767px) {

.Active__footer_3pD5 {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-top: 8px;
  }
}

.Active__unsubscription_s3E1 {
  min-width: 165px;
}

@media (max-width: 767px) {

.Active__unsubscription_s3E1 {
    width: 100%;
    margin-top: 8px;
  }
}

.Active__close_1txF,
.Active__cancel_3jDC {
  margin-right: 8px;
}

.Active__card_VF5U {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-width: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (max-width: 767px) {

.Active__card_VF5U {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.Active__number_1l90 {
  margin-left: 8px;
}

.Active__modal_1-mm {
  padding-top: 24px;
  padding-right: 24px;
}