
.PasswordChangeNotification__root_3HjB {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 201;
  display: none;
  -webkit-align-items: center;
          align-items: center;
  width: 288px;
  height: 56px;
  background-color: rgba(100,104,109,0.8);
  border-radius: 12px;
  box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 8px 16px 0 rgba(63,63,68,0.15);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%)
}

.PasswordChangeNotification__visible__Ous {
  display: -webkit-flex;
  display: flex
}

.PasswordChangeNotification__emoji_2C7R {
  display: -webkit-flex;
  display: flex;
  width: 24px;
  height: 24px;
  margin-left: 20px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  -webkit-user-select: none;
          user-select: none
}

.PasswordChangeNotification__text_2rNR {
  margin-left: 5px
}