.Card__root_3zAN {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 288px;
  height: 168px;
  padding: 19px 19px 14px 19px;
  color: rgba(0, 0, 0, 0.87);
  cursor: inherit;
  background-color: #ffffff;
  border: solid 1px #dde3ed;
  border-radius: 12px;
  transition: background 0.2s ease-in-out;
}

  .Card__root_3zAN:hover {
    background-color: #ffffff;
}

  .Card__root_3zAN:hover .Card__removeWrapper_2Xpb {
      opacity: 1;
}

.Card__card-image_2B_v {
  position: absolute;
  top: 19px;
  right: 19px;
  width: 42px;
  height: 28px;
}

.Card__top_1xO3 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 20px;
}

.Card__middle_2Nzd {
  margin-bottom: auto;
  color: inherit;
}

.Card__bottom_1Wft {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 4px;
  color: inherit;
}

.Card__number_1V-W {
  display: block;
  font-size: 20px;
  line-height: 24px;
  color: inherit;
}

.Card__bottom-right_2Evn {
  padding-right: 13px;
  color: inherit;
}

.Card__number-space_1Mii {
  display: block;
  width: 200px;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.12);
}

@media (max-width: 767px) {

.Card__number-space_1Mii {
    width: 185px;
  }
}

.Card__name-space_1xd7 {
  display: block;
  width: 200px;
  height: 24px;
  background-color: rgba(0, 0, 0, 0.12);
}

@media (max-width: 767px) {

.Card__name-space_1xd7 {
    width: 185px;
  }
}

.Card__date-space_1jYh {
  display: block;
  width: 60px;
  height: 24px;
  background-color: rgba(0, 0, 0, 0.12);
}

@media (max-width: 767px) {

.Card__date-space_1jYh {
    width: 48px;
  }
}

.Card__theme_white_AkWx {
  color: rgba(0, 0, 0, 0.54);
  background-color: #fff;
  border: 1px solid #c4d600;
}

.Card__theme_white_AkWx .Card__bottom-right_2Evn {
  padding-right: 0;
  margin-right: -5px;
}

@media (max-width: 767px) {

.Card__theme_white_AkWx .Card__bottom-right_2Evn {
    margin-right: 0;
  }
}

.Card__removeWrapper_2Xpb {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #c4d600;
  border-radius: 12px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

@media (max-width: 767px) {

.Card__removeWrapper_2Xpb {
    display: none;
  }
}

.Card__removeAction_Xo7x {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.Card__main_3jKW {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: -5px;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
}

.Card__main-icon_2bt- {
  margin-right: 4px;
  color: inherit;
}
