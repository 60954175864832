
.ConditionList__root_3hb9 {
  display: block;
  margin: 0;
}

.ConditionList__title_1o4U {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 12px;
}

.ConditionList__items_32vN {
  list-style-type: none;
}

.ConditionList__item_DYuq {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.ConditionList__item_DYuq:not(:last-child) {
    margin-bottom: 8px;
}

.ConditionList__icon_2vvX {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 12px;
}
