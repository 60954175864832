@media (max-width: 767px) {.MilesActivateStatus__root_2CQU {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background-color: #ffffff;
  }
}

.MilesActivateStatus__header_1ZZL {
  display: none;
}

@media (max-width: 767px) {

.MilesActivateStatus__header_1ZZL {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 18px 16px;
    border-bottom: 1px solid #dde3ed;
  }
}

@media (max-width: 767px) {

.MilesActivateStatus__close_Pu3I {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 0;
    margin-left: auto;
    background-color: transparent;
    outline: none;
  }
}

@media (max-width: 767px) {

.MilesActivateStatus__content_3ega {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding: 8px;
    padding-right: 8px;
    padding-bottom: 64px;
    padding-left: 8px;
    overflow: auto;
  }
}

.MilesActivateStatus__info_3DFh {
  margin-top: 24px;
  margin-bottom: 24px;
}

@media (max-width: 767px) {

.MilesActivateStatus__info_3DFh {
    padding-top: 0;
  }
}

.MilesActivateStatus__info_3DFh a {
    font-weight: 600;
    color: #97ba1e;
    text-decoration: none;
}

.MilesActivateStatus__info_3DFh a:hover {
      color: #869d22;
}

.MilesActivateStatus__info_3DFh a[href^="tel"] {
      white-space: nowrap;
}

.MilesActivateStatus__notification_2Wh5 {
  margin-bottom: 24px;
}

.MilesActivateStatus__condition_3Nme {
  display: block;
  margin-bottom: 24px;
}
