.MainMenu__root_qq_T {
  position: relative;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: -16px;
  margin-left: -16px;
}
.MainMenu__root_qq_T.MainMenu__media_1We6 ul {
      padding-top: 2px;
}
@media (max-width: 768px) {
      .MainMenu__root_qq_T.MainMenu__media_1We6::before {
        background: linear-gradient(90deg, #A1BFA5, rgba(255,255,255, 0) 16px), linear-gradient(270deg, #A1BFA5, rgba(255,255,255, 0) 16px);
  }
      .MainMenu__root_qq_T.MainMenu__media_1We6 ul {
        padding-top: 22px;
  }
}
@media (max-width: 768px) {
    .MainMenu__root_qq_T::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      pointer-events: none;
      content: '';
      background: linear-gradient(90deg, #fff, rgba(255,255,255, 0) 16px), linear-gradient(270deg, #fff, rgba(255,255,255, 0) 16px);
  }
}
.MainMenu__menu_2DbJ {
  position: relative;
  display: -webkit-flex;
  display: flex;
  padding-top: 26px;
  padding-right: 16px;
  padding-bottom: 2px;
  padding-left: 16px;
  margin-right: -16px;
  margin-left: -16px;
  overflow-y: auto;
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.MainMenu__menu_2DbJ::-webkit-scrollbar {
    display: none;
}
.MainMenu__menu_2DbJ.MainMenu__public_2C03 {
    -webkit-justify-content: center;
            justify-content: center;
}
.MainMenu__menu_link_2u-u {
  position: relative;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 14px 14px;
  color: #222a37;
  white-space: nowrap;
}
.MainMenu__menu_link_2u-u.MainMenu__media_1We6 {
    color: #ffffff;
}
@media (max-width: 1080px) {
.MainMenu__menu_link_2u-u {
    padding: 10px 14px;
  }
}
@media (max-width: 768px) {
.MainMenu__menu_link_2u-u {
    padding: 10px 0;
  }

    .MainMenu__menu_item_KPuL:not(:first-of-type) .MainMenu__menu_link_2u-u {
      margin-left: 12px;
  }

    .MainMenu__menu_item_KPuL:not(:last-of-type) .MainMenu__menu_link_2u-u {
      margin-right: 12px;
  }
}
.MainMenu__menu_link_2u-u.MainMenu__disabled_21UU {
    color: #95a0b3;
    pointer-events: none;
}
.MainMenu__menu_item_KPuL.MainMenu__align_right_ED8R {
    margin-left: auto;
}
.MainMenu__menu_icon_3_W1 {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 6px;
}
.MainMenu__marker_33vw {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #97ba1e;
  border-radius: 1px;
  transition: 0.5s;
}
.MainMenu__marker_33vw.MainMenu__media_1We6 {
    background-color: #ffffff;
}