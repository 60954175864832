.Avatar__root_3DPN {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 132px;
  height: 132px;
  cursor: pointer;
  border: 1px dashed #d0d6e1;
  border-radius: 50%;
}

  .Avatar__root_3DPN.Avatar__disabled_1gXu {
    pointer-events: none;
    opacity: 0.5;
}


.Avatar__button_15qs {
  position: absolute;
  top: 0;
  right: 7px;
  z-index: 1;
}


.Avatar__input_3REm {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  -webkit-appearance: none;
          appearance: none;
}


.Avatar__avatar_1vFN {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  -webkit-transform: translate(-1px, -1px);
          transform: translate(-1px, -1px);
}

