.Empty__root_3w_g {
  max-width: 492px;
  margin: 0 auto 40px;
}

.Empty__description_3ZsG {
  margin-top: 16px;
}

.Empty__description_3ZsG a {
    color: #101112;
    text-decoration: none;
}

@media (min-width: 768px) {
  .Empty__title_2JIl > div {
    text-align: center;
  }
}