.ConfirmRegistration__root_3E68 ul {
    margin: 10px 0 10px 20px;
    list-style-type: disc
}
  .ConfirmRegistration__root_3E68 a {
    color: rgba(0, 0, 0, 0.54);
    text-decoration: underline
}

.ConfirmRegistration__loader_3O5h {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: calc(100vh - 200px);
  padding: 100px
}