.Registration__form_1Tsv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 360px;
  padding-top: 68px;
  padding-bottom: 172px;
  margin-left: 216px;
}

  @media (max-width: 767px) {.Registration__form_1Tsv {
    width: auto;
    padding: 0;
    margin-left: 0;
  }
}

.Registration__content_3x0C {
  padding: 40px 32px 32px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 16px 32px rgba(34, 42, 55, 0.13);
}

@media (max-width: 767px) {

.Registration__content_3x0C {
    padding: 16px 16px 32px;
    border-radius: 0;
  }
}

.Registration__title_n1ey {
  max-width: 272px;
  margin-bottom: 24px;
}

@media (max-width: 767px) {

.Registration__title_n1ey {
    max-width: none;
    margin-bottom: 16px;
  }
}

.Registration__subtitle_xChF {
  display: inline-block;
  margin-bottom: 24px;
}

@media (max-width: 767px) {

.Registration__subtitle_xChF {
    margin-bottom: 16px;
  }
}

.Registration__field_2yr- {
  position: relative;
  display: block;
}

.Registration__child-link_pzxV {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 8px;
}

.Registration__loyalty-provider_HQyx {
  display: block;
  margin-bottom: 10px;
}

.Registration__child-link_pzxV a {
  margin-right: 6px;
}

.Registration__enter-info_3kA1 {
  margin-bottom: 16px;
}

.Registration__alert_2FuR {
  display: block;
  margin-bottom: 24px;
}

.Registration__agreement_2gbL:not(.Registration__business_3T9U) {
    margin-top: 24px;
}

.Registration__agreement_2gbL a {
    color: #505f79;
}

.Registration__socials_1naa {
  margin-top: 32px;
}

.Registration__login_OBEb {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.Registration__resend-code_G98y {
  margin-top: 16px;
}