

.GlowPoint__GlowPoint_ZyZ3 {
  position: relative;
  top: 0;
  right: 0;
  display: inline-block;
  line-height: 0;
  color: inherit;
  pointer-events: none;
}

  .GlowPoint__GlowPoint_theme_red_1aHf .GlowPoint__point_24I_ {
    top: 0 !important;
    right: -1px !important;
    width: 6px;
    height: 6px;
    background-color: #d22630;
}

.GlowPoint__point_24I_ {
  position: absolute;
  display: block;
  width: 5px;
  height: 5px;
  pointer-events: none;
  background-color: #c4d600;
  border-radius: 50%;
}
