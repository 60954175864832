.Achievements__root_1EK5 {
  width: 700px;
  margin: 0 auto;
}

  .Achievements__root_1EK5:not(.Achievements__isPublic_2EEZ) {
    padding-bottom: 300px;
}

  @media (max-width: 767px) {.Achievements__root_1EK5 {
    width: 100%;
    padding: 0 8px;
    background-color: #f1f3f8;
  }

    .Achievements__root_1EK5:not(.Achievements__isPublic_2EEZ)s {
      padding-bottom: 100px;
  }
}

.Achievements__group_2ynl {
  margin-top: 100px;
  text-align: center;
}

@media (max-width: 767px) {

.Achievements__group_2ynl {
    padding: 18px 16px 40px;
    margin-top: 40px;
    text-align: left;
    background-color: #fff;
    border-radius: 12px;
  }

    .Achievements__group_2ynl:first-child {
      margin-top: 0;
  }
}

.Achievements__title_3Ixl {
  font-size: 28px;
  font-weight: bold;
  line-height: 36px;
  color: #222a37;
}

@media (max-width: 767px) {

.Achievements__title_3Ixl {
    font-size: 14px;
    line-height: 20px;
  }
}

.Achievements__subtitle_31iW {
  margin-top: 12px;
  font-size: 14px;
  line-height: 20px;
  color: #222a37;
}

@media (max-width: 767px) {

.Achievements__subtitle_31iW {
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    color: #7a869a;
  }
}

.Achievements__list_7dnc {
  margin-top: 60px;
}

@media (max-width: 767px) {

.Achievements__list_7dnc {
    margin-top: 24px;
  }
}

.Achievements__list-inner_1MzT {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: -30px -10px;
}

@media (max-width: 767px) {

.Achievements__list-inner_1MzT {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: -12px -5px;
  }

    .Achievements__list-inner_1MzT::after {
      width: 94px;
      content: '';
  }
}

.Achievements__item_1nFH {
  display: inline-block;
  width: 160px;
  margin: 30px 10px;
  overflow: visible;
  text-align: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

@media (max-width: 767px) {

.Achievements__item_1nFH {
    width: 84px;
    margin: 12px 5px;
  }
}

.Achievements__item-name_2Ik9 {
    display: inline-block;
    margin-top: 16px;
}

.Achievements__item-name_2Ik9 > p {
      word-break: break-word;
}

.Achievements__stub_3z4s {
  margin-top: 0;
  margin-bottom: 0;
}

.Achievements__inactive_HCHi .Achievements__badge_GsZ_ {
    background-color: #eceff5;
}

.Achievements__badge_GsZ_ {
  position: relative;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100px;
  height: 100px;
  padding: 10px;
  background-color: #eceff5;
  border-radius: 12px;
}

.Achievements__badge-img_3dCc {
    height: 100%;
}

@media (max-width: 767px) {

.Achievements__badge_GsZ_ {
    width: 84px;
    height: 84px;
    padding: 8px;
  }
}

.Achievements__lock_1_z6 {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  color: #fff;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media (max-width: 767px) {

.Achievements__modal-overlay_1w2B {
    -webkit-align-items: flex-end;
            align-items: flex-end;
  }
}

.Achievements__modal_1rwe {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  max-width: 600px;
  min-height: 544px;
  padding: 48px;
  background-color: #fff;
  border-radius: 16px;
}

.Achievements__modal-img_3dR8 {
    height: 240px;
    -webkit-filter: drop-shadow(0px 4px 10px rgba(34, 42, 55, 0.13));
            filter: drop-shadow(0px 4px 10px rgba(34, 42, 55, 0.13));
}

@media (max-width: 767px) {

.Achievements__modal-img_3dR8 {
      height: 200px;
  }
}

@media (max-width: 767px) {

.Achievements__modal_1rwe {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    min-height: auto;
    max-height: calc(100vh - 96px);
    padding: 8px 8px 16px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.Achievements__modal-badge_2BlE {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 18px;
    background-color: #eceff5;
    border-radius: 8px;
}

@media (max-width: 767px) {

.Achievements__modal-badge_2BlE {
      padding: 10px;
  }
}

.Achievements__modal-close_1zh8 {
    position: absolute;
    top: -32px;
    right: -34px;
    color: #95a0b3;
}

@media (max-width: 767px) {

.Achievements__modal-close_1zh8 {
      top: 12px;
      right: 10px;
      color: #fff;
  }

      .Achievements__modal-close-inactive_M8Fu {
        color: #000;
  }
}

.Achievements__modal-name_2RPy {
    margin-top: 48px;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    color: #222a37;
}

@media (max-width: 767px) {

.Achievements__modal-name_2RPy {
      margin-top: 24px;
      font-size: 20px;
      line-height: 28px;
  }
}

.Achievements__modal-description_31-e {
    margin-top: 20px;
}

@media (max-width: 767px) {

.Achievements__modal-description_31-e {
      padding: 0 8px;
      margin-top: 12px;
      margin-bottom: 24px;
  }
}

.Achievements__modal-social-buttons_1TQ1, .Achievements__modal-know-more_3uqx {
    width: 200px;
    min-height: 47px;
    margin-top: 32px;
}

@media (max-width: 767px) {

.Achievements__modal-social-buttons_1TQ1, .Achievements__modal-know-more_3uqx {
      width: 100%;
      margin-top: 12px;
  }
}

.Achievements__modal-badge_2BlE, .Achievements__modal-name_2RPy, .Achievements__modal-description_31-e, .Achievements__modal-social-buttons_1TQ1, .Achievements__modal-know-more_3uqx {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.Achievements__modal-bottom_10FU {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}