.PaymentMethods__block_1IEg {
  margin-bottom: 40px
}

.PaymentMethods__notification_2iTh {
  margin-bottom: 20px
}

.PaymentMethods__modal-description_1uQR {
  margin-bottom: 16px
}

.PaymentMethods__modal-field_33oR {
  margin-bottom: 32px
}

.PaymentMethods__modal-repeat_3klu {
  margin-bottom: 16px
}

.PaymentMethods__modal-title_251J {
  font-size: 24px;
  line-height: 32px
}