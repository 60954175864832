.Statistics__wrap_2xLB {
  position: relative;
  background-color: #f1f3f8;
  background-size: cover;
}

  .Statistics__wrap_2xLB .Statistics__nearestFlight_1ixt {
    margin-top: -16px;
}


.Statistics__map_MHjS {
  height: 220px;
}


.Statistics__map-modal_1PNO {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 100% !important;
    height: 100%;
}


.Statistics__map-modal-overlay_15FJ {
      -webkit-align-items: flex-start !important;
              align-items: flex-start !important;
}


.Statistics__map-modal-header_1AjP {
      z-index: 1;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      width: 100%;
      height: 56px;
      padding: 0 16px;
      background-color: #ffffff;
}


.Statistics__map-modal__close-button_1ApT {
      color: rgba(0, 0, 0, 0.87);
}


.Statistics__map_MHjS .gm-svpc {
    display: none;
}


@media (max-width: 767px) {


.Statistics__map_MHjS {
    height: calc(100% - 56px);
  }

    .Statistics__map_MHjS .gm-fullscreen-control, .Statistics__map_MHjS .gm-bundled-control-on-bottom {
      display: none;
  }

    .Statistics__map_MHjS .gm-bundled-control-on-bottom {
      margin-bottom: 130px !important;
  }
}


@media (min-width: 768px) {


.Statistics__container_Ydql {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 100%;
    height: 128px;
    margin: 0 auto;
    pointer-events: none;
    border-radius: 16px;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
  }
}


@media (min-width: 1280px) {


.Statistics__container_Ydql {
    width: 720px;
    box-shadow: 0px 4px 10px rgba(29, 33, 41, 0.13);
  }
}


.Statistics__aeroplane_1hyp {
  display: inline-block;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}


.Statistics__buy-ticket-mobile-wrap_2AX4 {
  background-color: #f1f3f8;
}


.Statistics__nearestFlight_1ixt {
  padding: 0 8px;
  margin-top: 24px;
}


.Statistics__nearestFlight_1ixt::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 52px;
    content: '';
    background-color: #ffffff;
}