.Section__root_rAyw {
  position: relative;
  padding: 16px 24px 0;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 10px -2px rgba(29, 33, 41, 0.13);
}

  @media (max-width: 767px) {.Section__root_rAyw {
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }
}

.Section__head_3sjt {
  position: relative;
  z-index: 2;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-bottom: 16px;
  background-color: #fff;
  border-bottom: 1px solid #dde3ed;
  transition: box-shadow 0.3s;
}

@media (max-width: 767px) {

.Section__head_3sjt {
    padding: 18px 16px;
    border-bottom-width: 0;
    box-shadow: 0 4px 10px -2px rgba(29, 33, 41, 0.13);
  }
}

.Section__bottom_2NBv {
  padding-top: 24px;
  padding-bottom: 24px;
}