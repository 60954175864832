.MilesStatus__root_2Q8e {
  position: relative;
  margin: 0;
  background-color: #fff;
}

  @media (max-width: 767px) {.MilesStatus__root_2Q8e {
    position: fixed;
    top: 56px;
    left: 0;
    z-index: 310;
    width: 100%;
    height: 100%;
    padding: 16px 16px 166px;
    margin: 0;
    overflow: auto;
  }
}

.MilesStatus__title_CsvF {
  display: none;
}

@media (max-width: 767px) {

.MilesStatus__title_CsvF {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 311;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    min-height: 56px;
    padding: 0 16px;
    overflow-y: hidden;
    background-color: #fff;
    border-bottom: solid 1px #e8e8e8;
  }
}

.MilesStatus__title-icon_3GyB {
    margin-left: auto;
    cursor: pointer;
}

.MilesStatus__notification_1J3b {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin: 6px 0 24px;
}

.MilesStatus__notification-description_SaML a {
    color: #757575;
    text-decoration: underline;
}

@media (max-width: 767px) {

.MilesStatus__notification_1J3b {
    margin-top: 0;
  }
}

.MilesStatus__info_uIE3 {
  display: block;
  margin: 24px 0 40px;
}

@media (max-width: 767px) {

.MilesStatus__info_uIE3 {
    margin: 0;
  }
}

.MilesStatus__info_uIE3:last-child {
    margin-bottom: 0;
}

.MilesStatus__info_uIE3 a {
    display: inline;
    padding-top: 1px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    text-decoration: underline;
    cursor: pointer;
    border-bottom: none;
}

.MilesStatus__info_uIE3 a:hover {
      text-decoration: none;
}

.MilesStatus__buy_Z7uz {
  display: block;
  margin: 0 0 8px;
}

.MilesStatus__fields_3qoR {
  display: block;
  width: 100%;
  margin: 0 0 17px;
}

.MilesStatus__card-payment_12DB {
  display: block;
  margin: 7px 0 17px;
}

@media (max-width: 767px) {

.MilesStatus__card-payment_12DB {
    margin: 3px 0 0;
  }
}

.MilesStatus__m-select_F7tf {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 58px;
  border: solid 1px #75787b;
  border-radius: 4px;
}

/* ------ other ------ */

@media (max-width: 767px) {

.MilesStatus__desktop_wHB_ {
    display: none;
  }
}

@media (min-width: 767px) {

.MilesStatus__mobile_gtrs {
    display: none;
  }
}

.MilesStatus__incorrect-code-notification_39ho {
  margin-bottom: 16px;
}

@media (max-width: 767px) {

.MilesStatus__incorrect-code-notification_39ho {
    /* Fix render iOS 14 Safari */
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
}
