.Item__root_61FR {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 16px 0;
  margin: 0 16px;
  border-top: 1px solid #eceff5;
}

.Item__content_1l4w {
  display: block;
  min-width: 0;
}

.Item__actions_1mw6 {
  position: relative;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  width: 16px;
  height: 16px;
}

.Item__toggle_1_lN {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 16px;
  height: 16px;
  color: #95a0b3;
  outline: none;
}

.Item__toggle_1_lN.Item__open_3CMq {
    color: #222a37;
}

.Item__menu_Su5f {
  position: absolute;
  top: 23px;
  right: 0;
  z-index: 1;
  background-color: #ffffff;
  border: 1px solid #d0d6e1;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(29, 33, 41, 0.13);
}

.Item__action_2uy0 {
  width: 100%;
  padding: 12px 15px;
  background: none;
  border: none;
  outline: none;
}