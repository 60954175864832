.S7PriorityCard__card_bXDi {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain
}

.S7PriorityCard__card-info_JzYR {
  position: absolute;
  top: 10%;
  right: 5%;
  display: block;
  color: #757575;
  text-align: right
}

.S7PriorityCard__img_2RQM {
  width: 100%;
  box-shadow: 0 8px 16px 0 rgba(63,63,68,0.15)
}