.MilesRecoveryRequestForm__root_tc1M {
  box-sizing: border-box;
  padding-bottom: 12px;
  margin: 25px 0 0;
}

  .MilesRecoveryRequestForm__root_open_3O5T {
    padding-bottom: 80px;
}

.MilesRecoveryRequestForm__form_2ppb {
  max-width: 720px;
}

.MilesRecoveryRequestForm__notification_3EeF {
  margin-bottom: 24px;
}

.MilesRecoveryRequestForm__notification_3EeF b {
    font-weight: 600;
}

.MilesRecoveryRequestForm__notification_3EeF > div:nth-child(2) {
    margin-top: 16px;
}

.MilesRecoveryRequestForm__content_20TL {
  display: -webkit-flex;
  display: flex;
}

.MilesRecoveryRequestForm__actions_2uRn {
  display: -webkit-flex;
  display: flex;
  margin-top: 32px;
}

.MilesRecoveryRequestForm__fields_348Z {
  width: 100%;
}

.MilesRecoveryRequestForm__action_3pIG {
  width: 220px;
}

.MilesRecoveryRequestForm__action_3pIG:not(:last-child) {
    margin-right: 8px;
}

.MilesRecoveryRequestForm__boarding-pass_Woiw {
  min-width: 200px;
  height: 296px;
  margin: 0 0 0 60px;
  background-size: cover;
}

.MilesRecoveryRequestForm__flight_3QTQ {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 621px;
  padding: 24px;
  margin-top: 24px;
  border: 1px solid #dde3ed;
  border-radius: 8px;
}

.MilesRecoveryRequestForm__flight_logo_1acH {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: #97ba1e;
}

.MilesRecoveryRequestForm__flight_logo_1acH > svg {
      margin-right: 8px;
}

.MilesRecoveryRequestForm__flight_error_3IXM {
    margin-top: 24px;
}

.MilesRecoveryRequestForm__flight_3QTQ .MilesRecoveryRequestForm__arrival_time_1-_h {
    margin-left: 16px;
}

.MilesRecoveryRequestForm__flight_3QTQ .MilesRecoveryRequestForm__departure_time_MBlM {
    margin-right: 16px;
}

.MilesRecoveryRequestForm__row_2Kjt {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.MilesRecoveryRequestForm__row_2Kjt:not(:last-child) {
    margin-bottom: 8px;
}

.MilesRecoveryRequestForm__col_2phI {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.MilesRecoveryRequestForm__col-1_3hHP {
    width: 168px;
    margin-right: 8px;
}

.MilesRecoveryRequestForm__col-2_3Nih {
    width: auto;
}

.MilesRecoveryRequestForm__col-3_1l7g {
    margin-left: auto;
}

.MilesRecoveryRequestForm__line_horizontal_3m0T {
  -webkit-flex-grow: 4;
          flex-grow: 4;
  margin: 0 8px;
  border-bottom: 2px dashed #d0d6e1;
}

@media (max-width: 1280px) {
  .MilesRecoveryRequestForm__root_tc1M {
    position: fixed;
    top: 96px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 322;
    width: 100%;
    padding: 16px;
    margin: 0;
    overflow: auto;
    background-color: #ffffff;
  }

  .MilesRecoveryRequestForm__form_2ppb {
    max-width: none;
  }

  .MilesRecoveryRequestForm__title_3qJJ {
    position: fixed;
    top: 40px;
    right: 0;
    left: 0;
    z-index: 322;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    min-height: 56px;
    padding: 0 16px;
    background-color: #ffffff;
    border-bottom: 1px solid #dde3ed;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

    .MilesRecoveryRequestForm__title_icon_2CKJ {
      color: inherit !important;
      outline: none;
  }

  .MilesRecoveryRequestForm__flight_3QTQ {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .MilesRecoveryRequestForm__actions_2uRn {
    margin-top: 40px;
  }

  .MilesRecoveryRequestForm__action_3pIG {
    width: 100%;
  }

  .MilesRecoveryRequestForm__boarding-pass_Woiw {
    display: none;
  }

  .MilesRecoveryRequestForm__flight_3QTQ {
    padding: 0;
    margin-top: 40px;
    border: none;
  }

  .MilesRecoveryRequestForm__arrival_city_9557,
  .MilesRecoveryRequestForm__departure_city_2Ijx {
    margin-right: 4px;
  }

  .MilesRecoveryRequestForm__arrival_time_1-_h,
  .MilesRecoveryRequestForm__departure_time_MBlM {
    margin: 0 8px 0 0 !important;
  }

  .MilesRecoveryRequestForm__row_2Kjt {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

    .MilesRecoveryRequestForm__row_2Kjt:not(:last-child) {
      margin-bottom: 13px;
  }
      .MilesRecoveryRequestForm__row_nested_285y:not(:last-child) {
        margin-bottom: 5px;
  }
    .MilesRecoveryRequestForm__col-1_3hHP {
      position: relative;
      width: 20px;
      margin-right: 12px;
      color: #97ba1e;
  }

      .MilesRecoveryRequestForm__col-1_3hHP svg {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
  }

    .MilesRecoveryRequestForm__col-2_3Nih {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: flex-start;
              align-items: flex-start;
  }

      .MilesRecoveryRequestForm__col-2_3Nih .MilesRecoveryRequestForm__div_37sl {
        display: -webkit-flex;
        display: flex;
  }

  .MilesRecoveryRequestForm__line_vertical_3cgE {
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
    z-index: -1;
    height: 90px;
    border-right: 2px dotted #dce7aa;
  }

  .MilesRecoveryRequestForm__plug_vZ-u {
    position: absolute;
    top: 100%;
    left: calc(50% - 1px);
    width: 2px;
    height: calc(100%);
    background: #ffffff;
  }

  .MilesRecoveryRequestForm__circle_1F9n {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 16px;
    height: 16px;
    margin-top: 2px;
    background: #ffffff;
    border: 2px solid #dce7aa;
    border-radius: 50%;
  }

    .MilesRecoveryRequestForm__circle_1F9n div {
      width: 6px;
      height: 6px;
      background: #97ba1e;
      border-radius: 50%;
  }
}

.MilesRecoveryRequestForm__overlay_1--c {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 321;
  display: -webkit-flex;
  display: flex;
  background-color: #222a37;
  opacity: 0.6;
}
