.RadioSelect__root_3Lwt {
  display: -webkit-flex;
  display: flex;
}

.RadioSelect__option_3qSC {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 15px 24px;
  margin: 0;
  color: #7a869a;
  background-color: #fff;
  border: 1px solid #d0d6e1;
  outline: none;
}

.RadioSelect__option_3qSC:first-child {
    border-right: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.RadioSelect__option_3qSC:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.RadioSelect__option_active_18xM {
    color: #fff;
    background-color: #97ba1e;
}

.RadioSelect__invalid_27OJ .RadioSelect__option_3qSC {
  border-color: #f14d56;
}

.RadioSelect__disabled_3Aok {
  opacity: 0.5;
}