.DocumentRemove__hide_2oMQ {
  display: inline;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

  @media (max-width: 767px) {
    .DocumentRemove__hide_mobile_19TA {
      display: none;
  }
}
