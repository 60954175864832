.Expand__root_4saO {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 12px;
}

  @media (max-width: 767px) {.Expand__root_4saO {
    padding: 16px;
  }
}

.Expand__button_2Ewi {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  color: #222a37 !important;
  text-align: left;
  cursor: pointer;
  outline: none;
}

.Expand__content_DF00 {
  margin-top: 24px;
}

.Expand__title_3dun {
  display: -webkit-flex;
  display: flex;
}

.Expand__title-extra_2cep {
  position: relative;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  margin-left: 28px;
}

.Expand__title-extra_2cep:before {
    position: absolute;
    top: 50%;
    left: -16px;
    display: block;
    width: 4px;
    height: 4px;
    content: '';
    background-color: #222a37;
    border-radius: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}