.Mobile__heading_3JL7 {
  display: block;
  padding: 24px;
  background-color: #ffffff;
}

.Mobile__name_3Ntv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 8px;
  overflow: hidden;
}

.Mobile__name_part_ZyWd {
  display: inline-block;
  max-width: 100%;
}

.Mobile__name_part_ZyWd:first-child {
    margin-right: 4px;
}

.Mobile__info_37tn {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 12px;
}

.Mobile__dot_1KX7 {
  display: block;
  width: 4px;
  height: 4px;
  margin: 0 12px;
  background: #d0d6e1;
  border-radius: 50%;
}

.Mobile__item_2lD6 {
  display: block;
  padding: 0 8px;
  margin-top: 8px;
}

.Mobile__remove_2xCN {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 40px 16px 16px;
}