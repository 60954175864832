.Info__root_1ebP {
  display: -webkit-flex;
  display: flex;
}

  @media (max-width: 1279px) {.Info__root_1ebP {
    height: auto;
    padding: 32px 8px 40px;
  }
}

  @media (max-width: 1279px) and (min-width: 768px) {.Info__root_1ebP {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

    .Info__root_1ebP .Info__left_XLji, .Info__root_1ebP .Info__right_2ebh {
      width: calc(50% - 4px);
  }

    .Info__root_1ebP .Info__left_XLji {
      margin-right: 8px;
  }
}

  @media (max-width: 767px) {.Info__root_1ebP {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

    .Info__root_1ebP .Info__left_XLji {
      margin-bottom: 8px;
  }
}

.Info__left_XLji, .Info__right_2ebh {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.Info__icons_yrqM {
  display: -webkit-flex;
  display: flex;
  height: 24px;
  min-height: 24px;
  color: #ffffff;
}

.Info__icons_left_3tBf,
  .Info__icons_center_PBav,
  .Info__icons_right_bhS1 {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    height: 100%;
    background-color: #ffffff;
}

.Info__icons_yrqM > svg {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

@media (max-width: 1279px) {

.Info__icons_yrqM {
    display: none;
  }
}

.Info__circle_1AqZ {
  position: absolute;
  top: -20px;
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 50%;
}

.Info__circle_1AqZ > svg > path {
    fill: url(#linear-gradient-green-seagreen);
}

@media (max-width: 1279px) {

.Info__circle_1AqZ {
    left: 50%;
    box-shadow: 0px 0px 4px rgba(29, 33, 41, 0.04), 0px 4px 12px rgba(29, 33, 41, 0.04);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

@media (min-width: 1280px) {

.Info__left_XLji {
    width: 294px;
  }
      .Info__left_XLji .Info__icons_left_3tBf {
        width: 58px;
        border-top-left-radius: 16px;
  }

      .Info__left_XLji .Info__icons_center_PBav {
        width: 63px;
  }

      .Info__left_XLji .Info__icons_right_bhS1 {
        width: 38px;
  }
      .Info__left_XLji .Info__circle_left_2c-m {
        left: 72px;
  }

      .Info__left_XLji .Info__circle_right_1opX {
        left: 203px;
  }

    .Info__left_XLji .Info__items_33ey {
      padding-right: 11px;
      border-bottom-left-radius: 16px;
  }
}

@media (max-width: 1279px) {
    .Info__left_XLji .Info__items_33ey {
      background-color: transparent;
  }
}

@media (min-width: 1280px) {

.Info__right_2ebh {
    width: 426px;
  }
      .Info__right_2ebh .Info__icons_left_3tBf {
        width: 169px;
  }

      .Info__right_2ebh .Info__icons_right_bhS1 {
        width: 189px;
        border-top-right-radius: 16px;
  }

    .Info__right_2ebh .Info__circle_1AqZ {
      left: 183px;
  }

    .Info__right_2ebh .Info__items_33ey {
      padding-left: 11px;
      border-bottom-right-radius: 16px;
  }
}

@media (max-width: 1279px) {
    .Info__right_2ebh .Info__items_33ey {
      border-radius: 12px;
      box-shadow: 0px 0px 4px rgba(29, 33, 41, 0.04), 0px 4px 12px rgba(29, 33, 41, 0.04);
  }
}

@media (max-width: 767px) {
    .Info__right_2ebh .Info__items_33ey {
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      height: auto;
  }

    .Info__right_2ebh .Info__circle_1AqZ {
      display: none;
  }
}

.Info__items_33ey {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 104px;
  padding: 16px 32px 40px;
  color: #222a37;
  background-color: #ffffff;
}

@media (max-width: 1279px) {

.Info__items_33ey {
    -webkit-justify-content: center;
            justify-content: center;
    height: 100px;
    padding: 0;
  }
}

.Info__delimiter_34Gq {
  position: absolute;
  top: 20px;
  width: 1px;
  height: 88px;
  background-color: #dde3ed;
}

@media (max-width: 1279px) {

.Info__delimiter_34Gq {
    display: none;
  }
}

@media (max-width: 767px) {
      .Info__empty_rMeA .Info__right_2ebh .Info__items_33ey {
        padding-bottom: 20px;
  }
}

