.LoyaltyCard__root_96X8 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

  @media (min-width: 767px) {.LoyaltyCard__root_96X8 {
    width: 408px;
    padding-bottom: 172px;
    margin-top: 68px;
    margin-left: 216px;
  }
}

.LoyaltyCard__title_2Ex2 {
  text-align: center;
}

.LoyaltyCard__without_button_ZwPk .LoyaltyCard__body_30SD {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.LoyaltyCard__body_30SD {
  padding: 40px 32px 24px;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.LoyaltyCard__content_24MP {
  margin-top: 30px;
}

.LoyaltyCard__card-wrap_2zZB {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100px;
  margin-top: 20px;
  overflow: hidden;
  border-bottom: 1px solid #e0e0e0;
}

.LoyaltyCard__card_1rrt {
  width: 264px;
}

.LoyaltyCard__button_37-- {
  padding: 0 20px 20px;
  background-color: #fff;
}