.GroupData__GroupData_3xU6 {
  position: relative;
  display: block;
  padding: 16px 32px 0;
  background-color: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0 4px 10px -2px rgba(29, 33, 41, 0.13);
}

  @media (max-width: 1280px) {.GroupData__GroupData_3xU6 {
    padding: 16px 16px 0;
  }
}

  @media (max-width: 767px) {.GroupData__GroupData_3xU6 {
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }
}

  @media (max-width: 767px) {

  .GroupData__GroupData_edit_B1G1:not(.GroupData__GroupData_filter_3TnV) {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 200;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      background-color: #EDEDED;
  }

      .GroupData__GroupData_edit_B1G1:not(.GroupData__GroupData_filter_3TnV) .GroupData__title_yZ6T {
        display: none;
  }

      .GroupData__GroupData_edit_B1G1:not(.GroupData__GroupData_filter_3TnV) .GroupData__content_2am_ {
        z-index: 1;
        height: 100%;
        background-color: #EDEDED;
  }

      .GroupData__GroupData_edit_B1G1:not(.GroupData__GroupData_filter_3TnV) .GroupData__heading_c004 {
        padding: 16px;
        background-color: #EDEDED;
  }

        .GroupData__GroupData_edit_B1G1:not(.GroupData__GroupData_filter_3TnV) .GroupData__heading_c004::before {
          font-size: 18px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.87);
          content: '\420\435\434\430\43A\442\438\440\43E\432\430\43D\438\435';
  }
}

  .GroupData__GroupData_edit_B1G1 {
    z-index: 1;
}

  @media (max-width: 767px) {
      .GroupData__GroupData_edit_B1G1 .GroupData__heading_c004 {
        -webkit-flex-shrink: 0;
                flex-shrink: 0;
  }
}

  @media (max-width: 767px) {

  .GroupData__GroupData_filter_3TnV {
      z-index: auto;
  }
}

  .GroupData__GroupData_filter_3TnV .GroupData__heading_c004 {
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
}

.GroupData__heading_c004 {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: #fff;
  transition: box-shadow 0.3s;
}

@media (max-width: 767px) {

.GroupData__heading_c004 {
    padding: 18px 16px;
  }
}

.GroupData__title_yZ6T a {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: underline;
}

.GroupData__title_yZ6T a:hover {
    text-decoration: none;
}

.GroupData__content_2am_ {
  position: relative;
  display: block;
  background-color: #fff;
}

@media (max-width: 767px) {

.GroupData__content_2am_ {
    padding: 0 16px;
  }
}

.GroupData__item_2UFD {
  display: block;
  padding: 24px 0;
  color: rgba(0, 0, 0, 0.87);
}

.GroupData__item_2UFD:not(:last-child) {
    border-bottom: 1px solid #dde3ed;
}

.GroupData__item_edit_1CUH {
    position: relative;
    z-index: auto;
}

.GroupData__item_isDocRow_2tGU {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 68px;
    padding: 0;
}

.GroupData__item_hideBorderBottom_2e8j {
    border-bottom: 0 !important;
}

.GroupData__openFilter_3nne {
  display: none;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 26px;
  height: 26px;
}

@media (max-width: 767px) {

.GroupData__openFilter_3nne {
    display: -webkit-flex;
    display: flex;
  }
}

.GroupData__delete_1rSQ {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 26px;
  height: 26px;
  margin-right: 12px;
  margin-left: auto;
}

.GroupData__filter_3n5O {
  width: 100%;
  padding-top: 13px;
}

.GroupData__filter_noTitle_1nNj {
    padding-top: 0;
}

.GroupData__archive-icon_UmmP {
  margin: -2px 0 0 -3px;
}

.GroupData__error_1A9D {
  padding: 16px 0;
}

.GroupData__familyAccount_GI7L {
  border-bottom: solid 1px #dde3ed;
}
