.Label__Label_3_SD {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  white-space: nowrap;
  opacity: 0.54;
}

  .Label__Label_whiteSpace_nowrap_4U2L {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    overflow: hidden;
    white-space: nowrap;
}
