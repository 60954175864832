.ModalForm__avatar_3b5_ {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 12px;
  margin-bottom: 30px
}

.ModalForm__field_3pBr:not(:last-child) {
    margin-bottom: 16px
}

.ModalForm__switcher_3L1b {
  height: auto;
  min-height: 40px
}

.ModalForm__switcher_button_type_1jyA {
  padding-top: 11px;
  padding-bottom: 11px;
  color: #95a0b3
}

.ModalForm__switcher_button_weight_2Pim {
  text-align: center
}

.ModalForm__switcher_text_PP0h {
  padding-top: 3px
}

.ModalForm__pet_3xiv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center
}

.ModalForm__message_10U5 {
  margin-top: 24px
}

@media (max-width: 767px) {
    .ModalForm__modal_root_2oXy .ModalForm__modal_wrapper_V5q8 {
      max-height: calc(100% - 16px)
  }
}