.StatisticsNotAvailable__root_rmHe {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 240px;
  background-color: #f1f3f8;
}

  @media (max-width: 767px) {.StatisticsNotAvailable__root_rmHe {
    height: 168px;
  }
}