.ProfilePurchases__root_1ZmX {
  display: block;
  min-height: calc(100vh - 638px);
  padding-top: 40px;
  padding-bottom: 120px;
  background-color: #f1f3f8;
}

.ProfilePurchases__wrapper_2Ka8 {
  max-width: 1064px;
}

.ProfilePurchases__topPanel_2-mo {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 24px;
}

.ProfilePurchases__title_Os8l {
  width: auto;
  margin-right: 32px;
}

.ProfilePurchases__filter_23K4 {
  margin-top: 8px;
}

.ProfilePurchases__mobile_2mxQ {
  padding-right: 16px;
  padding-bottom: 26px;
  padding-left: 16px;
}

.ProfilePurchases__mobile_2mxQ .ProfilePurchases__topPanel_2-mo {
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-align-items: baseline;
            align-items: baseline;
    width: 95%;
}

.ProfilePurchases__mobile_2mxQ .ProfilePurchases__title_Os8l {
    margin-bottom: 24px;
}
