.PageLoader__root_JMYh {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 100px;
  background-color: #ffffff
}

.PageLoader__transparentBackground_viLF {
  background-color: transparent
}