

.Status__status_2q-8 {
  display: block;
  font-size: 24px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.87);
  text-transform: capitalize;
}

  @media (max-width: 767px) {

.Status__status_2q-8 {
    margin-top: 6px;
  }
}
