.MilesRecovery__root_30RI {
  display: block;
  width: 100%;
  padding: 0 0 40px;
  background-color: #ffffff;
}

.MilesRecovery__title_RZno {
  display: none;
}

.MilesRecovery__new-button_BNB4 {
  width: 160px;
  margin: 25px 0 60px;
}

.MilesRecovery__notification__pr3 {
  margin: 24px 0 10px;
}

.MilesRecovery__requests_title_3J_Q {
  padding-bottom: 32px;
  border-bottom: solid 1px #dde3ed;
}

.MilesRecovery__request_WAeG {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 22px 0;
  border-bottom: solid 1px #dde3ed;
}

.MilesRecovery__request_info_Vzlb {
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.MilesRecovery__request_info_Vzlb:focus {
      outline: none;
}

.MilesRecovery__request_info_opened_3CgG::before {
      position: absolute;
      top: 0;
      right: -32px;
      left: -32px;
      z-index: -1;
      display: block;
      height: 100%;
      content: '';
      background-color: #f6f8fc;
}

.MilesRecovery__request_pending_2XVS .MilesRecovery__status_1yDk {
      background-color: #f6f8fc;
}

.MilesRecovery__request_pending_2XVS .MilesRecovery__status_icon_2UVX {
      color: #7a869a;
}

.MilesRecovery__request_rejected_1tKg .MilesRecovery__status_1yDk {
      background-color: #feedee;
}

.MilesRecovery__request_rejected_1tKg .MilesRecovery__status_icon_2UVX {
      color: #f14d56;
}

.MilesRecovery__request_success_18qY .MilesRecovery__status_1yDk {
      background-color: #f6f9ea;
}

.MilesRecovery__request_success_18qY .MilesRecovery__status_icon_2UVX {
      color: #97ba1e;
}

.MilesRecovery__request_WAeG .MilesRecovery__number_vtow {
    width: 120px;
}

.MilesRecovery__request_WAeG .MilesRecovery__flight-date_1Zv6 {
    width: 100px;
}

.MilesRecovery__request_WAeG .MilesRecovery__partner-name_QWFC {
    width: 140px;
}

.MilesRecovery__request_WAeG .MilesRecovery__flight-number_Z4Ly {
    width: 80px;
}

.MilesRecovery__request_WAeG .MilesRecovery__type-description_3mTv {
    width: 272px;
}

.MilesRecovery__request_WAeG .MilesRecovery__status_1yDk {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    max-width: 150px;
    padding: 4px 6px;
    margin-left: auto;
    border-radius: 8px;
}

.MilesRecovery__request_WAeG .MilesRecovery__status_icon_2UVX {
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: center;
              align-items: center;
      margin-right: 6px;
}

.MilesRecovery__request_WAeG .MilesRecovery__status_icon_2UVX:last-child {
        margin-right: 0;
}

.MilesRecovery__more-button_1Xc_ {
  width: 144px;
  margin: 24px auto 0;
}

.MilesRecovery__rules_1CHX {
  margin-top: 24px;
}

.MilesRecovery__loader_3RZG {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

div.MilesRecovery__info_buttons_1iLP {
  padding-right: 0;
  padding-left: 0;
}

@media (max-width: 1280px) {
  .MilesRecovery__root_30RI {
    position: fixed;
    top: 56px;
    left: 0;
    z-index: 310;
    width: 100%;
    height: 100%;
    padding: 0 16px 144px;
    margin: 0;
    overflow: auto;
  }

  .MilesRecovery__title_RZno {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 310;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    min-height: 56px;
    padding: 0 16px;
    overflow-y: hidden;
    background-color: #fff;
    border-bottom: solid 1px #dde3ed;
  }

    .MilesRecovery__title_icon_fin0 {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      margin-right: 12px;
      color: inherit !important;
      outline: none;
  }

  .MilesRecovery__new-button_BNB4 {
    margin: 24px 0;
  }

  .MilesRecovery__notification__pr3 {
    margin-bottom: 24px;
  }

  .MilesRecovery__request_WAeG {
    padding: 20px 0;
  }

    .MilesRecovery__request_left_3HR6 {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      width: 100%;
      max-width: 232px;
  }

    .MilesRecovery__request_right_1eJj {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: flex-end;
              align-items: flex-end;
      width: 100%;
  }

    .MilesRecovery__request_WAeG .MilesRecovery__flight-date_1Zv6,
    .MilesRecovery__request_WAeG .MilesRecovery__flight-number_Z4Ly,
    .MilesRecovery__request_WAeG .MilesRecovery__number_vtow {
      text-align: right;
  }


    .MilesRecovery__request_WAeG .MilesRecovery__number_vtow {
      width: 100px;
      margin-bottom: 20px;
  }

    .MilesRecovery__request_WAeG .MilesRecovery__partner-name_QWFC,
    .MilesRecovery__request_WAeG .MilesRecovery__type-description_3mTv {
      width: 100%;
  }

    .MilesRecovery__request_WAeG .MilesRecovery__flight-date_1Zv6 {
      width: 80px;
  }

    .MilesRecovery__request_WAeG .MilesRecovery__status_1yDk {
      margin-right: auto;
      margin-bottom: 16px;
      margin-left: 0;
  }

    .MilesRecovery__request_WAeG .MilesRecovery__partner-name_QWFC,
    .MilesRecovery__request_WAeG .MilesRecovery__flight-number_Z4Ly {
      margin-bottom: 8px;
  }
}

@media (max-width: 767px) {
  .MilesRecovery__new-button_BNB4 {
    width: 100%;
  }
}

@media (max-width: 359px) {
    .MilesRecovery__request_left_3HR6 {
      max-width: 185px;
  }
}
