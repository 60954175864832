.MilesBuy__root_2EqB {
  position: relative;
  margin: 0 0 39px;
  background-color: #fff;
}

  @media (max-width: 767px) {.MilesBuy__root_2EqB {
    position: fixed;
    top: 56px;
    left: 0;
    z-index: 310;
    width: 100%;
    height: 100%;
    padding: 16px 16px 166px;
    margin: 0;
    overflow: auto;
  }
}

.MilesBuy__title_3wNK {
  display: none;
}

@media (max-width: 767px) {

.MilesBuy__title_3wNK {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 311;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    min-height: 56px;
    padding: 0 16px;
    overflow-y: hidden;
    background-color: #fff;
    border-bottom: solid 1px #e8e8e8;
  }
}

.MilesBuy__title-icon_1pfY {
    margin-left: auto;
    cursor: pointer;
}

.MilesBuy__notification_2rE2 {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin: 6px 0 24px;
}

@media (max-width: 767px) {

.MilesBuy__notification_2rE2 {
    margin-top: 0;
  }
}

.MilesBuy__condition_2ePj {
  display: block;
  margin: 0 0 40px;
}

.MilesBuy__fields_pUOf {
  display: block;
  width: 100%;
  margin: 0 0 17px;
}

.MilesBuy__card-payment_2OHU {
  display: block;
  margin: 7px 0 17px;
}

@media (max-width: 767px) {

.MilesBuy__card-payment_2OHU {
    margin: 3px 0 0;
  }
}

.MilesBuy__overlay_1O3p {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 398;
  background-color: rgba(153, 153, 153, .4);
}

.MilesBuy__m-select_nky6 {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 58px;
  border: solid 1px #75787b;
  border-radius: 4px;
}

/* ------ other ------ */

@media (max-width: 767px) {

.MilesBuy__desktop_1857 {
    display: none;
  }
}

@media (min-width: 767px) {

.MilesBuy__mobile_35un {
    display: none;
  }
}
