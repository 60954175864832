.SettingsBlock__title_separator_39rD {
  width: calc(100% - 64px);
  height: 1px;
  margin: 0 0 0 32px;
  background-color: #dde3ed;
  border-width: 0;
}

.SettingsBlock__child_3p-r {
  padding: 24px 0 0;
}

.SettingsBlock__child_content_3Fsm {
    padding-bottom: 24px;
}

.SettingsBlock__child_3p-r:not(:last-child) .SettingsBlock__child_content_3Fsm {
    border-bottom: 1px solid #dde3ed;
}

.SettingsBlock__mobile_view_R3Ll {
  margin-top: 16px;
}

.SettingsBlock__mobile_title_1_GH {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  padding: 18px 16px;
  background: #ffffff;
  border-bottom: 1px solid #dde3ed;
}

.SettingsBlock__mobile_title_button_KU1m {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: inherit !important;
    cursor: pointer;
}

.SettingsBlock__back_3lFE {
  margin-right: 12px;
  color: inherit !important;
}

.SettingsBlock__notification_32B3 {
  padding: 0 32px;
}

@media (max-width: 1279px) {
  .SettingsBlock__mobile_view_R3Ll > :not(:last-child) {
    border-bottom: 1px solid #dde3ed;
  }

  .SettingsBlock__content_mobile_view_2iIT .SettingsBlock__childs_2Xay {
    position: fixed;
    top: 60px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 998;
    padding-bottom: 16px;
    overflow: auto;
    background-color: #ffffff;
  }

  .SettingsBlock__title_separator_39rD {
    width: calc(100% - 32px);
    margin: 0 0 0 16px;
  }

  .SettingsBlock__notification_32B3 {
    padding: 16px 16px 0;
  }

  .SettingsBlock__child_3p-r {
    padding: 16px 16px 0;
  }

    .SettingsBlock__child_content_3Fsm {
      padding-bottom: 16px;
  }
}