.Order__root_3PnP {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 24px;
  border: 1px solid #dde3ed;
  border-radius: 8px;
}

  @media (max-width: 767px) {.Order__root_3PnP {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding: 16px;
  }
}

.Order__header_1_cY {
  margin-bottom: 16px;
}

.Order__body_3tUi {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.Order__status_cancelled_6qR4,
  .Order__status_finished_294r,
  .Order__status_rejected_Ycz8 {
    background-color: #f6f8fc;
}

.Order__block_2osd {
  width: 100%;
  margin-bottom: 24px;
}

.Order__information_3dMe {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.Order__title_3oxq {
  margin-bottom: 8px;
}

@media (max-width: 767px) {

.Order__title_3oxq {
    max-width: 240px;
  }
}

.Order__details_1kd5 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  max-width: 160px;
}

.Order__price_18GD {
  width: auto;
  margin-bottom: 8px;
}

@media (max-width: 767px) {

.Order__price_18GD {
    margin-bottom: 0;
  }
}

.Order__status-bar_3y1j {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  width: 100%;
  padding-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {

.Order__status-bar_3y1j {
    padding-top: 24px;
  }
}

@media (max-width: 767px) {

.Order__footer_2CBB {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
}

.Order__footer__buttons_23-3 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.Order__footer__pay-button_3LH8 {
    min-width: 140px;
}

@media (min-width: 768px) {

.Order__footer__pay-button_3LH8 {
      min-width: 200px;
  }
}

.Order__notification_36T_ {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Order__form_2OZj {
  padding-top: 40px;
}

@media (max-width: 767px) {

.Order__form_2OZj {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    padding-top: 0;
    background-color: #ffffff;
  }
}

.Order__form-header_3mUh {
  display: none;
}

@media (max-width: 767px) {

.Order__form-header_3mUh {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 18px 16px;
    border-bottom: 1px solid #dde3ed;
  }
}

@media (max-width: 767px) {

.Order__form-back_1_li {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    justify-self: center;
    padding: 0;
    margin-right: 12px;
    background-color: transparent;
    border: 0;
    outline: none;
  }
}

@media (max-width: 767px) {

.Order__form-content_xDRu {
    padding: 16px;
  }
}

.Order__hidden_DTIC {
  display: none;
}