.BuyTicket__root_2qZi {
  max-width: 400px;
  padding-top: 60px;
  margin: 0 auto;
  color: #222a37;
  text-align: center;
}

.BuyTicket__title_3KKK {
  margin-top: 8px;
}

.BuyTicket__subtitle_1yX2 {
  margin-top: 12px;
}

.BuyTicket__button_3AGE {
  margin-top: 24px;
}

.BuyTicket__isMobile_NgRK {
  max-width: 240px;
  padding-top: 40px;
  padding-bottom: 16px;
}

.BuyTicket__isMobile_NgRK .BuyTicket__subtitle_1yX2 {
    margin-top: 8px;
}

.BuyTicket__isMobile_NgRK .BuyTicket__button_3AGE {
    margin: 20px -24px 0;
}