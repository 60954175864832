.Transportation__slider_191X {
  margin-right: -32px;
}

  @media (max-width: 1280px) {.Transportation__slider_191X {
    margin-right: -16px;
  }
}

.Transportation__slide_3Unh {
  margin-right: 12px;
  margin-left: 0;
}

.Transportation__modal_image_container_2t9U {
  margin-bottom: 104px;
  text-align: center;
}

@media (max-width: 767px) {

.Transportation__modal_image_container_2t9U {
    margin-bottom: 64px;
  }
}

.Transportation__modal_image_KkcJ {
  max-width: 100%;
  height: auto;
}

.Transportation__modal_divider_39ek {
  display: none;
}

.Transportation__modal_content_3pOO {
  max-height: 647px;
}

.Transportation__list_Trgy {
  padding-top: 8px;
}

.Transportation__list_item_1721 {
  position: relative;
  padding: 8px 16px;
}

.Transportation__list_item_1721::before {
    position: absolute;
    top: 15px;
    left: 0;
    width: 6px;
    height: 6px;
    content: "";
    background-color: #97ba1e;
    border-radius: 50%;
}