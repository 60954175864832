.TripsFilter__filters_3xle {
  position: relative;
  z-index: 5;
  display: -webkit-flex;
  display: flex;
}

.TripsFilter__filter_26Vj + .TripsFilter__filter_26Vj {
  margin-left: 8px;
}

.TripsFilter__filter-button_3etp {
  width: 100%;
}

.TripsFilter__passenger-icon_FBC5 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 40px;
  height: 40px;
  color: #ffffff;
  background-color: #cad0d9;
  border-radius: 50%;
}

.TripsFilter__mobile-row_3kHv {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 18px 1px;
  border-bottom: 1px solid #dde3ed;
  outline: none;
}

.TripsFilter__mobile-row_3kHv:first-of-type {
  margin-top: -24px;
}

.TripsFilter__left_2qkc {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  padding-right: 16px;
}

.TripsFilter__right_Ic5N {
  max-width: 100%;
  overflow: hidden;
}

.TripsFilter__not-found_24m_ {
  max-width: 360px;
  margin: 80px auto 0;
}

@media (max-width: 767px) {

.TripsFilter__not-found_24m_ {
    max-width: 100%;
  }
}

.TripsFilter__not-found-icon_2Xxb {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-bottom: 24px;
}

@media (max-width: 767px) {

.TripsFilter__not-found-icon_2Xxb {
    margin-bottom: 12px;
  }
}