.Price__root_3VN3 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  padding: 10px 12px;
  background-color: #ffffff;
  border-radius: 10px;
}

  @media (max-width: 767px) {.Price__root_3VN3 {
    -webkit-align-items: flex-end;
            align-items: flex-end;
    padding: 0;
    background: none;
  }
}

.Price__title_22BV {
  width: auto;
  white-space: nowrap;
}

.Price__duration_b5vB {
  display: block;
  margin-top: -4px;
  white-space: nowrap;
  opacity: 0.8;
}