.Certificates__root_3OPf {
  margin-top: 100px;
}

  @media (max-width: 767px) {.Certificates__root_3OPf {
    margin-top: 60px;
  }
}

.Certificates__description_1Hej {
  max-width: 492px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 767px) {

.Certificates__description_1Hej {
    max-width: 100%;
  }
}

.Certificates__list_1nc1 {
  margin-top: 40px;
}

@media (max-width: 767px) {

.Certificates__list_1nc1 {
    margin-top: 20px;
  }
}