.Settings__root_15VC {
  padding-top: 60px;
  background-color: #f1f3f8;
}

.Settings__group_31dz {
  padding-bottom: 32px;
}

.Settings__logout_1dMg {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: #222a37;
}

.Settings__logout_icon_wgmY {
    margin-right: 12px;
}

@media (max-width: 1279px) {
  .Settings__root_15VC {
    padding: 20px 8px 0;
  }

  .Settings__group_31dz {
    padding-bottom: 16px;
  }
}

