.SetPassword__root_3Fy2 .SetPassword__loader_3O_B {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    min-height: 360px;
}

    @media (max-width: 767px) {.SetPassword__root_3Fy2 .SetPassword__loader_3O_B {
      min-height: 100%;
  }
}
  .SetPassword__root_3Fy2 .SetPassword__info_27xP {
    margin-top: 12px;
}
  .SetPassword__root_3Fy2 .SetPassword__notification_tWsr {
    display: block;
    margin-top: 24px;
    margin-bottom: 24px;
}
  .SetPassword__root_3Fy2 .SetPassword__actions_2PP5 {
    display: block;
    margin-top: auto;
}
  .SetPassword__root_3Fy2 .SetPassword__action_3KwW {
    display: block;
    margin-top: 8px;
}
  .SetPassword__root_3Fy2 .SetPassword__action_3KwW:first-child {
      margin-top: 32px;
}
  .SetPassword__root_3Fy2 .SetPassword__fields_149H {
    display: block;
    margin-top: 32px;
}
  .SetPassword__root_3Fy2 .SetPassword__field_1tBQ {
    display: block;
    margin-top: 24px;
}
  .SetPassword__root_3Fy2 .SetPassword__popover_1Z12 {
    position: absolute;
    top: 0;
    left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
}
  .SetPassword__root_3Fy2 .SetPassword__icon_2FO3 {
    margin-right: 8px;
}
  .SetPassword__root_3Fy2 .SetPassword__success_3WuW {
    display: -webkit-flex;
    display: flex;
    padding: 16px;
    background-color: rgba(29, 33, 41, 0.6);
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(34, 42, 55, 0.13);
}