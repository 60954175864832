.Order__root_3v-6 {
  z-index: 4;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 20px 24px;
  background-color: #ffffff;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}

  @media (max-width: 767px) {.Order__root_3v-6 {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    border-radius: 16px;
  }
}

@media (max-width: 767px) {

.Order__right_CdRh {
    width: 100%;
    margin-top: 16px;
  }
}

@media (max-width: 767px) {

.Order__subtitle_wFoS {
    margin-top: 4px;
  }
}