
.MilesConfirmDialog__root_2XQl {
  position: relative;
  display: block;
  width: 414px;
  min-height: 322px;
  padding: 23px;
  background-color: #F5F5F5;
  border: 1px solid #E0E0E0;
  border-radius: 12px;
}

  @media (max-width: 767px) {
.MilesConfirmDialog__root_2XQl {
    width: auto;
    min-height: auto;
  }
}

.MilesConfirmDialog__title_Cyp5 {
  display: -webkit-flex;
  display: flex;
  margin: 3px 0 0;
}

.MilesConfirmDialog__description_1Me0 {
  display: -webkit-flex;
  display: flex;
  margin: 10px 0 0;
}

.MilesConfirmDialog__field_3tOO {
  display: block;
  padding: 0;
  margin: 22px 0 0;
}

.MilesConfirmDialog__timer_3ZW5 {
  display: -webkit-flex;
  display: flex;
  margin: 39px 0 0;
}

@media (max-width: 767px) {

.MilesConfirmDialog__timer_3ZW5 {
    margin-top: 27px;
  }
}

.MilesConfirmDialog__timer-icon_2usu {
    margin-right: 8px;
}

.MilesConfirmDialog__overlay_2ujO {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 398;
  display: block;
  background-color: rgba(118,118,118,0.87);
  border-radius: 12px;
}

.MilesConfirmDialog__loader_1Spb {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 399;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.MilesConfirmDialog__exit_2qor {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}

@media (max-width: 767px) {

.MilesConfirmDialog__exit_2qor {
    position: static;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 20px;
    margin: 16px 0 0;
  }
}

.MilesConfirmDialog__exit-text_37qw {
    margin-left: 8px;
    cursor: pointer;
}

.MilesConfirmDialog__root_2XQl:before,
.MilesConfirmDialog__overlay_2ujO:before {
  position: absolute;
  top: -5px;
  left: 80px;
  width: 8px;
  height: 8px;
  content: '';
  background-color: #F5F5F5;
  border-top: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

@media (max-width: 767px) {

.MilesConfirmDialog__root_2XQl:before,
.MilesConfirmDialog__overlay_2ujO:before {
    left: calc(50% - 4px);
  }
}

.MilesConfirmDialog__overlay_2ujO:before {
  top: -4px;
  left: 25px;
  z-index: 390;
  background-color: transparent;
  border: none;
}

.MilesConfirmDialog__confirm-button_c6rb,
.MilesConfirmDialog__request-button_unt9 {
  display: -webkit-flex;
  display: flex;
  width: 160px;
  height: 48px;
  margin: 17px 0 0;
}

@media (max-width: 767px) {

.MilesConfirmDialog__confirm-button_c6rb,
.MilesConfirmDialog__request-button_unt9 {
    width: auto;
  }
}

/* ------ other ------ */

@media (max-width: 767px) {

.MilesConfirmDialog__desktop_3gVc {
    display: none;
  }
}

@media (min-width: 767px) {

.MilesConfirmDialog__mobile_17gn {
    display: none;
  }
}
