.ContactBlock__root_xqdH {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 100%;
}

  .ContactBlock__root_xqdH:not(:last-child) {
    margin-right: 24px;
}

.ContactBlock__action_1U_d {
  margin-top: 16px;
}

.ContactBlock__tag_1Q_y {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  padding: 4px 12px;
  margin-bottom: 16px;
  background-color: #fff4e9;
  border-radius: 14px;
}

.ContactBlock__tag_1Q_y svg {
    margin-right: 6px;
    color: #ff991f;
}

@media (max-width: 767px) {
    .ContactBlock__root_xqdH:not(:last-child) {
      margin-right: 0;
      margin-bottom: 24px;
  }

  .ContactBlock__tag_1Q_y {
    margin-bottom: 8px;
  }
}