

.ProgressBar__overlay_3job {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 320;
  display: none;
  background-color: rgba(255, 255, 255, .4);
}

  @media (max-width: 767px) {

.ProgressBar__overlay_3job {
    position: fixed;
    width: 100%;
    height: 100%;
  }
}

.ProgressBar__loader_2Rls {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 321;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.ProgressBar__visible_SevL {
  display: block;
}
