.PriorityCardWithQRAndBarCode__root_41qp {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  background-color: #ffffff;
}

  @media (max-width: 1280px) {.PriorityCardWithQRAndBarCode__root_41qp {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    min-height: 100%;
  }
}


.PriorityCardWithQRAndBarCode__title_3998 {
  margin: 4px 0 24px;
}


.PriorityCardWithQRAndBarCode__notification_34CY {
  width: 100%;
  margin-bottom: 32px;
}


.PriorityCardWithQRAndBarCode__container_2zdE {
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-align-items: center;
          align-items: center;
  width: 208px;
  padding: 24px 0;
  border: solid 1px #dde3ed;
  border-radius: 12px;
}


.PriorityCardWithQRAndBarCode__barcode_2Hs5 {
  width: 160px;
  margin-bottom: 24px;
}


.PriorityCardWithQRAndBarCode__priority-card_1-Ht {
  margin-bottom: 24px;
}


.PriorityCardWithQRAndBarCode__button_Mdg- {
  width: 264px;
  min-height: 38px;
}


.PriorityCardWithQRAndBarCode__button_Mdg-:nth-child(2) {
    margin-top: 8px;
}


.PriorityCardWithQRAndBarCode__action_3a38 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  padding-bottom: 16px;
}


@media (max-width: 1280px) {


.PriorityCardWithQRAndBarCode__action_3a38 {
    padding-top: 16px !important;
  }
}


@media (max-width: 1280px) {
  .PriorityCardWithQRAndBarCode__notification_34CY {
    margin-bottom: 16px;
  }
}


@media (max-width: 767px) {

  .PriorityCardWithQRAndBarCode__qr-code_rCK1 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    min-width: 176px;
    min-height: 176px;
  }
}


@media print {
  .PriorityCardWithQRAndBarCode__root_41qp {
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    height: 100%;
  }

  .PriorityCardWithQRAndBarCode__container_2zdE {
    border-color: #000;
  }

  .PriorityCardWithQRAndBarCode__notification_34CY {
    display: none;
  }
}

