.Custom404__notfound_2C0o {
  position: relative;
  display: block;
  width: 100%;
  height: 640px;
  background-image: url('https://static.s7cdn.online/front-static/media/not-found-bg.952933bd9516b6629fb988246ae2d97f.jpg');
  background-repeat: no-repeat;
  background-position: 60% 50%;
  background-size: cover;
}

.Custom404__notfound_2C0o:before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  background-color: rgba(0, 0, 0, 0.2);
}

.Custom404__notfound__inner_KQ-H {
  position: relative;
  padding: 120px 0 0 0;
  color: #fff;
}

.Custom404__notfound__title_-VHG {
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
}

.Custom404__notfound__subtitle_zyVS {
  font-size: 14px;
  line-height: 20px;
}

.Custom404__notfound__searchform_1o9y {
  margin-top: 75px;
}

.Custom404__notfound__searchform-form_ZAOq {
  position: relative;
  margin-bottom: 40px;
}

.Custom404__notfound__searchform-icon_3qez {
  position: absolute;
  top: 17px;
  right: 17px;
  z-index: 2;
  background: none;
  border: none;
}

.Custom404__notfound__searchform-icon_3qez svg {
  cursor: pointer;
}

.Custom404__button-wrap_2lA2 {
  -webkit-flex-basis: 50%;
          flex-basis: 50%;
}

.Custom404__button-wrap_2lA2:last-child {
    margin-left: 12px;
}

@media (max-width: 767px) {

.Custom404__button-wrap_2lA2:last-child {
      margin-top: 16px;
      margin-left: 0;
  }
}

.Custom404__page-container_BEKQ {
  padding: 28px 16px 48px;
}

@media (max-width: 767px) {

.Custom404__page-container_BEKQ {
    padding-top: 0;
  }
}

.Custom404__row_3gKK {
  display: -webkit-flex;
  display: flex;
}

@media (max-width: 767px) {

.Custom404__row_3gKK {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

@media (min-width: 768px) {
  .Custom404__page-container_BEKQ {
    width: 720px;
    padding-right: 0;
    padding-left: 0;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .Custom404__notfound_2C0o {
    height: 540px;
    background-position: 65% 50%;
  }

  .Custom404__notfound__inner_KQ-H {
    padding: 30px 12px 0 12px;
  }

  .Custom404__notfound__searchform-form_ZAOq {
    margin-bottom: 40px;
  }
}

@keyframes Custom404__autofill_DHx1 {
  100% {
    color: inherit;
    background: transparent;
  }
}

@-webkit-keyframes Custom404__autofill_DHx1 {
  100% {
    color: inherit;
    background: transparent;
  }
}



