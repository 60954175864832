.MilesRecoveryRulesModal__root_1p3M {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  max-width: 600px;
}

.MilesRecoveryRulesModal__container_3JU0 {
  position: relative;
  display: block;
  width: 600px;
  max-width: 600px !important;
  outline: none;
}

.MilesRecoveryRulesModal__content_1ecb {
  padding-right: 10px;
  padding-left: 10px;
}

.MilesRecoveryRulesModal__content_1ecb b {
    font-weight: 600;
}

.MilesRecoveryRulesModal__content_1ecb a {
    font-weight: 600;
    color: #97ba1e;
}

.MilesRecoveryRulesModal__content_1ecb p {
    font-size: 16px;
    line-height: 24px;
    color: #222a37;
}

.MilesRecoveryRulesModal__content_1ecb p:first-child {
      margin-top: 0;
}

.MilesRecoveryRulesModal__content_1ecb p:not(:last-child) {
      margin: 0 0 8px;
}

@media (max-width: 767px) {
  .MilesRecoveryRulesModal__container_3JU0 {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    max-width: 100% !important;
  }

  .MilesRecoveryRulesModal__content_1ecb {
    box-sizing: border-box;
    margin-right: -10px;
    margin-left: -10px;
  }

    .MilesRecoveryRulesModal__content_1ecb p {
      font-size: 14px;
      line-height: 20px;
  }
}
