.B2bAccess__root_394L {
  display: block;
}

.B2bAccess__access_2rSV {
  display: -webkit-flex;
  display: flex;
}

@media (min-width: 768px) {

.B2bAccess__access_2rSV {
    margin-bottom: 18px;
  }
}

.B2bAccess__switcher_S0k- {
  margin-right: 8px;
}

@media (min-width: 768px) {

.B2bAccess__switcher_S0k- {
    padding-top: 2px;
  }
}

.B2bAccess__b2b_info_7rrl {
  margin-top: 18px;
}

@media (max-width: 767px) {

.B2bAccess__b2b_info_7rrl {
    display: none;
  }
}