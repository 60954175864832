.MilesExpiration__root_YLWC {
  position: relative;
  margin: 0 0 39px;
  background-color: #fff;
}

  @media (max-width: 767px) {.MilesExpiration__root_YLWC {
    position: fixed;
    top: 56px;
    left: 0;
    z-index: 310;
    width: 100%;
    height: 100%;
    padding: 16px 16px 166px;
    margin: 0;
    overflow: auto;
  }
}

.MilesExpiration__title_3tQH {
  display: none;
}

@media (max-width: 767px) {

.MilesExpiration__title_3tQH {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 311;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    min-height: 56px;
    padding: 0 16px;
    overflow-y: hidden;
    background-color: #fff;
    border-bottom: solid 1px #e8e8e8;
  }
}

.MilesExpiration__title-icon_1eCB {
    margin-left: auto;
    cursor: pointer;
}

.MilesExpiration__notification_3MZL {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin: 6px 0 24px;
}

@media (max-width: 767px) {

.MilesExpiration__notification_3MZL {
    margin-top: 0;
  }
}

.MilesExpiration__condition_37K8 {
  display: block;
  margin: 0 0 40px;
}

.MilesExpiration__fields_24hJ {
  display: block;
  width: 100%;
  margin: 0 0 17px;
}

.MilesExpiration__card-payment_3yOf {
  display: block;
  margin: 7px 0 17px;
}

@media (max-width: 767px) {

.MilesExpiration__card-payment_3yOf {
    margin: 3px 0 0;
  }
}

.MilesExpiration__info_2Xvy {
  display: block;
  margin: 0 0 40px;
}

.MilesExpiration__info_2Xvy p:not(:last-child) {
    margin: 0 0 12px;
}

.MilesExpiration__info_2Xvy b {
    font-weight: 600;
}

.MilesExpiration__overlay_vU-m {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 398;
  background-color: rgba(153, 153, 153, .4);
}

.MilesExpiration__m-select_1CCu {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 58px;
  border: solid 1px #75787b;
  border-radius: 4px;
}

/* ------ other ------ */

@media (max-width: 767px) {

.MilesExpiration__desktop_3JrP {
    display: none;
  }
}

@media (min-width: 767px) {

.MilesExpiration__mobile_i7zG {
    display: none;
  }
}
