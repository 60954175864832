@media (max-width: 767px) {.TransactionsFilter__root_BLP6 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
}

.TransactionsFilter__filter_2k9J {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.TransactionsFilter__types_D_bq {
  position: relative;
}

@media (max-width: 767px) {

.TransactionsFilter__types_D_bq {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
    padding: 4px;
    background-color: #eceff5;
    border-radius: 8px;
  }

    .TransactionsFilter__types_D_bq::after,
    .TransactionsFilter__types_D_bq::before {
      position: absolute;
      top: 10px;
      bottom: 10px;
      z-index: 1;
      display: block;
      width: 1px;
      content: '';
      background-color: #d0d6e1;
  }

    .TransactionsFilter__types_D_bq::after {
      left: 33.3%;
      display: none;
  }

    .TransactionsFilter__types_D_bq::before {
      right: 33.3%;
  }

    .TransactionsFilter__types_in_1CNa::before,
    .TransactionsFilter__types_out_3fPL::before {
      display: none;
  }

    .TransactionsFilter__types_out_3fPL::after {
      display: block;
  }

    .TransactionsFilter__types_D_bq > button {
      width: 100%;
      padding-right: 4px;
      padding-left: 4px;
  }

      .TransactionsFilter__types_D_bq > button > span {
        display: inline-block;
        overflow: hidden;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        text-overflow: ellipsis;
        letter-spacing: 0.02em;
        white-space: nowrap;
  }
}

.TransactionsFilter__right_2FWd {
  z-index: 2;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-bottom: -8px;
}

@media (max-width: 767px) {

.TransactionsFilter__right_2FWd {
    display: none;
  }
}

.TransactionsFilter__type_2xce:disabled, .TransactionsFilter__select_3Qqv:disabled {
    color: inherit;
    background-color: #eceff5;
    border-color: #eceff5;
}

.TransactionsFilter__type_2xce {
  border-radius: 16px;
}

.TransactionsFilter__type_2xce:not(:last-child) {
    margin-right: 8px;
}

@media (max-width: 767px) {

.TransactionsFilter__type_2xce {
    width: 33.3%;
    border-radius: 6px;
  }

    .TransactionsFilter__type_2xce:not(:last-child) {
      margin-right: 0;
  }

    .TransactionsFilter__type_2xce:disabled {
      background-color: #ffffff;
  }

    .TransactionsFilter__type_2xce:not(:disabled) {
      color: #505f79;
      background-color: transparent;
      border-color: transparent;
  }
}

.TransactionsFilter__select_3Qqv {
  margin-bottom: 8px;
}

@media (max-width: 767px) {

.TransactionsFilter__select_3Qqv {
    height: 36px;
    padding: 0 16px;
    margin-top: 8px;
    margin-right: 6px;
    font-size: 16px;
    line-height: 24px;
    border-radius: 18px;
  }
}

.TransactionsFilter__select_3Qqv.TransactionsFilter__active_2y6w {
    background-color: #f6f9ea;
    border-color: #f6f9ea;
}

@media (max-width: 767px) {

.TransactionsFilter__select_3Qqv.TransactionsFilter__active_2y6w {
      color: #ffffff;
      background-color: #97ba1e;
      border-color: #97ba1e;
  }
}

.TransactionsFilter__select_3Qqv.TransactionsFilter__open_3kvY .TransactionsFilter__icon_2C2e {
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
}

.TransactionsFilter__icon_2C2e {
  margin-left: 8px;
}

.TransactionsFilter__icon_2C2e svg {
    display: block;
}

.TransactionsFilter__date_N3o_ {
  position: relative;
}

.TransactionsFilter__date_N3o_ button {
    border-radius: 8px;
}

.TransactionsFilter__popup_zJ4D,
.TransactionsFilter__categorylist_n_AG {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  margin-top: 8px;
}

@media (max-width: 767px) {

.TransactionsFilter__popup_zJ4D {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 995;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-top: 0;
    background: rgba(34, 42, 55, 0.6);
  }

    .TransactionsFilter__popup_zJ4D > * {
      -webkit-justify-content: center;
              justify-content: center;
      width: 100%;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
  }
}

.TransactionsFilter__category_1eF6 {
  margin-left: 8px;
}

@media (max-width: 767px) {

.TransactionsFilter__category_1eF6 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    width: calc(100% + 32px);
    padding: 14px 16px;
    margin: 0 -16px;
    outline: none;
  }
}

.TransactionsFilter__categorylist_n_AG {
  padding: 24px 20px;
  background-color: #ffffff;
  border: 1px solid #d0d6e1;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 8px 16px -4px rgba(29, 33, 41, 0.13);
}

@media (max-width: 767px) {

.TransactionsFilter__categorylist_n_AG {
    position: relative;
    top: 0;
    left: 0;
    padding: 0;
    margin-top: 12px;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
}

.TransactionsFilter__categoryItem_CieT:not(:last-child) {
    margin-bottom: 26px;
}

@media (max-width: 767px) {
    .TransactionsFilter__categoryItem_CieT:not(:last-child) {
      margin-bottom: 0;
      border-bottom: 1px solid #dde3ed;
  }

    .TransactionsFilter__categoryItem_CieT .TransactionsFilter__categoryIcon_2evB {
      width: 28px;
      height: 28px;
      margin-right: 8px;
  }
}

.TransactionsFilter__overlay_VeLi {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: none;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: #ffffff;
}

@media (max-width: 767px) {

.TransactionsFilter__overlay_VeLi {
    z-index: 998;
    display: block;
  }
}

.TransactionsFilter__header_3-iT {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 52px;
  padding: 0 16px;
  background-color: #ffffff;
  border-bottom: 1px solid #dde3ed;
}

.TransactionsFilter__close_7zuK {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: inherit;
}

.TransactionsFilter__close_7zuK svg {
    margin-right: 8px;
}

.TransactionsFilter__footer_YVjt {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  padding: 16px;
}

.TransactionsFilter__body_3Av9 {
  padding: 76px 16px 80px;
}

.TransactionsFilter__categoryCheck_1KXN {
  margin-left: auto;
}

.TransactionsFilter__group_6-Od:not(:first-child) {
  margin-top: 40px;
}

@media (max-width: 767px) {

.TransactionsFilter__diagram_3c7w {
    margin: 0 -16px;
  }
}

.TransactionsFilter__mobileOutside_1sfY {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
