.Preview__input_RVIU {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0
}

.Preview__modal_RtWs {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%
}

.Preview__qr_1GQB {
  padding: 26px;
  margin-bottom: 24px;
  border: 1px solid #dde3ed;
  border-radius: 12px
}

.Preview__button_35sc + .Preview__button_35sc {
  margin-top: 12px
}