@media (max-width: 767px) {.Trip__root_2ceg {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    background-color: #f1f3f8;
  }
}

@media (max-width: 767px) {

.Trip__back_3oqb {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 18px 16px;
    background-color: #ffffff;
  }
}

.Trip__back-link_2cTt {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  max-width: 100%;
  color: #505f79;
}

.Trip__back-icon_bSB3 {
  padding: 4px;
  margin-right: 8px;
  background-color: #ffffff;
  border-radius: 50%;
}

.Trip__back-icon_bSB3 svg {
    display: block;
}

@media (max-width: 767px) {

.Trip__back-icon_bSB3 {
    padding: 0;
    margin-right: 12px;
    background-color: transparent;
    border-radius: 0;
  }
}

.Trip__content_3ueu {
  margin-top: 40px;
}

@media (max-width: 767px) {

.Trip__content_3ueu {
    margin-top: 0;
    overflow-y: auto;
  }
}

@media (max-width: 767px) {

.Trip__inner_3Gaf:not(.Trip__inner-archive_1DC7) {
    padding: 0 8px;
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
  }
}

.Trip__modal-overlay_EQMz {
  /* Проблемы с порядком стилей */
  z-index: 301 !important;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  background-color: #f1f3f8;
}

.Trip__modal-content_1W2q {
  width: 100% !important;
  height: 100%;
}

.Trip__group_2MU3 {
  margin-top: 4px;
}

@media (max-width: 767px) {

.Trip__group_2MU3 {
    margin-top: 16px;
  }
}

.Trip__inner-archive_1DC7 {
  padding: 0 24px 36px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}

@media (min-width: 768px) {

.Trip__inner-archive_1DC7 {
    background-color: #ffffff;
  }
}

@media (max-width: 767px) {

.Trip__inner-archive_1DC7 {
    padding-right: 8px;
    padding-left: 8px;
  }
}

@media (min-width: 768px) {

.Trip__group-archive_1drs {
    padding-top: 20px;
  }

    .Trip__group-archive_1drs:not(:last-child) {
      padding-bottom: 20px;
  }

    .Trip__group-archive_1drs + .Trip__group-archive_1drs {
      border-top: 1px solid #dde3ed;
  }
}

@media (max-width: 767px) {

.Trip__group-archive_1drs {
    margin-top: 16px;
  }
}

.Trip__group-archive-inner_1q8b {
  padding: 16px;
}

.Trip__message_2AhQ {
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #dde3ed;
}

@media (max-width: 767px) {

.Trip__message_2AhQ {
    padding: 8px;
    margin-right: -8px;
    margin-left: -8px;
    background-color: #ffffff;
    border-bottom: 0;
  }
}

.Trip__message_link_1ivM {
  display: block;
  width: 100%;
  margin-top: 8px;
}
