.Loader__Loader_2kpc {
  display: block;
  overflow: hidden;
  border-radius: 6px
}

.Loader__box_2Ayq {
  position: relative;
  display: block;
  width: 1000px;
  height: 1000px
}
