.Loader__stub_JYsL {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255, 0.8);
}

.Loader__spinner_3rhH {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 32px;
  pointer-events: none;
}

.Loader__spinner_alignTop_EzDb {
    position: absolute;
    top: 200px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
