.MainLayout__root_3UFe {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
}

.MainLayout__header_1ur7 {
  z-index: 202;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.MainLayout__container_8GKT {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.MainLayout__breadcrumbs_white_TGjX {
    background-color: #ffffff;
}

@media (min-width: 768px) {

.MainLayout__breadcrumbs_nM0d {
    padding: 0 24px;
  }
}

.MainLayout__background_1Lhr {
  position: relative;
  display: block;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.MainLayout__background_green_1m9s {
    background-color: #e1f0e5;
}

.MainLayout__background_G50_PrEb {
    background-color: #f4f9e6;
}

.MainLayout__background_N30_3bv2 {
    background-color: #f1f3f8;
}

.MainLayout__content_2dGR {
  position: relative;
}

.MainLayout__priority-image_2DCl {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  width: 600px;
  height: 400px;
  background-size: cover;
}

@media (max-width: 1023px) {

.MainLayout__priority-image_2DCl {
    display: none;
  }
}

@media (max-width: 1439px) {

.MainLayout__priority-image-big-breakpoint_2bKY {
      display: none;
  }
}

.MainLayout__header_space_3lN2 {
  width: 100%;
  background-color: #f1f3f8;
  box-shadow: inset 0px 0px 4px 0px rgba(34, 42, 55, 0.04);
}

.MainLayout__header_space_media_2U6p {
    background-color: #a1bfa5;
    box-shadow: unset;
}

.MainLayout__header_space_login_130J {
    background-color: transparent;
}
