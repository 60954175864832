.ProfileMiles__profileMiles_Lalm {
  display: block;
  min-height: calc(100vh - 638px);
  padding-bottom: 120px;
  background-color: #f1f3f8;
}

  @media (max-width: 767px) {.ProfileMiles__profileMiles_Lalm {
    padding-bottom: 55px;
    background-color: #f5f5f5;
  }
}

@media (max-width: 1023px) {

.ProfileMiles__container_3jjc {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.ProfileMiles__group_2B7a {
  position: relative;
  display: block;
  padding-top: 32px;
}

@media (max-width: 767px) {

.ProfileMiles__group_2B7a {
    padding-top: 16px;
  }

    .ProfileMiles__group_2B7a:last-child {
      padding-top: 24px;
  }
}
