.SetPasswordWrapper__root_1gCF {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: calc(100vh - 80px);
  padding-top: 82px;
  padding-bottom: 162px;
}

  @media (max-width: 767px) {.SetPasswordWrapper__root_1gCF {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: stretch;
            align-items: stretch;
    min-height: calc(100vh - 52px);
    padding: 20px 16px;
    background-color: #ffffff;
  }
}

  .SetPasswordWrapper__root_1gCF .SetPasswordWrapper__box_2zCB {
    position: relative;
    box-sizing: border-box;
    display: block;
    width: 360px;
    padding: 32px;
    overflow: hidden;
}

