.PetItem__root_H7pa {
  position: relative;
  padding-right: 32px;
  padding-left: 32px;
  margin-right: -32px;
  margin-left: -32px;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out;
}

  @media (max-width: 1280px) {.PetItem__root_H7pa {
    padding-right: 16px;
    padding-left: 16px;
    margin-right: -16px;
    margin-left: -16px;
  }
}

  .PetItem__root_H7pa:hover {
    background-color: #f6f8fc;
}

.PetItem__container_3pAb {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 24px;
  padding-bottom: 23px;
}

@media (max-width: 1280px) {

.PetItem__container_3pAb {
    padding-top: 16px;
    padding-bottom: 15px;
  }
}

.PetItem__root_H7pa:not(:last-child) .PetItem__container_3pAb {
    border-bottom: 1px solid #eceff5;
}

.PetItem__root_H7pa:hover .PetItem__container_3pAb {
    border-color: transparent;
}

.PetItem__image_container_9kyP {
  margin-right: 20px;
  overflow: hidden;
  border-radius: 50%;
}

.PetItem__image_PRqM {
  vertical-align: middle;
  object-fit: cover;
}

@media (max-width: 1279px) {

.PetItem__image_PRqM {
    width: 40px;
    height: 40px;
  }
}

.PetItem__content_2cRq {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  min-width: 0;
}

.PetItem__nickname_3l0F {
  width: 100%;
  max-width: 220px;
  margin-right: 25px;
  margin-bottom: 4px;
}

@media (max-width: 767px) {

.PetItem__nickname_3l0F {
    margin-right: 0;
  }
}

.PetItem__info_elLz {
  min-width: 113px;
  margin-right: 59px;
}

@media (max-width: 767px) {

.PetItem__info_elLz {
    margin-right: 0;
  }
}

.PetItem__actions_1zKY {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  margin-right: 40px;
  margin-left: auto;
  opacity: 0;
  transition: 0.15s ease-in-out;
}

@media (max-width: 959px) {

.PetItem__actions_1zKY {
    margin-right: 0;
  }
}

.PetItem__link_1EwI:hover ~ .PetItem__actions_1zKY,
  .PetItem__root_H7pa.PetItem__show_tooltip_2X36 .PetItem__actions_1zKY,
  .PetItem__actions_1zKY:hover {
    opacity: 1;
}

.PetItem__remove_3vcs {
  position: relative;
  z-index: 2;
}

.PetItem__link_1EwI {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}