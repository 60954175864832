.PublicPreferences__permissions_GGe2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 20px;
  margin-left: -16px;
}

.PublicPreferences__permissions-wrap_2l2E {
  overflow: hidden;
}

.PublicPreferences__permission_3Fs7 {
  position: relative;
  display: inline-block;
  padding-left: 16px;
}

.PublicPreferences__permission_3Fs7:before {
    position: absolute;
    top: 50%;
    left: 8px;
    display: block;
    width: 4px;
    height: 4px;
    content: '';
    background-color: #7a869a;
    border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.PublicPreferences__customize_1wXc {
  max-width: 200px;
  margin-top: 32px;
}

@media (max-width: 767px) {

.PublicPreferences__customize_1wXc {
    max-width: 100%;
    margin-top: 16px;
  }
}