.DocumentContainerView__date_1CRy {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.DocumentContainerView__icon_3Lrw {
  margin-left: 6px;
}

.DocumentContainerView__icon_3Lrw > svg {
    display: block;
}
