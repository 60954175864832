.Header__wrapper_38w_ {
  position: fixed;
  top: auto;
  left: 0;
  z-index: 303;
  width: 100%;
}

  .Header__wrapper_38w_.Header__headerAbsolute_1Iol {
    position: absolute;
}

.Header__avatar_3XO- {
  width: 28px;
  height: 28px;
  cursor: pointer;
  background-size: cover;
  border-radius: 50%;
}
