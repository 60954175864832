@media (max-width: 767px) {.Form__root_3NOF {
    padding-bottom: 60px
  }
}
  @media (min-width: 768px) {.Form__root_3NOF {
    max-width: 624px
  }
}

.Form__block_2tlU {
  margin-bottom: 40px
}

.Form__phone_i-K1 {
  font-weight: 700
}