.Passengers__passenger_15wC {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

  .Passengers__passenger_15wC:not(:first-child) {
    padding-top: 21px;
    margin-top: 20px;
    border-top: 1px solid #eceff5;
}

  @media (max-width: 767px) {.Passengers__passenger_15wC {
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }

    .Passengers__passenger_15wC:not(:first-child) {
      padding-top: 16px;
      margin-top: 15px;
      border-top: 1px solid #eceff5;
  }
}

.Passengers__avatar_1sIm {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #d0d6e1;
  border-radius: 50%;
}

@media (max-width: 767px) {

.Passengers__avatar_1sIm {
    width: 40px;
    height: 40px;
  }
}

.Passengers__right_3N9P {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-left: 20px;
}

@media (max-width: 767px) {

.Passengers__right_3N9P {
    margin-left: 12px;
  }
}

.Passengers__info_2_u6 {
  display: -webkit-flex;
  display: flex;
  margin-top: 8px;
}

@media (max-width: 767px) {

.Passengers__info_2_u6 {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 12px;
  }
}

.Passengers__info-block_22zj {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 144px;
}

.Passengers__info-block_22zj:not(:first-child) {
    margin-left: 20px;
}

@media (max-width: 767px) {

.Passengers__info-block_22zj {
    -webkit-flex-direction: row;
            flex-direction: row;
    width: auto;
  }

    .Passengers__info-block_22zj:not(:first-child) {
      margin-top: 4px;
      margin-left: 0px;
  }
}

@media (max-width: 767px) {

.Passengers__info-value_3ELN {
    margin-left: 4px;
  }
}

.Passengers__just-names_3ylE {
  display: -webkit-flex;
  display: flex;
}

@media (max-width: 767px) {

.Passengers__just-names_3ylE {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}