.QRCodeItem__root_1IHo {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
}

  .QRCodeItem__root_1IHo + .QRCodeItem__root_1IHo {
    border-top: 1px solid #eceff5;
}

  @media (max-width: 767px) {.QRCodeItem__root_1IHo {
    padding-top: 17px;
    padding-bottom: 17px;
  }
}

.QRCodeItem__icon_2UeG {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 40px;
  height: 40px;
  color: #505f79;
  background-color: #d0d6e1;
  border-radius: 50%;
}

.QRCodeItem__caption_3iUx {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 3;
          flex-grow: 3;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  margin-left: 16px;
}

@media (max-width: 767px) {

.QRCodeItem__caption_3iUx {
    -webkit-flex-shrink: 1;
            flex-shrink: 1;
    margin-left: 12px;
  }
}

.QRCodeItem__subtitle_23Om {
  display: -webkit-flex;
  display: flex;
}

.QRCodeItem__actions_3nRA {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}
